import { observer } from 'mobx-react';
import './Modals.scss';
import { useState } from '../../state/AppState';
import { ModalType } from '../../state/ModalsStore';
import { CancelProcessingTransactionModal } from './modals/CancelProcessingTransactionModal/CancelProcessingTransactionModal';

export const Modals = observer(() => {
  const store = useState();
  const { modalsStore } = store;
  if (!modalsStore.activeModal) return null;
  switch (modalsStore.activeModal) {
    case ModalType.CANCEL_PROCESSING_TRANSACTION:
      return <CancelProcessingTransactionModal />;
  }
});
