import { observer } from 'mobx-react';
import './TxInfoPage.scss';
import Table, { SORT_DIRECTION } from '../../components/Table/Table';
import { getPayoutRequestRecordsColumns, getTxTableColumns } from '../MainPage/MainPage';
import { useState } from '../../state/AppState';
import { BackButton } from '../../components/controls/BackButton/BackButton';
import { PayoutRequestRecord } from '../../state/PayoutRequestRecord';
import { IOperation, Operation } from '../../state/Operation';

export const TxInfoPage = observer(() => {
  const state = useState();
  const { mainPageInfo, txInfoStore } = state;

  const tableColumns =
    txInfoStore.operations?.length && txInfoStore.operations[0] instanceof PayoutRequestRecord
      ? getPayoutRequestRecordsColumns(mainPageInfo.operationType, true)
      : getTxTableColumns(mainPageInfo.operationType, state.selectedCurrencyForConvert, undefined, undefined, true);

  return (
    <div className="TxInfoPage PageContainer">
      <BackButton label="Back" link={`/dashboard/user/${state.clientId}`} />
      <div className="PageTitle">Transaction info</div>
      <Table
        columns={tableColumns}
        defaultSortedColumn={{
          name: 'updatedAtNumber',
          direction: SORT_DIRECTION.DESC,
        }}
        periodsFilterColumn={'updatedAtNumber'}
        data={txInfoStore.operations ?? []}
        withoutFilters
        totalsFromApi={txInfoStore.totals}
      />
    </div>
  );
});
