import { ISelectOption } from '../Select/Select';
import cn from 'classnames';
import './SearchableSelectMultiple.scss';
import useComponentVisible from '../../../hooks/useComponentVisible';
import React, { MouseEvent, ReactNode, useEffect, useRef } from 'react';
import { Icon } from '../../Icon/Icon';
import { Input } from '../Input/Input';
import { Checkbox } from '../Checkbox/Checkbox';

interface SearchableSelectMultipleProps {
  options: ISelectOption[];
  label: string;
  selectedValues?: (string | number)[];
  className?: string;
  placeholder: string;
  onChange?: (values: (string | number)[]) => void;
  withClearButton?: boolean;
  withHiddenElements?: boolean;
  renderCustomItem?: (itemValue: string | number) => ReactNode;
  renderCustomOption?: (itemValue: string | number) => ReactNode;
  selectedOptionsOnTop?: boolean;
}

export const SearchableSelectMultiple = (props: SearchableSelectMultipleProps) => {
  const placeholderRef = useRef<HTMLDivElement>(null);
  const {
    options,
    label,
    className,
    placeholder,
    selectedValues,
    onChange,
    renderCustomItem,
    withClearButton,
    withHiddenElements,
    renderCustomOption,
    selectedOptionsOnTop,
  } = props;
  const [searchValue, setSearchValue] = React.useState('');
  const [optionsBlockOffsetTop, setOptionsBlockOffsetTop] = React.useState(62);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [hiddenElements, setHiddenElements] = React.useState(0);

  useEffect(() => {
    calcHiddenElements();
  }, [selectedValues]);

  const toggleOptions = () => {
    setIsComponentVisible(!isComponentVisible);
  };
  const sortedOptions = selectedOptionsOnTop
    ? options.sort((a, b) => {
        if (selectedValues?.includes(a.value) && selectedValues?.includes(b.value)) {
          return 1;
        }
        if (selectedValues?.includes(a.value) && !selectedValues?.includes(b.value)) {
          return -1;
        }
        if (!selectedValues?.includes(a.value) && selectedValues?.includes(b.value)) {
          return 1;
        }
        return 0;
      })
    : options;
  const filteredOptions = searchValue
    ? sortedOptions.filter(
        option =>
          option?.label?.toLowerCase().search(searchValue.toLowerCase().replace(/\\/g, '\\\\') as string) !== -1,
      )
    : sortedOptions;

  const toggleOption = (e: MouseEvent<HTMLDivElement>, value: string | number) => {
    e.preventDefault();
    e.stopPropagation();
    if (onChange && selectedValues) {
      if (selectedValues.indexOf(value) !== -1) {
        onChange(selectedValues!.filter(_ => _ !== value));
      } else {
        onChange(selectedValues.concat(value));
      }
    }
    calcOffsetTop();
  };

  const calcOffsetTop = () => {
    setTimeout(() => {
      setOptionsBlockOffsetTop((placeholderRef?.current?.offsetHeight ?? 0) + 40);
    }, 0);
  };

  const calcHiddenElements = () => {
    if (withHiddenElements) {
      const element = placeholderRef?.current;
      let elements = 0;
      if (element?.children?.length) {
        const elementWidth = element.offsetWidth;
        for (let i = 0; i < element.children.length; i++) {
          const endChildOffsetFromLeft =
            (element.children[i] as HTMLElement).offsetLeft + (element.children[i] as HTMLElement).offsetWidth;
          if (endChildOffsetFromLeft > elementWidth) {
            elements++;
          }
        }
      }
      setHiddenElements(elements >= 1 ? elements : 0);
    }
  };

  const onClearButtonClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (onChange) {
      onChange([]);
      calcOffsetTop();
    }
  };

  return (
    <div
      ref={ref}
      className={cn('SearchableSelectMultiple', className, { withClearButton: !!withClearButton, withHiddenElements })}
    >
      <div
        onClick={toggleOptions}
        className={cn('SearchableSelectMultiple__input', {
          isOpen: isComponentVisible,
          hasSelectedItems: !!selectedValues?.length,
        })}
      >
        <div className="left-block">
          <span className="label">{label}</span>
          <div className="placeholder" ref={placeholderRef}>
            {selectedValues?.length
              ? selectedValues
                  ?.map(val => options.find(o => o.value === val))
                  .filter(val => val?.value !== undefined && val.label !== undefined)
                  .map((item, i) => (
                    <>
                      {renderCustomItem ? (
                        renderCustomItem(item?.value ?? '')
                      ) : (
                        <div className="item">
                          {item?.label}
                          <div onClick={e => toggleOption(e, item?.value!)} className="item__closeBtn">
                            <Icon iconName="close-modal" widthInPx={8} heightInPx={8} />
                          </div>
                        </div>
                      )}
                    </>
                  ))
              : placeholder}
          </div>
        </div>
        {withHiddenElements && selectedValues?.length && hiddenElements > 0 ? (
          <div className="counter">+{hiddenElements}</div>
        ) : null}
        <div className="SearchableSelectMultiple__rightBlock">
          {withClearButton && selectedValues?.length ? (
            <div onClick={onClearButtonClick} className="SearchableSelectMultiple__clearButton">
              <Icon iconName={'close'} widthInPx={12} heightInPx={12} />
            </div>
          ) : null}
          <Icon iconName={isComponentVisible ? 'arrow-up-black' : 'arrow-down-black'} widthInPx={12} heightInPx={7} />
        </div>
      </div>
      {isComponentVisible && (
        <div className="SearchableSelectMultiple__options-block" style={{ top: optionsBlockOffsetTop }}>
          <Input
            className="search-input"
            leftIconName="search"
            value={searchValue}
            label="Search"
            onChange={setSearchValue}
            actionButtonIcon={searchValue?.length ? 'close' : undefined}
            actionButtonOnClick={() => setSearchValue('')}
          />
          <div className="options-list">
            {filteredOptions.map(option => (
              <div
                className={cn('option', { active: selectedValues?.indexOf(option.value) !== -1 })}
                onClick={e => toggleOption(e, option.value)}
              >
                <div className="option-label">
                  {renderCustomOption ? renderCustomOption(option.value) : option.label}
                </div>
                <Checkbox isActive={selectedValues?.indexOf(option.value) !== -1} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
