export function formatDate(date: Date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export const formatSecondsToTime = function (secs: number | undefined | null) {
  if (secs) {
    let sec_num = secs;
    let days: number | string = Math.floor(sec_num / 86400);
    let hours: number | string = Math.floor((sec_num - days * 86400) / 3600);
    let minutes: number | string = Math.floor((sec_num - days * 86400 - hours * 3600) / 60);
    let seconds: number | string = Math.floor(sec_num - days * 86400 - hours * 3600 - minutes * 60);

    if (days) {
      return `${days.toString()}d`;
    }
    if (hours) {
      return `${hours.toString()}h ${minutes.toString()}m`;
    }
    if (minutes) {
      return `${minutes.toString()}m ${seconds.toString()}s`;
    }
    return `${seconds.toString()} s`;
  }
  return '';
};

export const formatSecondsToTimeHHmmss = function (secs: number | undefined | null) {
  if (secs) {
    let sec_num = secs;
    let days: number | string = Math.floor(sec_num / 86400);
    let hours: number | string = Math.floor((sec_num - days * 86400) / 3600);
    let minutes: number | string = Math.floor((sec_num - days * 86400 - hours * 3600) / 60);
    let seconds: number | string = Math.floor(sec_num - days * 86400 - hours * 3600 - minutes * 60);

    if (days < 10) {
      days = '0' + days.toString();
    }
    if (hours < 10) {
      hours = '0' + hours.toString();
    }
    if (minutes < 10) {
      minutes = '0' + minutes.toString();
    }
    if (seconds < 10) {
      seconds = '0' + seconds.toString();
    }
    const daysStr = days === '00' ? '' : `${days} ${days === '01' ? 'day' : 'days'}, `;
    return daysStr + hours + ':' + minutes + ':' + seconds;
  }
  return '';
};
