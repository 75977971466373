
import './Switcher.scss';

interface SwitcherProps {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  id: string;
}

export const Switcher = (props: SwitcherProps) => {
  return (
    <div className="toggleWrapper" >
      <input type="checkbox" name={props.id} id={props.id} onChange={props.onChange} className="mobileToggle" checked={props.checked} />
      <label htmlFor={props.id} />
    </div>
  )
}