import { makeObservable, observable, action } from 'mobx';
import { AppState } from './AppState';
import { v4 as uuid } from 'uuid';

export enum NotificationType {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface INotification {
  message?: string;
  type?: NotificationType;
  id: string;
}
export class NotificationsState {
  private appState: AppState;

  @observable notificationsList: INotification[] = [];

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  addNotification = (message: string, type?: NotificationType) => {
    const notificationItem = { message, id: uuid(), type };
    this.notificationsList.unshift(notificationItem);
    setTimeout(() => {
      this.removeNotification(notificationItem);
    }, 3000);
  };

  @action
  removeNotification = (notification: INotification) => {
    this.notificationsList = this.notificationsList.filter(_ => _.id !== notification.id);
  };
}
