import { makeObservable, observable, action, computed } from 'mobx';
import { AppState } from './AppState';
import { IOperation, Operation } from './Operation';
import { PayoutRequestRecord } from './PayoutRequestRecord';
import { protocol } from '../api/proto';
import { ITotalFromApi } from '../components/Table/Table';
import { formatCurrency, formatWlCurrency } from './utils/Money';

export class TxInfoStore {
  private appState: AppState;

  @observable operations?: IOperation[] | PayoutRequestRecord[];
  @observable totals?: ITotalFromApi[];

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  goToInfoPage = (operation: IOperation | PayoutRequestRecord) => {
    this.operations = this.getOperations(operation);
    if (this.appState.navigate) this.appState.navigate(`/dashboard/user/${this.appState.clientId}/tx-info`);
  };

  @action
  getOperations(operation: IOperation | PayoutRequestRecord) {
    this.totals = [];
    if (operation) {
      if (operation instanceof PayoutRequestRecord) {
        return [
          new PayoutRequestRecord(this.appState, {
            ...operation,
            childs: undefined,
          } as protocol.IPayoutRequestRecord),
          ...(operation?.childs ?? []),
        ];
      } else {
        if (operation.currency) {
          this.totals?.push({
            key: 'amountSum',
            value: formatCurrency(Number(operation.sumAmount ?? 0), operation.currency),
            additionalValue: formatWlCurrency(
              operation.amountFiatSum ? operation.amountFiatSum[this.appState.selectedCurrencyForConvert] ?? 0 : 0,
              this.appState.selectedCurrencyForConvert,
            ),
          });
          if (operation.currency === protocol.Currency.ETH_CUR) {
            this.totals?.push({
              key: 'feeSum',
              value: formatCurrency(Number(operation.sumFee ?? 0), operation.currency),
              additionalValue: formatWlCurrency(
                operation.feeFiatSum ? operation.feeFiatSum[this.appState.selectedCurrencyForConvert] ?? 0 : 0,
                this.appState.selectedCurrencyForConvert,
              ),
            });
          }
        }
        return [new Operation(this.appState, { ...operation, childs: undefined }), ...(operation?.childs ?? [])];
      }
    }
    return [];
  }

  @action
  resetPage = () => {
    //this.operation = undefined;
  };
}
