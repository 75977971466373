import { makeObservable, observable, action } from 'mobx';
import { protocol } from '../api/proto';
import { AppState } from './AppState';
import { chainToCurrency, formatCurrency } from './utils/Money';

export interface IAddressGroup extends protocol.ClientSystemAddressesTagGroupsListResponse.IGroup {
  systemAddressesBalance?: number;
  systemAddressesBalanceFormatted?: string;
  tokenAddressesBalance?: number;
  tokenAddressesBalanceFormatted?: string;
  formattedChain?: string;
  tokenAddressesCount?: number;
  id?: number;
  lastSystemAddress?: protocol.IClientSystemAddress;
}

export class SystemAddressesStore {
  private appState: AppState;

  @observable isAddressesLoading: boolean = false;
  @observable addressGroups: IAddressGroup[] = [];
  @observable addressGroupsFiltered: IAddressGroup[] = [];
  @observable regenerateAddressId?: number;

  @observable filterCurrencies: protocol.Currency[] = [];
  @observable filterOnlyTokenBalanceEnabled: boolean = false;

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  changeCurrencies = (values: (string | number)[]) => {
    this.filterCurrencies = values as protocol.Currency[];
  };

  @action
  toggleOnlyTokenBalanceFilter = () => {
    this.filterOnlyTokenBalanceEnabled = !this.filterOnlyTokenBalanceEnabled;
  };

  @action
  resetFilters = () => {
    this.filterCurrencies = [];
    this.filterOnlyTokenBalanceEnabled = false;
    this.updateFilteredData();
  };

  @action
  fetchAddresses = () => {
    this.isAddressesLoading = true;
    this.appState.api.clientSystemAddressesTagGroupsListRequest({}, (msg: protocol.IServerResponse) => {
      this.isAddressesLoading = false;
      if (msg.clientSystemAddressesTagGroupsListResponse?.groups?.length) {
        this.addressGroups = msg.clientSystemAddressesTagGroupsListResponse?.groups.map(this.processAddressGroup);
      } else {
        this.addressGroups = [];
      }
      this.updateFilteredData();
    });
  };

  processAddressGroup = (group: IAddressGroup, index: number) => {
    const currencySystemAddress = chainToCurrency(group.chain ?? undefined);
    const currencyTokenAddress =
      group.tokenAddresses?.length && group.tokenAddresses[0].currency
        ? group.tokenAddresses[0].currency
        : chainToCurrency(group.chain ?? undefined);
    const systemAddressesBalance = group.systemAddresses?.length
      ? group.systemAddresses.reduce((acc, _) => Number(acc ?? 0) + Number(_.balance ?? 0), 0)
      : 0;
    const tokenAddressesBalance = group.tokenAddresses?.length
      ? group.tokenAddresses.reduce((acc, _) => Number(acc ?? 0) + Number(_.balance ?? 0), 0)
      : 0;
    return {
      ...group,
      systemAddressesBalance,
      systemAddressesBalanceFormatted: formatCurrency(systemAddressesBalance, currencySystemAddress),
      tokenAddressesBalance,
      tokenAddressesBalanceFormatted: formatCurrency(tokenAddressesBalance, currencyTokenAddress),
      formattedChain: protocol.Chain[group.chain ?? protocol.Chain.UNK_CHAIN].replace('_CHAIN', ''),
      tokenAddressesCount: group.tokenAddresses?.length ?? 0,
      id: index,
      lastSystemAddress: group.systemAddresses?.length
        ? group.systemAddresses[group.systemAddresses.length - 1]
        : undefined,
    };
  };

  @action
  generateNewAddress = (address: IAddressGroup) => {
    this.regenerateAddressId = address.id;
    this.appState.api.createClientSystemAddressRequest(
      {
        tag: address.tag,
        chain: address.chain,
      },
      (msg: protocol.IServerResponse) => {
        this.regenerateAddressId = undefined;
        if (msg.createClientSystemAddressResponse?.address?.address) {
          this.addressGroups = this.addressGroups.map(_ => {
            if (_.id === address.id) {
              return this.processAddressGroup(
                {
                  ...address,
                  systemAddresses:
                    address.systemAddresses && msg.createClientSystemAddressResponse?.address
                      ? [...address.systemAddresses, msg.createClientSystemAddressResponse?.address]
                      : address.systemAddresses,
                },
                address.id ?? 0,
              );
            }
            return _;
          });
          this.updateFilteredData();
        }
      },
    );
  };

  @action
  updateFilteredData = () => {
    if (this.filterCurrencies?.length || this.filterOnlyTokenBalanceEnabled) {
      this.addressGroupsFiltered = this.addressGroups?.filter(group => {
        const currency = chainToCurrency(group.chain ?? undefined);
        return (
          (!this.filterCurrencies?.length || this.filterCurrencies.includes(currency)) &&
          (!this.filterOnlyTokenBalanceEnabled || group.tokenAddressesBalance !== 0)
        );
      });
    } else {
      this.addressGroupsFiltered = this.addressGroups;
    }
  };

  @action
  resetPage = () => {
    this.resetFilters();
    this.isAddressesLoading = false;
    this.addressGroups = [];
    this.addressGroupsFiltered = [];
    this.regenerateAddressId = undefined;
  };
}
