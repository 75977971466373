import './ErrorPage.scss';

interface ErrorPageProps {
  errorText: string;
}

const ErrorPage = (props: ErrorPageProps) => {
  return (
    <div className="ErrorPage">
      <div className="error-container">
        <div className="error-title">ERROR</div>
        {props.errorText}
      </div>
    </div>
  )
}

export default ErrorPage;