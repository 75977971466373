import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from '../Icon/Icon';
import { PeriodFilters } from '../PeriodFilters/PeriodFilters';
import { FilterPeriod, FILTER_PERIODS } from './Table';

interface ITableFiltersProps {
  period: FilterPeriod;
  onChangePeriod: (period: FilterPeriod) => void;
  setCustomDateRange: (range: Date | [Date | null, Date | null] | null) => void;
  customDateRange?: [Date | null, Date | null];
  openCustomizeColumnsModal: () => void;
  filtersLeftBlock?: React.ReactElement;
  filtersCustomPeriodsFilters?: React.ReactElement;
}

export const TableFilters = observer((props: ITableFiltersProps) => {
  return (
    <div className="TableFilters">
      <div>{props.filtersLeftBlock ? props.filtersLeftBlock : null}</div>
      <div className="right-block">
        {props.filtersCustomPeriodsFilters ? (
          props.filtersCustomPeriodsFilters
        ) : (
          <PeriodFilters
            onChange={(period: string) => props.onChangePeriod(period as FilterPeriod)}
            value={props.period}
            periods={FILTER_PERIODS as unknown as string[]}
            setCustomDateRange={props.setCustomDateRange}
            customDateRange={props.customDateRange}
          />
        )}

        <div className="customize-fields">
          <div className="settings-button" onClick={props.openCustomizeColumnsModal}>
            <Icon widthInPx={18} heightInPx={18} iconName="settings-2" />
          </div>
        </div>
      </div>
    </div>
  );
});
