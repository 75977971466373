import Long from 'long';
import { action, computed, makeObservable, observable } from 'mobx';
import { protocol } from '../api/proto';
import { IAmlReport } from './AmlStore';
import { AppState } from './AppState';
import { SignalsStatictics, getSourcesMainStatByRiskLevel } from './utils/amlUtils';
import { formatServerErrors } from './utils/format';
import { formatCurrency } from './utils/Money';
import { NotificationType } from './NotificationsState';
import { ISortedColumn, SORT_DIRECTION } from '../components/Table/Table';

const CHECK_REPORT_TIMEOUT = 10000; //ms
const LIMIT_PAGINATION = 50;

export interface ICheckingReportInfo {
  checked?: number;
  total?: number;
}

export interface IAmlCheckedAddressBalance extends protocol.AMLClientAddress.IBalance {
  balanceFormatted?: string;
}

export interface IAMLAddress extends protocol.IAMLClientAddress {
  balanceFormatted?: string;
  tagsList?: string;
  balanceNum?: number;
  balancesFormatted?: IAmlCheckedAddressBalance[];
}
export class AmlReportStore {
  private appState: AppState;

  @observable reportId?: number;

  @observable reportAddressesLoading: boolean = false;
  @observable reportAddresses?: IAMLAddress[] = [];
  @observable filteredReportData?: IAmlReport[] = [];

  @observable reportFilterSources: string[] = [];
  @observable reportFilterRiskFrom: string = '';
  @observable reportFilterRiskTo: string = '';

  @observable isCheckingReport: boolean = false;
  @observable checkingReportInfo?: ICheckingReportInfo;
  @observable reportSourcesInfo?: SignalsStatictics;
  @observable reportStatsLoading: boolean = false;

  @observable reportRowId?: string;

  checkingTimeout?: number;

  @observable selectedAddresses: string[] = [];

  @observable addTagModalIsOpen: boolean = false;
  @observable tagName: string = '';
  @observable addTagIsLoading: boolean = false;
  @observable addTagError?: string;

  @observable page: number = 0;
  @observable totalPages: number = 0;
  @observable sortedColumnAddresses?: ISortedColumn = {
    name: 'riskScore',
    direction: SORT_DIRECTION.DESC,
  };

  @action
  changePage = (page: number) => {
    this.page = page;
    if (this.reportId) {
      this.loadReportAddresses(this.reportId);
    }
  };

  @computed
  get addressesSortBy() {
    if (this.sortedColumnAddresses?.name) {
      switch (this.sortedColumnAddresses.name) {
        case 'address':
          return protocol.SortBy.ADDRESS_SORT_BY;
        case 'riskScore':
          return protocol.SortBy.RISK_SCORE_SORT_BY;
        case 'balanceNum':
          return protocol.SortBy.BALANCE_SORT_BY;
        case 'currency':
          return protocol.SortBy.CURRENCY_SORT_BY;
      }
    }
    return undefined;
  }

  @action
  onSortColumnAddressesClick = (name: string) => {
    if (this.sortedColumnAddresses?.name && this.sortedColumnAddresses.name === name) {
      if (this.sortedColumnAddresses.direction === SORT_DIRECTION.ASC) {
        this.sortedColumnAddresses = undefined;
      } else if (this.sortedColumnAddresses.direction === SORT_DIRECTION.DESC) {
        this.sortedColumnAddresses.direction = SORT_DIRECTION.ASC;
      } else {
        this.sortedColumnAddresses.direction = SORT_DIRECTION.DESC;
      }
    } else {
      this.sortedColumnAddresses = {
        name,
        direction: SORT_DIRECTION.DESC,
      };
    }
    this.page = 0;
    if (this.reportId) {
      this.loadReportAddresses(this.reportId);
    }
  };

  @action
  openAddTagModal = () => {
    this.addTagModalIsOpen = true;
  };

  @action
  closeAddTagModal = () => {
    this.addTagModalIsOpen = false;
    this.tagName = '';
    this.addTagIsLoading = false;
    this.addTagError = undefined;
  };

  @action
  onChangeTagName = (tagName: string) => {
    this.tagName = tagName;
  };

  @action
  onToggleAddressRow = (address: string) => {
    if (this.selectedAddresses.includes(address)) {
      this.selectedAddresses = this.selectedAddresses.filter(_ => _ !== address);
    } else {
      this.selectedAddresses.push(address);
    }
  };

  @action
  onSelectAllRows = () => {
    this.selectedAddresses = this.reportAddresses?.map(_ => _.address ?? '') ?? [];
  };

  @action
  unselectAllRows = () => {
    this.selectedAddresses = [];
  };

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @computed
  get isCheckingStarted() {
    return !!this.isCheckingReport;
  }

  @action
  initAmlReport = (reportId: number) => {
    this.reportSourcesInfo = undefined;
    this.loadReport(reportId);
    this.loadReportAddresses(reportId);
  };

  @action
  checkAgainStart = () => {
    if (this.filteredReportData?.length) {
      this.appState.aml.filterAddresses = this.filteredReportData.filter(row => !!row.address).map(row => row.address!);
      if (this.appState.aml.filterAddresses?.length) {
        //this.newCheckStart();
        this.reportAddresses = [];
        this.filteredReportData = [];
      }
    }
  };

  @action
  fetchingReport = () => {
    if (this.reportId && this.isCheckingReport) {
      this.appState.api.amlClientBatchStatsRequest({ batchId: this.reportId }, this.onFetchingReportResponse);
      this.loadReportAddresses(this.reportId);
    }
  };

  @action
  onFetchingReportResponse = (msg: protocol.IServerResponse) => {
    if (msg?.amlClientBatchStatsResponse?.stats) {
      const check = msg?.amlClientBatchStatsResponse?.stats;
      if (check.status === protocol.AMLBatchCheckStatus.ACTIVE_AML_BATCH_CHECK_STATUS) {
        const checked = check.completedChecks || new Long(0);

        this.checkingReportInfo = {
          total: check.totalChecks?.toNumber(),
          checked: checked?.toNumber() || 0,
        };
        this.processReportSources(msg);
        this.checkingTimeout = window.setTimeout(this.fetchingReport, CHECK_REPORT_TIMEOUT);
      } else {
        this.processReportSources(msg);
        this.checkingReportInfo = undefined;
        this.isCheckingReport = false;
      }
    } else {
      this.checkingReportInfo = undefined;
      this.isCheckingReport = false;
    }
  };

  @action
  processReportSources = (msg: protocol.IServerResponse) => {
    if (msg?.amlClientBatchStatsResponse?.stats?.sources) {
      this.reportSourcesInfo = getSourcesMainStatByRiskLevel(msg.amlClientBatchStatsResponse.stats.sources);
    }
  };

  @action
  stopChecking = () => {
    if (this.reportId) {
      this.appState.api.amlBatchCheckStopRequest({ id: this.reportId }, this.onStopCheckingResponse);
    }
  };

  @action
  onStopCheckingResponse = (msg: protocol.IServerResponse) => {
    this.isCheckingReport = false;
    this.checkingReportInfo = undefined;
  };

  @action
  loadReport = (reportId: number, cb?: () => void) => {
    this.reportId = reportId;
    this.reportStatsLoading = true;
    this.appState.api.amlClientBatchStatsRequest({ batchId: reportId }, this.onReportResponse.bind(cb));
  };

  @action
  onReportResponse = (msg: protocol.IServerResponse, cb?: () => void) => {
    this.reportStatsLoading = false;
    if (msg?.amlClientBatchStatsResponse?.stats) {
      const stats = msg?.amlClientBatchStatsResponse?.stats;
      if (stats.status === protocol.AMLBatchCheckStatus.ACTIVE_AML_BATCH_CHECK_STATUS) {
        this.isCheckingReport = true;
        this.fetchingReport();
      }
      this.processReportSources(msg);
    }
    if (cb) {
      cb();
    }
  };

  @action
  loadReportAddresses = (reportId: number, cb?: () => void) => {
    this.reportAddressesLoading = true;
    this.appState.api.amlClientBatchAddressesListRequest(
      {
        batchId: reportId,
        filters: {
          filterSources: this.reportFilterSources,
          fromRiskscore: this.reportFilterRiskFrom ? Number(this.reportFilterRiskFrom) / 100 : 0,
          toRiskscore: this.reportFilterRiskTo ? Number(this.reportFilterRiskTo) / 100 : undefined,
        },
        limit: Long.fromNumber(LIMIT_PAGINATION),
        offset: Long.fromNumber(this.page * LIMIT_PAGINATION),
        sortBy: this.addressesSortBy,
        sortDesc:
          this.sortedColumnAddresses?.direction !== undefined
            ? this.sortedColumnAddresses?.direction === SORT_DIRECTION.DESC
            : undefined,
      },
      this.onLoadAddressesResponse.bind(cb),
    );
  };

  @action
  onLoadAddressesResponse = (msg: protocol.IServerResponse, cb?: () => void) => {
    this.reportAddressesLoading = false;
    this.totalPages = msg?.amlClientBatchAddressesListResponse?.totalPages?.toNumber() ?? 0;
    this.reportAddresses = msg?.amlClientBatchAddressesListResponse?.addresses?.map(address => {
      const balancesFormatted =
        address.balances && Object.values(address.balances)?.length
          ? Object.values(address.balances)
              .map(_ => ({
                ..._,
                balanceFormatted: _.currency && _.balance ? formatCurrency(Number(_.balance), _.currency) : '-',
              }))
              .sort((a, b) => {
                if (a.currency === address.currency) return -1;
                if (b.currency === address.currency) return 1;
                return 0;
              })
          : [];
      const balanceNumForSort = balancesFormatted?.find(_ => _.currency === address.currency)?.balance;
      return {
        ...address,
        balanceNum: balanceNumForSort ? Number(balanceNumForSort) : undefined,
        balancesFormatted,
        tagsList: address.tags?.join(', ') ?? '-',
      };
    });
    if (cb) {
      cb();
    }
  };

  @computed
  get reportAvgRisk() {
    const count = this.reportAddresses?.filter(_ => !!_.riskScore)?.length;
    if (count) {
      let sum = 0;
      this.reportAddresses!.forEach(row => {
        sum += row.riskScore || 0;
      });
      return Math.round((sum / count) * 100);
    }

    return 0;
  }

  @action
  changeReportFilterSources = (values: (string | number)[]) => {
    this.reportFilterSources = values as string[];
  };

  @action
  changeReportFilterRiskFrom = (value: string) => {
    this.reportFilterRiskFrom = value;
  };

  @action
  changeReportFilterRiskTo = (value: string) => {
    this.reportFilterRiskTo = value;
  };

  @action
  applyFiltersToReport = () => {
    if (this.reportId) {
      this.loadReportAddresses(this.reportId);
      this.selectedAddresses = [];
    }
  };

  @action
  setReportRowId = (rowId: string, reportId: number) => {
    this.reportRowId = rowId;
    if (!this.reportAddresses?.length) {
      this.loadReportAddresses(reportId);
    }
  };

  @computed
  get reportRowData() {
    if (this.reportRowId && this.reportAddresses?.length) {
      return this.reportAddresses.find(item => item.address === this.reportRowId);
    }
    return undefined;
  }

  @action
  goToReports = () => {
    this.appState.aml.amlPageTab = 'reports';
    if (this.appState.navigate) this.appState.navigate(`/dashboard/user/${this.appState.clientId}/aml`);
  };

  @action
  onAddTagClick = () => {
    if (this.tagName && this.selectedAddresses?.length) {
      this.addTagIsLoading = true;
      this.appState.api.modifyTagsRequest(
        {
          tagName: this.tagName,
          addresses: this.selectedAddresses,
          clientId: this.appState.clientId,
        },
        (msg: protocol.IServerResponse) => {
          this.addTagIsLoading = false;
          if (msg?.error) {
            this.addTagError = formatServerErrors(msg.error);
          } else {
            this.appState.notifications.addNotification('Tag successfully added', NotificationType.SUCCESS);

            this.closeAddTagModal();
            this.loadReportAddresses(this.reportId!);
          }
        },
      );
    }
  };

  @action
  resetReportFilters = (fromUi?: boolean) => {
    this.reportFilterSources = [];
    this.reportFilterRiskFrom = '';
    this.reportFilterRiskTo = '';
    if (fromUi && this.reportId) {
      this.loadReportAddresses(this.reportId);
    }
  };

  @action
  resetReportPage = () => {
    this.resetReportFilters();
    this.reportStatsLoading = false;
    this.reportAddressesLoading = false;
    this.isCheckingReport = false;
    this.reportSourcesInfo = undefined;
    this.selectedAddresses = [];
    this.tagName = '';
    this.addTagIsLoading = false;
    this.addTagError = undefined;
    window.clearTimeout(this.checkingTimeout);
    this.resetPaging();
  };

  @action
  resetReportRowPage = () => {
    this.reportRowId = undefined;
  };

  @action
  resetPaging = () => {
    this.page = 0;
    this.totalPages = 0;
    this.sortedColumnAddresses = {
      name: 'riskScore',
      direction: SORT_DIRECTION.DESC,
    };
  };
}
