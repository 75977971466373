import { observer } from 'mobx-react';
import { SORT_DIRECTION, ISortedColumn } from './Table';

interface TableThProps {
  title: string;
  columnName: string;
  sortEnabled: boolean;
  onSortClick: (columnName: string, sortFirstByColumn?: string) => void;
  sortedColumn?: ISortedColumn;
  sortFirstByColumn?: string;
}

export const TableTh = observer((props: TableThProps) => {
  if (props.sortEnabled) {
    let iconClasses = 'icon ';
    if (props.sortedColumn?.name && props.sortedColumn?.name === props.columnName) {
      if (props.sortedColumn.direction === SORT_DIRECTION.ASC) {
        iconClasses += 'icon-arrows-up';
      } else {
        iconClasses += 'icon-arrows-down';
      }
    } else {
      iconClasses += 'icon-arrows';
    }
    return (
      <th
        key={props.columnName}
        className="sorted-th"
        onClick={() => props.onSortClick(props.columnName, props.sortFirstByColumn)}
      >
        <div className="container">
          {props.title}
          <div className={iconClasses} />
        </div>
      </th>
    );
  }
  return <th key={props.columnName}>{props.title}</th>;
});
