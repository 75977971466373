import { observer } from 'mobx-react';
import {
  DragDropContext,
  Droppable,
  DraggableProvided,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
} from 'react-beautiful-dnd';
import { Switcher } from '../../controls/Switcher/Switcher';
import { Icon } from '../../Icon/Icon';
import './CustomizeColumnsModal.scss';
import { IColumnState } from '../Table';

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  paddingBottom: isDraggingOver ? 37 : 0,
});

const DndItem = observer(
  (props: { provided: DraggableProvided; col: IColumnState; toggleColumnStatus: (col: IColumnState) => void }) => {
    const { provided, col, toggleColumnStatus } = props;
    return (
      <>
        <div className="left-block">
          <div {...provided.dragHandleProps}>
            <Icon iconName="drag-and-drop" widthInPx={10} heightInPx={16} />
          </div>
          <div className="field-name">{col.title}</div>
        </div>
        {col.enabled}
        <Switcher id={`${col.key}-switcher`} checked={col.enabled} onChange={() => toggleColumnStatus(col)} />
      </>
    );
  },
);

const DndWrapper = observer((props: { columns: IColumnState[]; toggleColumnStatus: (col: IColumnState) => void }) => {
  return (
    <div>
      {props.columns.map((col, index) => (
        <Draggable key={col.key} draggableId={col.key} index={index}>
          {(provided, snapshot) => (
            <div
              className="col-item"
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
              <DndItem provided={provided} col={col} toggleColumnStatus={props.toggleColumnStatus} />
            </div>
          )}
        </Draggable>
      ))}
    </div>
  );
});

interface ICutomizeColumnsModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  columns: IColumnState[];
  toggleColumnStatus: (col: IColumnState) => void;
  reorderColumns: (index: number, destinationIndex: number) => void;
}

export const CustomizeColumnsModal = observer((props: ICutomizeColumnsModalProps) => {
  if (!props.isOpen) return null;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    props.reorderColumns(result.source?.index, result.destination?.index);
  };

  return (
    <div className="modal-container">
      <div className="modal-background" />
      <div className="modal">
        <div className="CustomizeColumnsModal">
          <div className="CustomizeColumnsModal-container">
            <div className="modal-title">
              <div>Customize Fields</div>
              <div onClick={props.onCloseModal}>
                <Icon iconName="close-modal" widthInPx={16} heightInPx={16} />
              </div>
            </div>
            <div className="modal-content">
              <div className="columns-container">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        <DndWrapper toggleColumnStatus={props.toggleColumnStatus} columns={props.columns} />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
