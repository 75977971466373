import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from '../../state/AppState';
import { Button, ButtonSize, ButtonType } from '../controls/Button/Button';
import { Icon } from '../Icon/Icon';
import { Loader } from '../Loader/Loader';

import './TotalBalances.scss';
import { TotalBalancesItem } from './TotalBalancesItem';

interface TotalBalancesProps {
  minimizeView: boolean;
}

export const TotalBalances = observer((props: TotalBalancesProps) => {
  const state = useState();
  const { mainPageInfo } = state;
  const {
    balancesTotal,
    balancesUpdates,
    balancesLoading,
    fetchBalances,
    balancesLastUpdateStamp,
    balancesTotalIsBalanceExists,
  } = mainPageInfo;
  if (!balancesTotalIsBalanceExists && !balancesLoading) return null;
  return (
    <div className={`TotalBalances ${props.minimizeView ? 'minimize-view' : ''}`}>
      <div className="title">
        <div className="left">
          Total balances
          <div className="refresh-bth" onClick={() => fetchBalances()}>
            {balancesLoading ? <Loader /> : <Icon iconName="refresh" widthInPx={20} heightInPx={20} />}
          </div>
        </div>
        <TotalBalancesLastUpdate stamp={balancesLastUpdateStamp} />
      </div>
      <div className="balances-list">
        {Object.keys(balancesTotal).map(currency => (
          <TotalBalancesItem balancesTotal={balancesTotal} balancesUpdates={balancesUpdates} currency={currency} />
        ))}
      </div>
    </div>
  );
});

const TotalBalancesLastUpdate = ({ stamp }: { stamp?: number }) => {
  const [lastUpdate, setLastUpdate] = React.useState(0);
  useEffect(() => {
    if (stamp) {
      setLastUpdate(new Date().getTime() - stamp);
    }
  }, [stamp]);
  const onTick = () => {
    setLastUpdate(prevLastUpdate => (prevLastUpdate += 1000));
  };
  useEffect(() => {
    const interval = window.setInterval(onTick, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="TotalBalancesLastUpdate">
      {lastUpdate ? (
        <div>Last update: {moment.utc(lastUpdate).format(lastUpdate >= 60000 ? 'm[m] s[s]' : 's[s]')} ago</div>
      ) : null}{' '}
    </div>
  );
};
