import './assets/app.scss';
import { Routes, Route, Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import { observer } from 'mobx-react';
import { Header } from './components/Header/Header';
import { Notifications } from './components/Notifications/Notifications';
import { useMountEffect } from './hooks/useMountEffect';
import { useState } from './state/AppState';
import { ExplorerPage } from './pages/ExplorerPage/ExplorerPage';
import { ActivityPage } from './pages/ActivityPage/ActivityPage';
import { AmlAddressPage } from './pages/AmlAddressPage/AmlAddressPage';
import { AuthWrapper } from './components/AuthWrapper';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { FullPageLoader } from './components/Loader/Loader';
import { ProtectedRoutes } from './state/Routes';
import { SystemAddressesPage } from './pages/SystemAddressesPage/SystemAddressesPage';
import { TxInfoPage } from './pages/TxInfoPage/TxInfoPage';
import { Modals } from './components/Modals/Modals';
import { AmlSingleCheckPage } from './pages/AmlSingleCheckPage/AmlSingleCheckPage';

const MainPage = lazy(() => import('./pages/MainPage/MainPage'));
const WithdrawalPage = lazy(() => import('./pages/WithdrawalPage/WithdrawalPage'));
const WithdrawalResultPage = lazy(() => import('./pages/WithdrawalResultPage/WithdrawalResultPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage/ErrorPage'));
const AmlPage = lazy(() => import('./pages/AmlPage/AmlPage'));
const AmlReportPage = lazy(() => import('./pages/AmlReportPage/AmlReportPage'));
const InvoicesMainPage = lazy(() => import('./pages/InvoicesMainPage/InvoicesMainPage'));
const CreateInvoicePage = lazy(() => import('./pages/CreateInvoicePage/CreateInvoicePage'));
const InvoicesPeerPage = lazy(() => import('./pages/InvoicesPeerPage/InvoicesPeerPage'));
const UsersPage = lazy(() => import('./pages/UsersPage/UsersPage'));

export const App = observer(() => {
  const state = useState();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useMountEffect(() => {
    state.initNavigate(navigate);
    if (searchParams.get('electronApp')) {
      state.setWaitingMessageFromApp(true);
    }
    window.addEventListener('message', (event: MessageEvent<any>) => {
      if (event.data.type === 'send-token' && event.data.token) {
        state.setTokenToLocalStorage(event.data.token);
        if (!event.data.updateTokenOnly) {
          if (event.data.withRedirectToAvailablePage) {
            state.checkSession(true, undefined, event.data.token);
          } else {
            state.checkSession(undefined, event.data.route, event.data.token);
          }
        }
      }
    });
  });
  return (
    <div>
      {state.isInitialized && !state.waitingMessageFromApp && !state.isCheckingSession ? <Header /> : null}
      {state.waitingMessageFromApp || state.isCheckingSession ? <FullPageLoader /> : null}
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route path="/dashboard/user/:clientId/login" element={<LoginPage />} />
          <Route
            path={ProtectedRoutes.MAIN_PAGE}
            element={
              <AuthWrapper>
                <MainPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.WITHDRWAL}
            element={
              <AuthWrapper>
                <WithdrawalPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.WITHDRWAL_RESULT}
            element={
              <AuthWrapper>
                <WithdrawalResultPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.EXPLORER}
            element={
              <AuthWrapper>
                <ExplorerPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.ACTIVITY}
            element={
              <AuthWrapper>
                <ActivityPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.AML_MAIN_PAGE}
            element={
              <AuthWrapper>
                <AmlPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.AML_REPORT_PAGE}
            element={
              <AuthWrapper>
                <AmlReportPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.AML_ADDRESS_PAGE}
            element={
              <AuthWrapper>
                <AmlAddressPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.AML_SINGLE_CHECK_PAGE}
            element={
              <AuthWrapper>
                <AmlSingleCheckPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.INVOICES_MAIN_PAGE}
            element={
              <AuthWrapper>
                <InvoicesMainPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.CREATE_INVOICE_PAGE}
            element={
              <AuthWrapper>
                <CreateInvoicePage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.INVOICES_PEER_PAGE}
            element={
              <AuthWrapper>
                <InvoicesPeerPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.USERS}
            element={
              <AuthWrapper>
                <UsersPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.SYSTEM_ADDRESSES}
            element={
              <AuthWrapper>
                <SystemAddressesPage />
              </AuthWrapper>
            }
          />
          <Route
            path={ProtectedRoutes.TX_INFO}
            element={
              <AuthWrapper>
                <TxInfoPage />
              </AuthWrapper>
            }
          />

          <Route
            path="/dashboard/user/"
            element={
              <ErrorPage errorText="ClientId is required to view information. Add a parameter to the link, for example /dashboard/user/87dawd-2xwfId-12dadwqd-ogiv4" />
            }
          />
          <Route path="/error" element={<ErrorPage errorText={state.errorPageText ?? ''} />} />
          <Route path="/" element={<Navigate to="/dashboard/user/" />} />
          <Route path="*" element={<ErrorPage errorText="404 NOT FOUND" />} />
        </Routes>
      </Suspense>
      <Notifications />
      <Modals />
    </div>
  );
});
