import { observer } from 'mobx-react';
import { COLUMN_CONTENT_TYPE, COLUMN_FORMAT, IColumnState } from './Table';
import cn from 'classnames';
import { Icon } from '../Icon/Icon';
import { Checkbox } from '../controls/Checkbox/Checkbox';
import { formatCurrency, formatWlCurrency } from '../../state/utils/Money';
import { currencies, protocol } from '../../api/proto';

interface ITableRowProps {
  columns: IColumnState[];
  rowData: object;
  isChild?: boolean;
  hasChildRows?: boolean;
  onRowClick?: (rowData: object) => void;
  selectedRows?: string[];
  selectRowKeyId?: string;
  onToggleSelectRow?: (rowId: string) => void;
  openChildsInAnotherPage?: boolean;
}

export function getFormattedTableValue(column: IColumnState, row?: any, value?: string | number, isTotal?: boolean) {
  const { formatFields } = column;
  console.log((!isTotal && !column.formatApplyOnlyForComputedTotal) || isTotal);
  if ((!isTotal && !column.formatApplyOnlyForComputedTotal) || isTotal) {
    if (
      formatFields &&
      column.format === COLUMN_FORMAT.CURRENCY &&
      formatFields.currencyKey &&
      row &&
      row[formatFields.currencyKey] !== undefined
    ) {
      if (formatFields.isWlCurrency) {
        return formatWlCurrency(Number(value), row[formatFields.currencyKey]);
      }
      return formatCurrency(Number(value), row[formatFields.currencyKey]);
    }
    if (formatFields && column.format === COLUMN_FORMAT.CURRENCY && formatFields.currency !== undefined) {
      if (formatFields.isWlCurrency) {
        return formatWlCurrency(Number(value), formatFields.currency as currencies.WLCurrency);
      }
      return formatCurrency(Number(value), formatFields.currency as protocol.Currency);
    }
  }
  return value;
}

export const TableRow = observer((props: ITableRowProps) => {
  const {
    columns,
    rowData,
    isChild,
    onRowClick,
    onToggleSelectRow,
    selectedRows,
    selectRowKeyId,
    openChildsInAnotherPage,
  } = props;
  const row = rowData as any;
  const cells = columns.map(column => {
    if (column.renderCustomCellComponent) {
      return column.renderCustomCellComponent(rowData);
    }
    const value = row[column.key]
      ? column.type !== undefined && column.type === COLUMN_CONTENT_TYPE.LONG
        ? row[column.key]?.toString()
        : row[column.key]
      : column.naSymbol ?? null;
    const valueForShow = getFormattedTableValue(column, row, value);
    const additionalValues = column.additionalFields
      ? column.additionalFields
          .map(key => {
            const val = row[key];
            if (val) return val;
            return null;
          })
          .filter(val => !!val)
      : null;
    return (
      <td key={column.key}>
        {valueForShow}
        {additionalValues?.map(val => (
          <div>{val}</div>
        ))}
      </td>
    );
  });
  const isClickingRow =
    !!onRowClick &&
    ((!row.childs?.length && !openChildsInAnotherPage) || (openChildsInAnotherPage && row.childs?.length));
  return (
    <tr
      onClick={isClickingRow ? () => onRowClick(rowData) : undefined}
      className={cn({
        'childs-opened': !!row.childs?.length && row.childsIsOpen,
        'is-child': isChild,
        clicked: isClickingRow,
      })}
    >
      {props.hasChildRows ? (
        <td
          className="fixed-col"
          onClick={
            row.childs?.length && row.toggleChildsIsOpen && !openChildsInAnotherPage
              ? row.toggleChildsIsOpen
              : undefined
          }
        >
          {row.childs?.length ? (
            <div className="childs-operations">
              <div className="number">+{row.childs?.length}</div>
              {!openChildsInAnotherPage ? (
                <div className={cn('icon-container', { 'upside-down': row.childsIsOpen })}>
                  <Icon iconName={row.childsIsOpen ? 'dropdown-blue' : 'dropdown'} widthInPx={12} heightInPx={7} />
                </div>
              ) : null}
            </div>
          ) : isChild ? (
            <div className="child-indicator" />
          ) : null}
        </td>
      ) : props.selectRowKeyId && onToggleSelectRow ? (
        <td
          className="fixed-col"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onToggleSelectRow(row[props.selectRowKeyId as any]);
          }}
        >
          <Checkbox
            isActive={!!(props.selectRowKeyId && selectedRows?.find(r => r === row[props.selectRowKeyId as any]))}
          />
        </td>
      ) : null}
      {cells}
    </tr>
  );
});
