import { observer } from 'mobx-react';
import { useState } from '../../state/AppState';
import { IconButton } from '../controls/IconButton/IconButton';
import cn from 'classnames';
import './Notifications.scss';

export const Notifications = observer(() => {
  const state = useState();
  const { notifications } = state;
  if (notifications.notificationsList) {
    return (
      <div className="notifications-list">
        {notifications.notificationsList.map(notification => (
          <div className={cn('notifications-item', notification.type)} key={notification.id}>
            {notification.message}
            <IconButton
              onClick={() => notifications.removeNotification(notification)}
              iconName="close"
              widthInPx={8}
              heightInPx={8}
            />
          </div>
        ))}
      </div>
    );
  }
  return null;
});
