import { makeObservable, observable, action, computed } from 'mobx';
import { protocol } from '../api/proto';
import { AppState } from './AppState';
import { NotificationType } from './NotificationsState';
import { AccessGroups } from './Roles';
import { formatServerErrors } from './utils/format';

export interface IClientUserFormatted extends protocol.IClientUser {
  lastLoginAtFormatted?: string;
}

class NewUserForm {
  @observable login: string = '';
  @observable password: string = '';
  @observable accessGroups?: string = AccessGroups.ADMIN;
  @observable error?: string;

  constructor() {
    makeObservable(this);
  }

  @computed
  get isCreateButtonEnabled() {
    return this.login && this.password && this.accessGroups;
  }

  @action
  changeLogin = (val: string) => {
    this.login = val;
  };

  @action
  changePassword = (val: string) => {
    this.password = val;
  };

  @action
  changeAccessGroup = (val?: string | number) => {
    this.accessGroups = val as string;
  };
}

class NewPasswordForm {
  @observable password: string = '';
  @observable passwordRepeat: string = '';
  @observable error?: string;

  constructor() {
    makeObservable(this);
  }

  @computed
  get isCreateButtonEnabled() {
    return this.password && this.passwordRepeat && this.password === this.passwordRepeat;
  }

  @action
  changePassword = (val: string) => {
    this.password = val;
  };

  @action
  changePasswordRepeat = (val: string) => {
    this.passwordRepeat = val;
  };
}

export class UsersStore {
  private appState: AppState;

  @observable usersList?: IClientUserFormatted[];
  @observable usersListLoading: boolean = false;

  @observable newUserForm: NewUserForm = new NewUserForm();
  @observable createUserModalIsOpen: boolean = false;
  @observable createUserModalStep: 'create-form' | 'success' = 'create-form';
  @observable createUserLoading: boolean = false;

  @observable newPasswordForm: NewPasswordForm = new NewPasswordForm();
  @observable newPasswordModalUserId?: number;
  @observable changePasswordLoading: boolean = false;
  @observable changePasswordModalStep: 'new-password' | 'success' = 'new-password';

  @computed
  get isReadOnly() {
    return !!this.appState.isReadOnlyAllAccess;
  }

  @action
  openCreateUserModal = () => {
    this.createUserModalIsOpen = true;
  };

  @action
  closeCreateUserModal = () => {
    this.createUserModalIsOpen = false;
    this.newUserForm = new NewUserForm();
    this.createUserModalStep = 'create-form';
  };

  @action
  openChangePasswordModal = (userId: number) => {
    this.newPasswordModalUserId = userId;
  };

  @action
  closeChangePassworModal = () => {
    this.newPasswordModalUserId = undefined;
    this.newPasswordForm = new NewPasswordForm();
    this.changePasswordModalStep = 'new-password';
  };

  @computed
  get changePasswordUser() {
    if (this.newPasswordModalUserId) {
      return this.usersList?.find(_ => _.id === this.newPasswordModalUserId);
    }
    return undefined;
  }

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  getUsersList = () => {
    this.usersListLoading = true;
    this.appState.api.adminClientUsersListRequest({}, (msg: protocol.IServerResponse) => {
      this.usersListLoading = false;
      this.usersList =
        msg?.adminClientUsersListResponse?.users?.map(user => ({
          ...user,
          lastLoginAtFormatted: user.lastLoginAt?.notEquals(0)
            ? new Date(user.lastLoginAt?.multiply(1000).toNumber() ?? '').toLocaleString()
            : '-',
        })) ?? [];
    });
  };

  @action
  createNewUser = () => {
    if (this.newUserForm.isCreateButtonEnabled) {
      this.createUserLoading = true;
      this.appState.api.adminCreateClientUserRequest(
        {
          login: this.newUserForm.login,
          password: this.newUserForm.password,
          accessGroups: this.newUserForm.accessGroups ? [this.newUserForm.accessGroups] : undefined,
        },
        (msg: protocol.IServerResponse) => {
          this.createUserLoading = false;
          if (msg?.adminCreateClientUserResponse?.user) {
            this.createUserModalStep = 'success';
            this.appState.notifications.addNotification('User created successfully', NotificationType.SUCCESS);
            this.getUsersList();
          } else {
            this.newUserForm.error = formatServerErrors(msg.error);
          }
        },
      );
    }
  };

  @action
  changePassword = () => {
    if (this.newPasswordForm.isCreateButtonEnabled && this.newPasswordModalUserId) {
      this.changePasswordLoading = true;
      this.appState.api.adminUpdateClientUserPasswordRequest(
        {
          userId: this.newPasswordModalUserId,
          newPassword: this.newPasswordForm.password,
        },
        (msg: protocol.IServerResponse) => {
          this.changePasswordLoading = false;
          if (!msg.error) {
            this.changePasswordModalStep = 'success';
          } else {
            this.newPasswordForm.error = formatServerErrors(msg.error);
          }
        },
      );
    }
  };

  @action
  updateUserAccessGroups = (userId: number, newGroups: string[], row: IClientUserFormatted) => {
    if (userId && newGroups) {
      // this.createUserLoading = true;
      this.appState.api.adminUpdateClientUserAccessGroupsRequest(
        {
          userId: userId,
          accessGroups: newGroups,
        },
        (msg: protocol.IServerResponse) => {
          if (!msg?.error) {
            this.appState.notifications.addNotification('Roles updated successfully', NotificationType.SUCCESS);
            row.accessGroups = newGroups;
          } else {
            this.appState.notifications.addNotification(formatServerErrors(msg.error), NotificationType.ERROR);
          }
        },
      );
    }
  };

  @action
  resetPage = () => {
    this.usersListLoading = false;
    this.usersList = undefined;
    this.createUserModalIsOpen = false;
    this.newUserForm = new NewUserForm();
    this.createUserModalStep = 'create-form';
    this.newPasswordForm = new NewPasswordForm();
    this.newPasswordModalUserId = undefined;
    this.changePasswordLoading = false;
    this.changePasswordModalStep = 'new-password';
  };
}
