import Long from 'long';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { protocol } from '../api/proto';
import { ActivityRecord } from './ActivityRecord';
import { AppState } from './AppState';

export const ACTIVITY_PERIODS = [
  'Today',
  'Last week',
  'Last month',
  'Last 3 months',
  'Last 6 months',
  'Last year',
] as const;
export type ActivityPeriod = typeof ACTIVITY_PERIODS[number];

export class ActivityState {
  private appState: AppState;

  activityLogsList = observable.array<ActivityRecord>([]);
  @observable activityPeriod: ActivityPeriod = 'Today';

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  fetchLogs = () => {
    this.appState.api.clientLogsRequest(
      {
        lastCreatedAt: new Long(this.fromToNumTotalFilter.from),
      },
      this.onLogsResponse,
    );
  };

  @action
  onLogsResponse = (msg: protocol.IServerResponse) => {
    if (msg?.clientRequestLogsResponse?.logs?.length) {
      this.activityLogsList.replace(
        msg.clientRequestLogsResponse.logs.map(log => new ActivityRecord(this.appState, log)),
      );
    }
  };

  @action
  resetPage = () => {
    this.activityLogsList.replace([]);
  };

  @action
  changePeriod = (activityPeriod: ActivityPeriod) => {
    this.activityPeriod = activityPeriod;
    this.fetchLogs();
  };

  @computed
  get fromToNumTotalFilter() {
    let from = 0;
    //let to = 0;
    if (this.activityPeriod === 'Today') {
      from = moment().startOf('day').unix();
    } else if (this.activityPeriod === 'Last week') {
      from = moment().subtract('7', 'days').startOf('day').unix();
    } else if (this.activityPeriod === 'Last month') {
      from = moment().subtract('1', 'month').startOf('day').unix();
    } else if (this.activityPeriod === 'Last 3 months') {
      from = moment().subtract('3', 'month').startOf('day').unix();
    } else if (this.activityPeriod === 'Last 6 months') {
      from = moment().subtract('6', 'month').startOf('day').unix();
    } else if (this.activityPeriod === 'Last year') {
      from = moment().subtract('1', 'year').startOf('day').unix();
    }
    return { from };
  }
}
