import { observer } from 'mobx-react';
import './AmlSingleCheckPage.scss';
import { useMountEffect } from '../../hooks/useMountEffect';
import { useState } from '../../state/AppState';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { Icon } from '../../components/Icon/Icon';
import { AmlReportAddressStatistics } from '../../components/aml/AmlReportAddressStatistics/AmlReportAddressStatistics';
import { Button, ButtonSize, ButtonType } from '../../components/controls/Button/Button';
import Skeleton from 'react-loading-skeleton';

export const AmlSingleCheckPage = observer(() => {
  const state = useState();
  const params = useParams();
  const { amlSingleCheckStore } = state;
  const {
    initAmlCheck,
    resetPage,
    check,
    goToChecks,
    checkAgainStart,
    isLoading,
    isChecking,
    checkAgainButtonDisabled,
  } = amlSingleCheckStore;
  useMountEffect(() => {
    if (params.clientId) {
      state.setClientId(params.clientId);
      if (params.id) {
        initAmlCheck(Number(params.id));
      }
    }
  });
  useEffect(() => {
    return () => {
      resetPage();
    };
  }, [resetPage]);
  return (
    <div className="AmlAddressPage">
      <div onClick={goToChecks} className="AmlAddressPage-back-button">
        <Icon iconName="arrow-back-blue" widthInPx={10} heightInPx={16} />
        Report
      </div>
      <div className="AmlAddressPage__title">
        Address <span>{check?.address}</span>
      </div>
      <div className="AmlAddressPage__content">
        <AmlReportAddressStatistics
          isLoading={isLoading || isChecking}
          sources={check?.report?.sources}
          riskScore={check?.report?.riskscore}
          riskLevel={check?.riskLevel}
        />
        <div className="AmlAddressPage__info">
          <div className="AmlAddressPage__info-block">
            <div className="AmlAddressPage__info-title">Check created at</div>
            <div className="AmlAddressPage__info-value">
              {isLoading || isChecking ? (
                <Skeleton
                  width={100}
                  borderRadius={8}
                  duration={0.8}
                  highlightColor={'#FFFFFF'}
                  height={30}
                  count={1}
                />
              ) : check?.createdAtFormatted && check?.createdAt?.notEquals(0) ? (
                check?.createdAtFormatted
              ) : (
                'N/A'
              )}
            </div>
          </div>
          <div className="AmlAddressPage__info-block">
            <Button
              label="Check again"
              disabled={checkAgainButtonDisabled}
              onClick={checkAgainStart}
              type={ButtonType.PRIMARY}
              size={ButtonSize.NORMAL_XL}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
