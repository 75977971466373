import Long from 'long';
import { action, computed, makeObservable, observable } from 'mobx';
import { currencies, protocol } from '../api/proto';
import { ISortedColumn, ITotalFromApi, SORT_DIRECTION } from '../components/Table/Table';
import { AppState } from './AppState';
import { IPeerDetailsFormatted, PeerDetails } from './InvoicesStore';
import { NotificationType } from './NotificationsState';
import { Operation } from './Operation';
import { AccessGroups } from './Roles';
import { formatServerErrors } from './utils/format';
import {
  convertAmountToApi,
  convertMapWithCurrencyNamesAsKeysToWlCurrency,
  formatWlCurrency,
  getCurrencyIcon,
  getCurrencyTitle,
  getWlCurrencyByFiatCurrency,
  getWlCurrencyCode,
} from './utils/Money';
import { emailIsValid } from './utils/validations';

const LIMIT_PAGINATION = 50;

interface IPeerAddress extends protocol.IClientPeerAddress {
  createdAtFormatted?: string;
  formattedCurrency?: string;
  currencyIconName?: string;
  receivedAmountsFormatted?: { [k: number]: string } | null;
  expectedAmountsFormatted?: { [k: number]: string } | null;
}

export type PeerTableTab = 'addresses' | 'transactions';

export class PeerAddressFormatted extends protocol.ClientPeerAddress implements IPeerAddress {
  private appState: AppState;

  @observable expectedAmountsFormatted?: { [k: number]: string } | null;
  @observable receivedAmountsFormatted?: { [k: number]: string } | null;

  constructor(appState: AppState, props: IPeerAddress) {
    super();
    makeObservable(this);
    Object.assign(this, props);
    this.appState = appState;
  }

  @computed
  get receivedAmount() {
    return this.receivedAmountsFormatted
      ? Number(this.receivedAmountsFormatted[this.appState.selectedCurrencyForConvert] ?? 0)
      : 0;
  }

  @computed
  get receivedAmountFormatted() {
    return formatWlCurrency(this.receivedAmount, this.appState.selectedCurrencyForConvert);
  }

  @computed
  get expectedAmount() {
    return this.expectedAmountsFormatted
      ? Number(this.expectedAmountsFormatted[this.appState.selectedCurrencyForConvert] ?? 0)
      : 0;
  }

  @computed
  get expectedAmountFormatted() {
    return formatWlCurrency(this.expectedAmount, this.appState.selectedCurrencyForConvert);
  }

  @computed
  get expectedAmountUsdFormatted() {
    return formatWlCurrency(
      Number(this.expectedAmountsFormatted ? this.expectedAmountsFormatted[currencies.WLCurrency.WLC_USD] ?? 0 : 0),
      currencies.WLCurrency.WLC_USD,
    );
  }

  @computed
  get additionalCurrenciesExpectedAmountsWithoutUsd() {
    const additionalAmounts: string[] = [];
    if (this.expectedAmountsFormatted) {
      Object.keys(this.expectedAmountsFormatted)?.forEach(currency => {
        if (Number(currency) !== currencies.WLCurrency.WLC_USD) {
          additionalAmounts.push(
            formatWlCurrency(Number(this.expectedAmountsFormatted![Number(currency)]), Number(currency)),
          );
        }
      });
    }
    return additionalAmounts;
  }

  @computed
  get receivedAmountUsdFormatted() {
    return formatWlCurrency(
      Number(this.receivedAmountsFormatted ? this.receivedAmountsFormatted[currencies.WLCurrency.WLC_USD] ?? 0 : 0),
      currencies.WLCurrency.WLC_USD,
    );
  }

  @computed
  get additionalCurrenciesReceivedAmountsWithoutUsd() {
    const additionalAmounts: string[] = [];
    if (this.receivedAmountsFormatted) {
      Object.keys(this.receivedAmountsFormatted)?.forEach(currency => {
        if (Number(currency) !== currencies.WLCurrency.WLC_USD) {
          additionalAmounts.push(
            formatWlCurrency(Number(this.receivedAmountsFormatted![Number(currency)]), Number(currency)),
          );
        }
      });
    }
    return additionalAmounts;
  }
}

export class InvoicePeerStore {
  private appState: AppState;

  @observable peerId?: number;

  @observable peerTableTab: PeerTableTab = 'addresses';

  @observable peerInfo?: PeerDetails;
  @observable peerAddressesList?: PeerAddressFormatted[];
  @observable transactionsList?: Operation[];

  @observable peerInfoLoading: boolean = false;
  @observable addressesIsLoading: boolean = false;
  @observable transactionsIsLoading: boolean = false;

  @observable page: number = 0;
  @observable totalPages: number = 0;

  @observable newAddressModalIsOpen: boolean = false;
  @observable createAddressLoading: boolean = false;
  @observable newAddressAmount: string = '';
  @observable newAddressCurrency?: number;
  @observable newAddressEmail: string = '';
  @observable createAddressError?: string;

  @observable changeTransactionAmountModalIsOpen: boolean = false;
  @observable changeTransactionAmountId?: number;
  @observable transactionCurrency?: protocol.FiatCurrency;
  @observable transactionAmount: string = '';
  @observable changeTransactionIsLoading: boolean = false;
  @observable changeTransactionError?: string;

  @observable sortedColumnInvoices?: ISortedColumn = {
    name: 'createdAt',
    direction: SORT_DIRECTION.DESC,
  };

  @observable sortedColumnTransactions?: ISortedColumn = {
    name: 'updatedAtNumber',
    direction: SORT_DIRECTION.DESC,
  };

  @observable transactionsTableTotals?: {
    totalIncomes: { [k: number]: string } | null;
  };

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @computed
  get isReadOnly() {
    return (
      !!this.appState.isReadOnlyAllAccess || this.appState.userAccessGroups?.includes(AccessGroups.INVOICES_READ_ONLY)
    );
  }

  @action
  changePage = (page: number) => {
    this.page = page;
    if (this.peerTableTab === 'addresses') {
      this.loadAddresses();
    } else {
      this.loadTransactions();
    }
  };

  @action
  onChangeAmount = (amount: string) => {
    this.newAddressAmount = amount;
  };

  @action
  onChangeCurrency = (currency?: number | string) => {
    this.newAddressCurrency = currency as number;
  };

  @action
  onChangeEmail = (email: string) => {
    this.newAddressEmail = email;
  };

  @computed
  get newInvoiceSubmitButtonIsEnabled() {
    return !!this.newAddressCurrency && this.emailIsValid;
  }

  @computed
  get emailIsValid() {
    if (!this.newAddressEmail) return true;
    return emailIsValid(this.newAddressEmail);
  }

  @action
  openNewAddressModal = () => {
    this.newAddressModalIsOpen = true;
  };

  @action
  hideNewAddressModal = () => {
    this.newAddressModalIsOpen = false;
    this.resetNewAddressForm();
  };

  @computed
  get changeTransactionSubmitBtnEnabled() {
    return !!this.transactionCurrency && !!this.transactionAmount;
  }

  @computed
  get peerFiatCurrenciesOptions() {
    const currencies =
      this.peerInfo?.additionalCurrencies
        .filter(currency => currency !== protocol.FiatCurrency.USD_FIAT_CUR)
        .map(currency => ({
          value: currency,
          label: protocol.FiatCurrency[currency].replace('_FIAT_CUR', ''),
        })) ?? [];
    currencies.unshift({ value: protocol.FiatCurrency.USD_FIAT_CUR, label: 'USD' });
    return currencies;
  }

  @action
  openChangeTransactionAmountModal = (txId: number) => {
    this.changeTransactionAmountModalIsOpen = true;
    this.changeTransactionAmountId = txId;
  };

  @action
  hideChangeTransactionAmountModal = () => {
    this.changeTransactionAmountModalIsOpen = false;
    this.resetChangeTransactionAmountForm();
  };

  @action
  changeTransactionCurrency = (currency?: string | number) => {
    this.transactionCurrency = currency as protocol.FiatCurrency;
  };

  @action
  changeTransactionAmount = (val: string) => {
    this.transactionAmount = val;
  };

  @action
  onChangeTableTab = (tab: PeerTableTab) => {
    if (tab !== this.peerTableTab) {
      this.resetPaging();
      if (tab === 'addresses') {
        this.loadAddresses();
      } else {
        this.loadTransactions();
      }
    }
    this.peerTableTab = tab;
  };

  @computed
  get transactionsSortBy() {
    if (this.sortedColumnTransactions?.name) {
      switch (this.sortedColumnTransactions.name) {
        case 'updatedAtNumber':
          return protocol.SortBy.DATE_SORT_BY;
        case 'formattedAmount':
          return protocol.SortBy.AMOUNT_SORT_BY;
        case 'formattedCurrency':
          return protocol.SortBy.CURRENCY_SORT_BY;
        case 'id':
          return protocol.SortBy.ID_SORT_BY;
        case 'tagsList':
          return protocol.SortBy.TAGS_SORT_BY;
      }
    }
    return undefined;
  }

  @computed
  get tableTransactionsTotals(): ITotalFromApi[] {
    const totalIncomes =
      this.transactionsTableTotals?.totalIncomes &&
      this.transactionsTableTotals?.totalIncomes[this.appState.selectedCurrencyForConvert]
        ? this.transactionsTableTotals?.totalIncomes[this.appState.selectedCurrencyForConvert]
        : 0;

    return [
      {
        key: 'formattedAmount',
        value: formatWlCurrency(Number(totalIncomes), this.appState.selectedCurrencyForConvert),
      },
    ];
  }

  @action
  onSortColumnInvoicesClick = (name: string) => {
    if (this.sortedColumnInvoices?.name && this.sortedColumnInvoices.name === name) {
      if (this.sortedColumnInvoices.direction === SORT_DIRECTION.ASC) {
        this.sortedColumnInvoices = undefined;
      } else if (this.sortedColumnInvoices.direction === SORT_DIRECTION.DESC) {
        this.sortedColumnInvoices.direction = SORT_DIRECTION.ASC;
      } else {
        this.sortedColumnInvoices.direction = SORT_DIRECTION.DESC;
      }
    } else {
      this.sortedColumnInvoices = {
        name,
        direction: SORT_DIRECTION.DESC,
      };
    }
    this.page = 0;
    this.loadAddresses();
  };

  @action
  onSortColumnTransactionsClick = (name: string) => {
    if (this.sortedColumnTransactions?.name && this.sortedColumnTransactions.name === name) {
      if (this.sortedColumnTransactions.direction === SORT_DIRECTION.ASC) {
        this.sortedColumnTransactions = undefined;
      } else if (this.sortedColumnTransactions.direction === SORT_DIRECTION.DESC) {
        this.sortedColumnTransactions.direction = SORT_DIRECTION.ASC;
      } else {
        this.sortedColumnTransactions.direction = SORT_DIRECTION.DESC;
      }
    } else {
      this.sortedColumnTransactions = {
        name,
        direction: SORT_DIRECTION.DESC,
      };
    }
    this.page = 0;
    this.loadTransactions();
  };

  @action
  init(peerId: number) {
    this.peerId = peerId;
    this.loadPeerData();
    this.loadAddresses();
    //this.loadInvoices();
  }

  @action
  loadPeerData() {
    if (this.peerId) {
      this.peerInfoLoading = true;
      this.appState.api.clientPeerDetailsRequest({ id: this.peerId }, (msg: protocol.IServerResponse) => {
        this.peerInfoLoading = false;
        if (msg?.clientPeerDetailsResponse?.peer) {
          this.peerInfo = new PeerDetails(this.appState, {
            ...msg?.clientPeerDetailsResponse?.peer,
            totalExpectedAmountsFormatted: convertMapWithCurrencyNamesAsKeysToWlCurrency(
              msg?.clientPeerDetailsResponse?.peer.totalExpectedAmounts,
            ),
            totalReceivedAmountsFormatted: convertMapWithCurrencyNamesAsKeysToWlCurrency(
              msg?.clientPeerDetailsResponse?.peer.totalReceivedAmounts,
            ),
            lastReceivedAmountsFormatted: convertMapWithCurrencyNamesAsKeysToWlCurrency(
              msg?.clientPeerDetailsResponse?.peer.lastReceivedAmounts,
            ),
            lastTxDateFormatted:
              msg?.clientPeerDetailsResponse?.peer.lastTxDate &&
              msg?.clientPeerDetailsResponse?.peer.lastTxDate?.notEquals(0)
                ? new Date(
                    msg?.clientPeerDetailsResponse?.peer.lastTxDate?.multiply(1000).toNumber() ?? '',
                  ).toLocaleString()
                : '-',
          });
        }
      });
    }
  }

  loadAddresses() {
    if (this.peerId) {
      this.addressesIsLoading = true;
      this.appState.api.clientPeerAddressesListRequest(
        {
          peerId: this.peerId,
        },
        this.onAddressesResponse,
      );
    }
  }

  @action
  onAddressesResponse = (msg: protocol.IServerResponse) => {
    this.addressesIsLoading = false;
    if (msg?.clientPeerAddressesListResponse?.addresses?.length) {
      this.peerAddressesList = msg.clientPeerAddressesListResponse.addresses.map(
        address =>
          new PeerAddressFormatted(this.appState, {
            ...address,
            formattedCurrency: address.currency ? getCurrencyTitle(address.currency) : '',
            currencyIconName: address.currency ? getCurrencyIcon(address.currency) : '',
            createdAtFormatted:
              address.createdAt && address.createdAt.notEquals(0)
                ? new Date(address.createdAt?.multiply(1000).toNumber() ?? '').toLocaleString()
                : '-',
            receivedAmountsFormatted: convertMapWithCurrencyNamesAsKeysToWlCurrency(address.receivedAmounts),
            expectedAmountsFormatted: convertMapWithCurrencyNamesAsKeysToWlCurrency(address.expectedAmounts),
          }),
      );
    }
  };

  /*@action
  loadInvoices() {
    if (this.peerId) {
      this.invoicesIsLoading = true;
      this.appState.api.clientPeerInvoicesListRequest(
        {
          peerId: this.peerId,
          limit: Long.fromNumber(LIMIT_PAGINATION),
          offset: Long.fromNumber(this.page * LIMIT_PAGINATION),
          sortBy: this.invoicesSortBy,
          sortDesc:
            this.sortedColumnInvoices?.direction !== undefined
              ? this.sortedColumnInvoices?.direction === SORT_DIRECTION.DESC
              : undefined,
        },
        this.onInvoicesListResponse,
      );
    }
  }

  @action
  onInvoicesListResponse = (msg: protocol.IServerResponse) => {
    this.invoicesIsLoading = false;
    if (msg?.clientPeerInvoicesListResponse?.invoices?.length) {
      this.peerInvoicesList = msg.clientPeerInvoicesListResponse.invoices.map(invoice => ({
        ...invoice,
        createdAtFormatted:
          invoice.createdAt && invoice.createdAt.notEquals(0)
            ? new Date(invoice.createdAt?.multiply(1000).toNumber() ?? '').toLocaleString()
            : '-',
        receivedAmountUsdFormatted: invoice.receivedAmountUsd
          ? formatWlCurrency(Number(invoice.receivedAmountUsd), currencies.WLCurrency.WLC_USD)
          : '-',
        requestedAmountUsdFormatted: invoice.requestedAmountUsd
          ? formatWlCurrency(Number(invoice.requestedAmountUsd), currencies.WLCurrency.WLC_USD)
          : '-',
      }));
    }
    this.totalPages = msg?.clientPeerInvoicesListResponse?.totalPages?.toNumber() ?? 0;
  };*/

  @action
  loadTransactions() {
    if (this.peerId) {
      this.transactionsIsLoading = true;
      this.appState.api.clientPeerTransactionsRequest(
        {
          peerId: this.peerId,
          limit: Long.fromNumber(LIMIT_PAGINATION),
          offset: Long.fromNumber(this.page * LIMIT_PAGINATION),
          sortBy: this.transactionsSortBy,
          sortDesc:
            this.sortedColumnTransactions?.direction !== undefined
              ? this.sortedColumnTransactions?.direction === SORT_DIRECTION.DESC
              : undefined,
        },
        this.onTransactionsResponse,
      );
    }
  }

  @action
  onTransactionsResponse = (msg: protocol.IServerResponse) => {
    this.transactionsIsLoading = false;
    if (msg?.clientPeerTransactionsResponse?.transactions?.length) {
      this.transactionsList = msg?.clientPeerTransactionsResponse?.transactions.map(
        transaction => new Operation(this.appState, transaction),
      );
    } else {
      this.transactionsList = [];
    }
    this.transactionsTableTotals = {
      totalIncomes: convertMapWithCurrencyNamesAsKeysToWlCurrency(msg?.clientPeerTransactionsResponse?.totalIncomes),
    };
    this.totalPages = msg?.clientPeerTransactionsResponse?.totalPages?.toNumber() ?? 0;
  };

  /* @action.bound
  fetchTags(operations: Operation[]) {
    this.appState.api.tagsRequest(
      {
        filterTxIds: operations?.filter(o => !!o.id).map(o => o.id),
        filterClientIds: this.appState.clientId ? [this.appState.clientId] : [],
      },
      (msg: protocol.IServerResponse) => this.onTagsResponse(msg, operations),
    );
  }

  @action.bound
  onTagsResponse(msg: protocol.IServerResponse, operations: Operation[]) {
    if (msg?.listTagsResponse?.tags?.length) {
      const tags = msg.listTagsResponse.tags;
      this.transactionsList = operations.map(operation => {
        if (operation.id) {
          const operationTags = tags
            .filter(tag => tag.txIds?.includes(operation.id) && !!tag.name)
            .map(tag => tag.name!);
          operation.tags = operationTags;
        }
        return operation;
      });
      this.transactionsIsLoading = false;
    } else {
      this.transactionsIsLoading = false;
      this.transactionsList = operations;
    }
  }*/

  @action
  deleteAddress = (address?: string) => {
    if (this.peerId && address) {
      this.peerInfoLoading = true;
      this.appState.api.deleteClientPeerAddressRequest(
        {
          peerId: this.peerId,
          address: address,
        },
        (msg: protocol.IServerResponse) => {
          if (!msg.error) {
            this.appState.notifications.addNotification('Address successfully deleted', NotificationType.SUCCESS);
          } else {
            this.appState.notifications.addNotification(formatServerErrors(msg.error), NotificationType.ERROR);
          }
          this.loadPeerData();
          this.loadAddresses();
        },
      );
    }
  };

  @action
  createNewAddress = () => {
    if (this.peerId && this.newAddressCurrency) {
      this.createAddressLoading = true;
      this.appState.api.generateNewClientPeerAddressRequest(
        {
          peerId: this.peerId,
          currency: this.newAddressCurrency,
          amountUsd: this.newAddressAmount
            ? convertAmountToApi(this.newAddressAmount, currencies.WLCurrency.WLC_USD)
            : undefined,
          email: this.newAddressEmail,
        },
        this.onCreateAddressResponse,
      );
    }
  };

  @action
  onCreateAddressResponse = (msg: protocol.IServerResponse) => {
    this.createAddressLoading = false;
    if (msg?.generateNewClientPeerAddressResponse?.address) {
      this.appState.notifications.addNotification('Address successfully created', NotificationType.SUCCESS);
      this.hideNewAddressModal();
      this.peerTableTab = 'addresses';
      this.resetNewAddressForm();
      this.resetPaging();
      this.loadAddresses();
      this.loadPeerData();
    } else {
      this.createAddressError = formatServerErrors(msg.error);
    }
  };

  @action
  changeTransactionAmountSubmitClick = () => {
    if (this.changeTransactionAmountId) {
      this.changeTransactionIsLoading = true;
      this.appState.api.clientPeerChangeTransactionAmountRequest(
        {
          transactionId: this.changeTransactionAmountId,
          currency: this.transactionCurrency,
          amount: convertAmountToApi(this.transactionAmount, getWlCurrencyByFiatCurrency(this.transactionCurrency!)),
        },
        (msg: protocol.IServerResponse) => {
          this.changeTransactionIsLoading = false;
          if (msg.error) {
            this.changeTransactionError = formatServerErrors(msg.error);
          } else {
            this.appState.notifications.addNotification('Amount changed successfullty', NotificationType.SUCCESS);
            this.hideChangeTransactionAmountModal();
            this.loadTransactions();
          }
        },
      );
    }
  };

  @action
  resetAll = () => {
    this.resetNewAddressForm();
    this.resetPaging();

    this.peerId = undefined;
    this.peerTableTab = 'addresses';
    this.peerInfo = undefined;
    this.peerAddressesList = undefined;
    this.transactionsList = undefined;
    this.addressesIsLoading = false;
    this.transactionsIsLoading = false;
    this.createAddressLoading = false;
    this.peerInfoLoading = false;
    this.transactionsTableTotals = undefined;
  };

  @action
  resetPaging = () => {
    this.page = 0;
    this.totalPages = 0;
    this.sortedColumnInvoices = {
      name: 'createdAt',
      direction: SORT_DIRECTION.DESC,
    };
    this.sortedColumnTransactions = {
      name: 'updatedAtNumber',
      direction: SORT_DIRECTION.DESC,
    };
  };

  @action
  resetNewAddressForm = () => {
    this.createAddressError = undefined;
    this.newAddressAmount = '';
    this.newAddressEmail = '';
    this.createAddressLoading = false;
  };

  @action
  resetChangeTransactionAmountForm = () => {
    this.changeTransactionAmountId = undefined;
    this.transactionCurrency = undefined;
    this.transactionAmount = '';
    this.changeTransactionIsLoading = false;
    this.changeTransactionError = undefined;
  };
}
