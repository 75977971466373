import './Loader.scss';

export const Loader = () => {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export const FullWidthLoader = () => {
  return (
    <div className="full-width-loader">
      <Loader />
    </div>
  );
};

export const FullPageLoader = () => {
  return (
    <div className="full-page-loader">
      <FullWidthLoader />
    </div>
  );
};
