import { protocol } from '../../api/proto';

export function formatServerErrors(error?: protocol.ServerError | null) {
  switch (error) {
    case protocol.ServerError.ParsingError:
      return 'An error occurred while requesting: ParsingError';
    case protocol.ServerError.ProcessingError:
      return 'An error occurred while requesting: ProcessingError';
    case protocol.ServerError.NotImplemented:
      return 'Not implemented';
    case protocol.ServerError.NotFound:
      return 'Not Found';
    case protocol.ServerError.UnknownCurrency:
      return 'Unknown currency';
    case protocol.ServerError.NotEnoughBalance:
      return 'Not enough balance';
    case protocol.ServerError.UnknownClient:
      return 'Unknown ClientId';
    case protocol.ServerError.InvalidAddress:
      return 'Invalid address';
    case protocol.ServerError.NoAddressesForCheck:
      return 'No addresses for check';
    case protocol.ServerError.NoAMLAvailableChecksLeft:
      return 'No AML available checks left';
    default:
      return error && protocol.ServerError[error]
        ? /(?=[A-Z])/g
            [Symbol.split](protocol.ServerError[error])
            .map((_, i) => (i === 0 ? _ : _.toLowerCase()))
            .join(' ')
        : 'Unknown error';
  }
}

export function camelCaseToString(str: string) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export function snakeCaseToString(str: string) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    })
    .replaceAll('_', ' ');
}
