import { RISK_LEVEL, SignalsStatictics, SignalStatItem } from '../../../state/utils/amlUtils';
import './AmlSignalsStatistics.scss';
import cn from 'classnames';
import { camelCaseToString } from '../../../state/utils/format';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface AmlSignalsStatisticsProps {
  signalsStatistics: SignalsStatictics;
  className?: string;
  isLoading?: boolean;
}

export const AmlSignalsStatistics = ({ signalsStatistics, className, isLoading }: AmlSignalsStatisticsProps) => {
  const { highRisk, lowRisk, mediumRisk } = signalsStatistics;
  return (
    <div className={cn('AmlSignalsStatistics', className)}>
      <div className="AmlSignalsStatistics__block">
        <div className="AmlSignalsStatistics__block-title">Danger</div>

        {isLoading ? (
          <AmlSignalsStatLoader />
        ) : highRisk?.length ? (
          highRisk.map(item => <AmlSignalsStatisticItem key={item.key} statItem={item} riskLevel={RISK_LEVEL.HIGH} />)
        ) : (
          <div className="no-data">No data</div>
        )}
      </div>
      <div className="AmlSignalsStatistics__block">
        <div className="AmlSignalsStatistics__block-title">Suspicious sources</div>
        {isLoading ? (
          <AmlSignalsStatLoader />
        ) : mediumRisk?.length ? (
          mediumRisk.map(item => (
            <AmlSignalsStatisticItem key={item.key} statItem={item} riskLevel={RISK_LEVEL.MEDIUM} />
          ))
        ) : (
          <div className="no-data">No data</div>
        )}
      </div>
      <div className="AmlSignalsStatistics__block">
        <div className="AmlSignalsStatistics__block-title">Trusted</div>
        {isLoading ? (
          <AmlSignalsStatLoader />
        ) : lowRisk?.length ? (
          lowRisk.map(item => <AmlSignalsStatisticItem key={item.key} statItem={item} riskLevel={RISK_LEVEL.LOW} />)
        ) : (
          <div className="no-data">No data</div>
        )}
      </div>
    </div>
  );
};

interface AmlSignalsStatisticItemProps {
  statItem: SignalStatItem;
  riskLevel: RISK_LEVEL;
}

const AmlSignalsStatisticItem = ({ statItem, riskLevel }: AmlSignalsStatisticItemProps) => {
  return (
    <div className={cn('AmlSignalsStatisticItem', riskLevel)}>
      <div className="progress-bar">
        <div className="progress-line" style={{ width: statItem.percent + '%' }} />
      </div>
      <div className="info-block">
        <div className="title">{statItem.title || camelCaseToString(statItem.key)}</div>
        <div className="value">
          {statItem.count ? (
            <span>
              <span>{statItem.count}</span>
              <span className="percent-block">{statItem.percent}%</span>
            </span>
          ) : statItem.percent ? (
            `${statItem.percent}%`
          ) : (
            '0'
          )}
        </div>
      </div>
    </div>
  );
};

const AmlSignalsStatLoader = () => (
  <div>
    <Skeleton width={'100%'} borderRadius={8} duration={0.8} highlightColor={'#FFFFFF'} height={25} count={1} />
    <Skeleton width={'70%'} borderRadius={8} duration={0.8} highlightColor={'#FFFFFF'} height={25} count={1} />
    <Skeleton width={'100%'} borderRadius={8} duration={0.8} highlightColor={'#FFFFFF'} height={25} count={1} />
  </div>
);
