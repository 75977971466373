import { protocol } from '../../api/proto';
import { round } from './round';
import { ISelectOption } from '../../components/controls/Select/Select';
import { camelCaseToString, snakeCaseToString } from './format';

const sourcesList = [
  'atm',
  'childExploitation',
  'darkMarket',
  'darkService',
  'enforcementAction',
  'exchangeFraudulent',
  'exchangeLicensed',
  'exchangeUnlicensed',
  'gambling',
  'illegalService',
  'liquidityPools',
  'marketplace',
  'miner',
  'mixer',
  'other',
  'p2pExchangeLicensed',
  'p2pExchangeUnlicensed',
  'payment',
  'ransom',
  'sanctions',
  'scam',
  'seizedAssets',
  'stolenCoins',
  'terrorismFinancing',
  'wallet',
];

export enum RISK_LEVEL {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  UNKNOWN = 'unknown',
}

export function getRiskLevelForChartByApiRiskLevel(riskLevel?: protocol.AMLRiskLevel) {
  if (riskLevel) {
    if (riskLevel === protocol.AMLRiskLevel.HIGH_AML_RISK_LEVEL) {
      return RISK_LEVEL.HIGH;
    }
    if (riskLevel === protocol.AMLRiskLevel.MEDIUM_AML_RISK_LEVEL) {
      return RISK_LEVEL.MEDIUM;
    }
    if (riskLevel === protocol.AMLRiskLevel.LOW_AML_RISK_LEVEL) {
      return RISK_LEVEL.LOW;
    }
  }
  return RISK_LEVEL.UNKNOWN;
}
export interface SignalStatItem {
  key: string;
  title?: string;
  count?: number;
  percent: number;
  riskLevel?: protocol.AMLSourceType;
}

export interface SignalsStatictics {
  highRisk: SignalStatItem[];
  mediumRisk: SignalStatItem[];
  lowRisk: SignalStatItem[];
}

export function getSourcesStatItemsForAddress(sources?: { [key: string]: protocol.IAMLSource }) {
  const signalsItems: SignalStatItem[] = [];
  if (sources) {
    Object.keys(sources).forEach(key => {
      const item = sources[key];
      if (item.value) {
        signalsItems.push({
          key: key || '',
          title: snakeCaseToString(key || ''),
          percent: item.value ? round(item.value * 100) : 0,
          riskLevel: item.type || protocol.AMLSourceType.TRUSTED_AML_SOURCE_TYPE,
        });
      }
    });
  }
  return signalsItems.sort((a, b) => b.percent - a.percent);
}

export function getSignalsAsOptions(): ISelectOption[] {
  return sourcesList.map(key => {
    return {
      label: camelCaseToString(key),
      value: key,
    };
  });
}

export function getSourcesMainStatByRiskLevel(sources?: { [key: string]: protocol.IAMLGroupSource }) {
  const highRisk: SignalStatItem[] = [];
  const mediumRisk: SignalStatItem[] = [];
  const lowRisk: SignalStatItem[] = [];

  if (sources) {
    Object.keys(sources).forEach(key => {
      const item = sources[key];
      if (item.count && item.count.notEquals(0)) {
        const signalStatItem: SignalStatItem = {
          key: key || '',
          title: snakeCaseToString(key || ''),
          percent: item.avg ? round(item.avg * 100) : 0,
          count: item.count?.toNumber() ?? 0,
        };
        if (item.type === protocol.AMLSourceType.DANGEROUS_AML_SOURCE_TYPE) {
          highRisk.push(signalStatItem);
        } else if (item.type === protocol.AMLSourceType.SUSPICIOUS_AML_SOURCE_TYPE) {
          mediumRisk.push(signalStatItem);
        } else if (item.type === protocol.AMLSourceType.TRUSTED_AML_SOURCE_TYPE) {
          lowRisk.push(signalStatItem);
        }
      }
    });
  }

  return {
    highRisk,
    mediumRisk,
    lowRisk,
  };
}

export function getSourcesMainStatByRiskLevelForAddress(sources?: { [key: string]: protocol.IAMLSource }) {
  const highRisk: SignalStatItem[] = [];
  const mediumRisk: SignalStatItem[] = [];
  const lowRisk: SignalStatItem[] = [];

  if (sources) {
    Object.keys(sources).forEach(key => {
      const item = sources[key];
      if (item.value) {
        const signalStatItem: SignalStatItem = {
          key: key || '',
          title: snakeCaseToString(key || ''),
          percent: item.value ? round(item.value * 100) : 0,
        };
        if (item.type === protocol.AMLSourceType.DANGEROUS_AML_SOURCE_TYPE) {
          highRisk.push(signalStatItem);
        } else if (item.type === protocol.AMLSourceType.SUSPICIOUS_AML_SOURCE_TYPE) {
          mediumRisk.push(signalStatItem);
        } else if (item.type === protocol.AMLSourceType.TRUSTED_AML_SOURCE_TYPE) {
          lowRisk.push(signalStatItem);
        }
      }
    });
  }

  return {
    highRisk,
    mediumRisk,
    lowRisk,
  };
}
