import { protocol } from './proto';
import { v4 as uuid } from 'uuid';
import axios, { AxiosError, AxiosInstance } from 'axios';
//import { runInAction } from "mobx";
import { AppState } from '../state/AppState';
import appSettings from '../appSettings.json';

export type Callback = (msg: protocol.IServerResponse) => void;
export type ErrorCallback = (code: number, message: string) => void;

const ERROR_TIMEOUT = 500;
const apiPath =
  process.env.NODE_ENV === 'development' ? '/eaf15bcd-9c2c-4d51-8c78-68b4dd6a0634/exec' : appSettings.apiPath;
export default class APIConnector {
  private batchMode: boolean = false;
  private skipOnErrors: boolean = false;
  private apiIsDown: boolean = false;
  private packetsInFlight: number = 0;

  private unsentActions: protocol.ClientRequest[] = [];
  private handlers: { [k: string]: Callback } = {};
  //private errorCallback: ErrorCallback;
  private appState: AppState;

  private requester: AxiosInstance = axios.create({
    baseURL: appSettings.apiUrl,
    responseType: 'arraybuffer',
    headers: { 'Content-Type': 'application/x-protobuf' },
  });

  constructor(appState: AppState) {
    this.appState = appState;
  }

  transactionsRequest(cb: Callback) {
    const transactionsRequest = new protocol.TransactionsRequest({});
    const action = new protocol.ClientRequest();
    action.transactionsRequest = transactionsRequest;

    this.enqueueAction(action, cb);
  }

  balancesRequest(cb: Callback) {
    const balancesRequest = new protocol.BalancesRequest({
      clientId: this.appState.clientId,
    });
    const action = new protocol.ClientRequest();
    action.balancesRequest = balancesRequest;

    this.enqueueAction(action, cb);
  }

  balancesTotalsRequest(params: protocol.IClientTotalBalancesRequest, cb: Callback, abortController?: AbortController) {
    const clientTotalBalancesRequest = new protocol.ClientTotalBalancesRequest(params);
    const action = new protocol.ClientRequest();
    action.clientTotalBalancesRequest = clientTotalBalancesRequest;

    this.enqueueAction(action, cb, undefined, undefined, abortController);
  }

  mainAccounts(cb: Callback) {
    const mainAccountsRequest = new protocol.MainAccountsRequest();
    const action = new protocol.ClientRequest();
    action.mainAccountsRequest = mainAccountsRequest;

    this.enqueueAction(action, cb);
  }

  transactions(params: protocol.ITransactionsRequest, cb: Callback) {
    const transactionsRequest = new protocol.TransactionsRequest(params);
    const action = new protocol.ClientRequest({
      transactionsRequest,
    });
    this.enqueueAction(action, cb);
  }

  transactionsPaged(params: protocol.ITransactionsPagedRequest, cb: Callback) {
    const transactionsPagedRequest = new protocol.TransactionsPagedRequest(params);
    const action = new protocol.ClientRequest({
      transactionsPagedRequest,
    });
    this.enqueueAction(action, cb);
  }

  transactionsStats(params: protocol.ITransactionsStatsRequest, cb: Callback) {
    const transactionsStatsRequest = new protocol.TransactionsStatsRequest(params);
    const action = new protocol.ClientRequest({
      transactionsStatsRequest,
    });
    this.enqueueAction(action, cb);
  }

  withdrawRequest(params: protocol.IEnqueuePayoutRequest, cb: Callback) {
    const enqueuePayoutRequest = new protocol.EnqueuePayoutRequest(params);
    const action = new protocol.ClientRequest({
      enqueuePayoutRequest,
    });
    this.enqueueAction(action, cb);
  }

  convertCurrencies(entries: protocol.IConvertCurrenciesEntry[], cb: Callback, abortController?: AbortController) {
    const convertCryptoToFiatRequest = new protocol.ConvertCryptoToFiatRequest({
      entries,
    });
    const action = new protocol.ClientRequest({
      convertCryptoToFiatRequest,
    });
    this.enqueueAction(action, cb, true, false, abortController);
  }

  transactionFeeRequest(params: protocol.ITransactionFeeRequest, cb: Callback, abortController?: AbortController) {
    const transactionFeeRequest = new protocol.TransactionFeeRequest(params);
    const action = new protocol.ClientRequest({
      transactionFeeRequest,
    });
    this.enqueueAction(action, cb, true, false, abortController);
  }

  tagsRequest(params: protocol.IListTagsRequest, cb: Callback) {
    const listTagsRequest = new protocol.ListTagsRequest(params);
    const action = new protocol.ClientRequest({
      listTagsRequest,
    });
    this.enqueueAction(action, cb);
  }

  modifyTagsRequest(params: protocol.ModifyTagsRequest.IModifyTag, cb: Callback) {
    const modifyTagsRequest = new protocol.ModifyTagsRequest({
      modifyTags: params ? [params] : [],
    });
    const action = new protocol.ClientRequest({
      modifyTagsRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientLogsRequest(params: protocol.IClientRequestLogsRequest, cb: Callback) {
    const clientRequestLogsRequest = new protocol.ClientRequestLogsRequest(params);
    const action = new protocol.ClientRequest({
      clientRequestLogsRequest,
    });
    this.enqueueAction(action, cb);
  }

  listPayoutRequestsRequest(cb: Callback) {
    const listPayoutRequestsRequest = new protocol.ListPayoutRequestsRequest();
    const action = new protocol.ClientRequest({
      listPayoutRequestsRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlBatchCheckRequest(params: protocol.IAMLClientBatchCheckStartRequest, cb: Callback) {
    const amlClientBatchCheckStartRequest = new protocol.AMLClientBatchCheckStartRequest(params);
    const action = new protocol.ClientRequest({
      amlClientBatchCheckStartRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlBatchCheckResultRequest(params: protocol.IAMLBatchCheckResultsRequest, cb: Callback) {
    const amlBatchCheckResultsRequest = new protocol.AMLBatchCheckResultsRequest(params);
    const action = new protocol.ClientRequest({
      amlBatchCheckResultsRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlClientBatchStatsRequest(params: protocol.IAMLClientBatchStatsRequest, cb: Callback) {
    const amlClientBatchStatsRequest = new protocol.AMLClientBatchStatsRequest(params);
    const action = new protocol.ClientRequest({
      amlClientBatchStatsRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlClientBatchAddressesListRequest(params: protocol.IAMLClientBatchAddressesListRequest, cb: Callback) {
    const amlClientBatchAddressesListRequest = new protocol.AMLClientBatchAddressesListRequest(params);
    const action = new protocol.ClientRequest({
      amlClientBatchAddressesListRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlClientBatchChecksListRequest(params: protocol.IAMLClientBatchChecksListRequest, cb: Callback) {
    const amlClientBatchChecksListRequest = new protocol.AMLClientBatchChecksListRequest(params);
    const action = new protocol.ClientRequest({
      amlClientBatchChecksListRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlBatchCheckStopRequest(params: protocol.IAMLClientBatchCheckStopRequest, cb: Callback) {
    const amlClientBatchCheckStopRequest = new protocol.AMLClientBatchCheckStopRequest(params);
    const action = new protocol.ClientRequest({
      amlClientBatchCheckStopRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlOverallStatRequest(cb: Callback) {
    const amlClientOverallStatsRequest = new protocol.AMLClientOverallStatsRequest();
    const action = new protocol.ClientRequest({
      amlClientOverallStatsRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlAddressesListRequest(params: protocol.IAMLClientAddressesListRequest, cb: Callback) {
    const amlClientAddressesListRequest = new protocol.AMLClientAddressesListRequest(params);
    const action = new protocol.ClientRequest({
      amlClientAddressesListRequest,
    });
    this.enqueueAction(action, cb);
  }

  feeRatesRequest(params: protocol.IFeeRatesRequest, cb: Callback) {
    const feeRatesRequest = new protocol.FeeRatesRequest(params);
    const action = new protocol.ClientRequest({
      feeRatesRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientPeersListRequest(params: protocol.IClientPeersListRequest, cb: Callback) {
    const clientPeersListRequest = new protocol.ClientPeersListRequest(params);
    const action = new protocol.ClientRequest({
      clientPeersListRequest,
    });
    this.enqueueAction(action, cb);
  }

  createClientPeerRequest(params: protocol.ICreateClientPeerRequest, cb: Callback) {
    const createClientPeerRequest = new protocol.CreateClientPeerRequest(params);
    const action = new protocol.ClientRequest({
      createClientPeerRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientPeersShortListRequest(params: protocol.IClientPeersShortListRequest, cb: Callback) {
    const clientPeersShortListRequest = new protocol.ClientPeersShortListRequest(params);
    const action = new protocol.ClientRequest({
      clientPeersShortListRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientPeerDetailsRequest(params: protocol.IClientPeerDetailsRequest, cb: Callback) {
    const clientPeerDetailsRequest = new protocol.ClientPeerDetailsRequest(params);
    const action = new protocol.ClientRequest({
      clientPeerDetailsRequest,
    });
    this.enqueueAction(action, cb);
  }

  regenerateClientPeerAddressRequest(params: protocol.IRegenerateClientPeerAddressRequest, cb: Callback) {
    const regenerateClientPeerAddressRequest = new protocol.RegenerateClientPeerAddressRequest(params);
    const action = new protocol.ClientRequest({
      regenerateClientPeerAddressRequest,
    });
    this.enqueueAction(action, cb);
  }

  generateNewClientPeerAddressRequest(params: protocol.IGenerateNewClientPeerAddressRequest, cb: Callback) {
    const generateNewClientPeerAddressRequest = new protocol.GenerateNewClientPeerAddressRequest(params);
    const action = new protocol.ClientRequest({
      generateNewClientPeerAddressRequest,
    });
    this.enqueueAction(action, cb);
  }

  deleteClientPeerAddressRequest(params: protocol.IDeleteClientPeerAddressRequest, cb: Callback) {
    const deleteClientPeerAddressRequest = new protocol.DeleteClientPeerAddressRequest(params);
    const action = new protocol.ClientRequest({
      deleteClientPeerAddressRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientPeerAddressesListRequest(params: protocol.IClientPeerAddressesListRequest, cb: Callback) {
    const clientPeerAddressesListRequest = new protocol.ClientPeerAddressesListRequest(params);
    const action = new protocol.ClientRequest({
      clientPeerAddressesListRequest,
    });
    this.enqueueAction(action, cb);
  }

  /*createClientPeerInvoiceRequest(params: protocol.ICreateClientPeerInvoiceRequest, cb: Callback) {
    const createClientPeerInvoiceRequest = new protocol.CreateClientPeerInvoiceRequest(params);
    const action = new protocol.ClientRequest({
      createClientPeerInvoiceRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientPeerInvoicesListRequest(params: protocol.IClientPeerInvoicesListRequest, cb: Callback) {
    const clientPeerInvoicesListRequest = new protocol.ClientPeerInvoicesListRequest(params);
    const action = new protocol.ClientRequest({
      clientPeerInvoicesListRequest,
    });
    this.enqueueAction(action, cb);
  }*/

  clientPeerTransactionsRequest(params: protocol.IClientPeerTransactionsRequest, cb: Callback) {
    const clientPeerTransactionsRequest = new protocol.ClientPeerTransactionsRequest(params);
    const action = new protocol.ClientRequest({
      clientPeerTransactionsRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientPeersStatsRequest(params: protocol.IClientPeersStatsRequest, cb: Callback) {
    const clientPeersStatsRequest = new protocol.ClientPeersStatsRequest(params);
    const action = new protocol.ClientRequest({
      clientPeersStatsRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientPeerTransactionsStatsRequest(params: protocol.IClientPeerTransactionsStatsRequest, cb: Callback) {
    const clientPeerTransactionsStatsRequest = new protocol.ClientPeerTransactionsStatsRequest(params);
    const action = new protocol.ClientRequest({
      clientPeerTransactionsStatsRequest,
    });
    this.enqueueAction(action, cb);
  }

  adminClientUsersListRequest(params: protocol.IAdminClientUsersListRequest, cb: Callback) {
    const adminClientUsersListRequest = new protocol.AdminClientUsersListRequest(params);
    const action = new protocol.ClientRequest({
      adminClientUsersListRequest,
    });
    this.enqueueAction(action, cb);
  }

  adminCreateClientUserRequest(params: protocol.IAdminCreateClientUserRequest, cb: Callback) {
    const adminCreateClientUserRequest = new protocol.AdminCreateClientUserRequest(params);
    const action = new protocol.ClientRequest({
      adminCreateClientUserRequest,
    });
    this.enqueueAction(action, cb);
  }

  adminUpdateClientUserAccessGroupsRequest(params: protocol.IAdminUpdateClientUserAccessGroupsRequest, cb: Callback) {
    const adminUpdateClientUserAccessGroupsRequest = new protocol.AdminUpdateClientUserAccessGroupsRequest(params);
    const action = new protocol.ClientRequest({
      adminUpdateClientUserAccessGroupsRequest,
    });
    this.enqueueAction(action, cb);
  }

  checkSessionRequest(params: protocol.ICheckSessionRequest, cb: Callback) {
    const checkSessionRequest = new protocol.CheckSessionRequest(params);
    const action = new protocol.ClientRequest({
      checkSessionRequest,
    });
    this.enqueueAction(action, cb);
  }

  authUserRequest(params: protocol.IAuthUserRequest, cb: Callback) {
    const authUserRequest = new protocol.AuthUserRequest(params);
    const action = new protocol.ClientRequest({
      authUserRequest,
    });
    this.enqueueAction(action, cb);
  }

  adminUpdateClientUserPasswordRequest(params: protocol.IAdminUpdateClientUserPasswordRequest, cb: Callback) {
    const adminUpdateClientUserPasswordRequest = new protocol.AdminUpdateClientUserPasswordRequest(params);
    const action = new protocol.ClientRequest({
      adminUpdateClientUserPasswordRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientSystemAddressesTagGroupsListRequest(params: protocol.IClientSystemAddressesTagGroupsListRequest, cb: Callback) {
    const clientSystemAddressesTagGroupsListRequest = new protocol.ClientSystemAddressesTagGroupsListRequest(params);
    const action = new protocol.ClientRequest({
      clientSystemAddressesTagGroupsListRequest,
    });
    this.enqueueAction(action, cb);
  }

  createClientSystemAddressRequest(params: protocol.ICreateClientSystemAddressRequest, cb: Callback) {
    const createClientSystemAddressRequest = new protocol.CreateClientSystemAddressRequest(params);
    const action = new protocol.ClientRequest({
      createClientSystemAddressRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientAddressesTagsListRequest(params: protocol.IClientAddressesTagsListRequest, cb: Callback) {
    const clientAddressesTagsListRequest = new protocol.ClientAddressesTagsListRequest(params);
    const action = new protocol.ClientRequest({
      clientAddressesTagsListRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientPeerChangeTransactionAmountRequest(params: protocol.IClientPeerChangeTransactionAmountRequest, cb: Callback) {
    const clientPeerChangeTransactionAmountRequest = new protocol.ClientPeerChangeTransactionAmountRequest(params);
    const action = new protocol.ClientRequest({
      clientPeerChangeTransactionAmountRequest,
    });
    this.enqueueAction(action, cb);
  }

  hasChangesDashboardRequest(params: protocol.IHasChangesDashboardRequest, cb: Callback) {
    const hasChangesDashboardRequest = new protocol.HasChangesDashboardRequest(params);
    const action = new protocol.ClientRequest({
      hasChangesDashboardRequest,
    });
    this.enqueueAction(action, cb);
  }

  latestChangesDashboardRequest(params: protocol.ILatestChangesDashboardRequest, cb: Callback) {
    const latestChangesDashboardRequest = new protocol.LatestChangesDashboardRequest(params);
    const action = new protocol.ClientRequest({
      latestChangesDashboardRequest,
    });
    this.enqueueAction(action, cb);
  }

  checkTokenPayoutSystemBalanceRequest(params: protocol.ICheckTokenPayoutSystemBalanceRequest, cb: Callback) {
    const checkTokenPayoutSystemBalanceRequest = new protocol.CheckTokenPayoutSystemBalanceRequest(params);
    const action = new protocol.ClientRequest({
      checkTokenPayoutSystemBalanceRequest,
    });
    this.enqueueAction(action, cb);
  }

  cancelPendingPayoutRequestRequest(params: protocol.ICancelPendingPayoutRequestRequest, cb: Callback) {
    const cancelPendingPayoutRequestRequest = new protocol.CancelPendingPayoutRequestRequest(params);
    const action = new protocol.ClientRequest({
      cancelPendingPayoutRequestRequest,
    });
    this.enqueueAction(action, cb);
  }

  clientAddressesListRequest(
    params: protocol.IClientAddressesListRequest,
    cb: Callback,
    abortController?: AbortController,
  ) {
    const clientAddressesListRequest = new protocol.ClientAddressesListRequest(params);
    const action = new protocol.ClientRequest({
      clientAddressesListRequest,
    });
    this.enqueueAction(action, cb, true, false, abortController);
  }

  amlSingleCheckRequest(params: protocol.IAMLSingleCheckRequest, cb: Callback) {
    const amlSingleCheckRequest = new protocol.AMLSingleCheckRequest(params);
    const action = new protocol.ClientRequest({
      amlSingleCheckRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlCheckResultRequest(params: protocol.IAMLCheckResultRequest, cb: Callback) {
    const amlCheckResultRequest = new protocol.AMLCheckResultRequest(params);
    const action = new protocol.ClientRequest({
      amlCheckResultRequest,
    });
    this.enqueueAction(action, cb);
  }

  amlClientUnboundChecksListRequest(params: protocol.IAMLClientUnboundChecksListRequest, cb: Callback) {
    const amlClientUnboundChecksListRequest = new protocol.AMLClientUnboundChecksListRequest(params);
    const action = new protocol.ClientRequest({
      amlClientUnboundChecksListRequest,
    });
    this.enqueueAction(action, cb);
  }

  startBatch() {
    this.batchMode = true;
  }

  startBatchWithSkip() {
    this.batchMode = true;
    this.skipOnErrors = true;
  }

  commit() {
    this.batchMode = false;
    this.skipOnErrors = false;
    this.unsentActions = [];
  }

  private sendPacket(
    msg: protocol.ClientRequest,
    ignoreErrors?: boolean,
    retryOnAnyError?: boolean,
    callback?: Callback,
    abortController?: AbortController,
  ) {
    this.packetsInFlight += 1;

    console.debug('API request - ', msg);

    const buffer = protocol.ClientRequest.encode(msg).finish();
    const buffer2send = buffer.buffer.slice(buffer.byteOffset, buffer.byteOffset + buffer.byteLength);
    this.requester
      .post(apiPath, buffer2send, { signal: abortController?.signal })
      .then(resp => {
        this.packetsInFlight--;
        this.handleSuccessfulSendPacket(new Uint8Array(resp.data), callback);
      })
      .catch(err => {
        this.packetsInFlight -= 1;
        console.error(err);
        this.handleFailedSendPacket(err, msg, ignoreErrors, retryOnAnyError, abortController);
      });
  }

  private enqueueAction(
    action: protocol.ClientRequest,
    callback: Callback,
    ignoreErrors?: boolean,
    retryOnAnyError?: boolean,
    abortController?: AbortController,
  ) {
    if (this.apiIsDown === true && this.skipOnErrors === true) {
      return;
    }

    let trx = uuid();
    action.trx = trx;
    action.clientId = this.appState.clientId ?? '';
    action.authToken = this.appState.token ?? '';
    this.handlers[trx] = callback;

    if (this.batchMode === false) {
      this.sendPacket(action, ignoreErrors, retryOnAnyError, callback, abortController);
      this.unsentActions = [];
    }
  }

  private handleFailedSendPacket(
    err: AxiosError,
    actions: protocol.ClientRequest,
    ignoreErrors?: boolean,
    retryOnAnyError?: boolean,
    abortController?: AbortController,
  ) {
    this.apiIsDown = true;

    if (ignoreErrors) return;

    if (
      !retryOnAnyError &&
      (err.code === '500' ||
        err.code === '502' ||
        (err.request && (err.request.status === 500 || err.request.status === 502)))
    ) {
      //this.errorCallback(500, "Internal server error");
    } else {
      if (this.packetsInFlight === 0) {
        setTimeout(() => this.sendPacket(actions), ERROR_TIMEOUT, abortController);
      }
    }
  }

  private handleSuccessfulSendPacket(response: Uint8Array, callback?: Callback) {
    this.apiIsDown = false;

    const resp = protocol.ServerResponse.decode(response);
    console.debug('API response - ', resp);

    if (callback) callback(resp);
  }
}
