import Long from 'long';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { currencies, protocol } from '../api/proto';
import { ISortedColumn, ITotalFromApi, SORT_DIRECTION } from '../components/Table/Table';
import { AppState } from './AppState';
import { Operation } from './Operation';
import { AccessGroups } from './Roles';
import { convertMapWithCurrencyNamesAsKeysToWlCurrency, formatWlCurrency, getWlCurrencyCode } from './utils/Money';

const LIMIT_PAGINATION = 50;

type InvoicesMainTab = 'peers' | 'transactions';

export const FILTER_PERIODS_INVOICES = [
  'All',
  '1h',
  '2h',
  '3h',
  '6h',
  '12h',
  '24h',
  'Last week',
  'Current month',
  'Last month',
  'Last 3 months',
  'Last 6 months',
  'Last year',
  'Custom period',
] as const;
export type FilterPeriodInvoices = (typeof FILTER_PERIODS_INVOICES)[number];

export interface IPeerDetailsFormatted extends protocol.IClientPeerDetails {
  lastReceivedAmountsFormatted?: { [k: number]: string } | null;
  totalReceivedAmountsFormatted?: { [k: number]: string } | null;
  totalExpectedAmountsFormatted?: { [k: number]: string } | null;
  lastTxDateFormatted?: string;
}

export class PeerDetails extends protocol.ClientPeerDetails implements IPeerDetailsFormatted {
  private appState: AppState;

  @observable lastReceivedAmountsFormatted?: { [k: number]: string } | null;
  @observable totalReceivedAmountsFormatted?: { [k: number]: string } | null;
  @observable totalExpectedAmountsFormatted?: { [k: number]: string } | null;
  @observable lastTxDateFormatted?: string;

  constructor(appState: AppState, props: IPeerDetailsFormatted) {
    super();
    makeObservable(this);
    Object.assign(this, props);
    this.appState = appState;
  }

  @computed
  get lastReceivedAmount() {
    return this.lastReceivedAmountsFormatted &&
      this.lastReceivedAmountsFormatted[this.appState.selectedCurrencyForConvert]
      ? Number(this.lastReceivedAmountsFormatted[this.appState.selectedCurrencyForConvert] ?? 0)
      : undefined;
  }

  @computed
  get lastReceivedAmountFormatted() {
    return this.lastReceivedAmount
      ? formatWlCurrency(this.lastReceivedAmount, this.appState.selectedCurrencyForConvert)
      : '-';
  }

  @computed
  get totalReceivedAmount() {
    return this.totalReceivedAmountsFormatted &&
      this.totalReceivedAmountsFormatted[this.appState.selectedCurrencyForConvert]
      ? Number(this.totalReceivedAmountsFormatted[this.appState.selectedCurrencyForConvert] ?? 0)
      : undefined;
  }

  @computed
  get totalReceivedAmountFormatted() {
    return this.totalReceivedAmount
      ? formatWlCurrency(this.totalReceivedAmount, this.appState.selectedCurrencyForConvert)
      : '-';
  }

  @computed
  get totalExpectedAmount() {
    return this.totalExpectedAmountsFormatted &&
      this.totalExpectedAmountsFormatted[this.appState.selectedCurrencyForConvert]
      ? Number(this.totalExpectedAmountsFormatted[this.appState.selectedCurrencyForConvert] ?? 0)
      : undefined;
  }

  @computed
  get totalExpectedAmountFormatted() {
    return this.totalExpectedAmount
      ? formatWlCurrency(this.totalExpectedAmount, this.appState.selectedCurrencyForConvert)
      : '-';
  }

  @computed
  get lastReceivedAmountUsdFormatted() {
    return this.lastReceivedAmountsFormatted && this.lastReceivedAmountsFormatted[currencies.WLCurrency.WLC_USD]
      ? formatWlCurrency(
          Number(this.lastReceivedAmountsFormatted[currencies.WLCurrency.WLC_USD] ?? 0),
          currencies.WLCurrency.WLC_USD,
          true,
        )
      : undefined;
  }

  @computed
  get totalReceivedAmountUsdFormatted() {
    return this.totalReceivedAmountsFormatted && this.totalReceivedAmountsFormatted[currencies.WLCurrency.WLC_USD]
      ? formatWlCurrency(
          Number(this.totalReceivedAmountsFormatted[currencies.WLCurrency.WLC_USD] ?? 0),
          currencies.WLCurrency.WLC_USD,
          true,
        )
      : undefined;
  }

  @computed
  get totalExpectedAmountUsdFormatted() {
    return this.totalExpectedAmountsFormatted && this.totalExpectedAmountsFormatted[currencies.WLCurrency.WLC_USD]
      ? formatWlCurrency(
          Number(this.totalExpectedAmountsFormatted[currencies.WLCurrency.WLC_USD] ?? 0),
          currencies.WLCurrency.WLC_USD,
          true,
        )
      : undefined;
  }

  @computed
  get additionalCurrenciesTotalAmountsWithoutUsd() {
    const additionalAmounts: { expected: string; received: string; currency: string }[] = [];
    if (this.totalExpectedAmountsFormatted) {
      Object.keys(this.totalExpectedAmountsFormatted)?.forEach(currency => {
        if (Number(currency) !== currencies.WLCurrency.WLC_USD) {
          additionalAmounts.push({
            expected: formatWlCurrency(
              Number(this.totalExpectedAmountsFormatted![Number(currency)]),
              Number(currency),
              true,
            ),
            received:
              this.totalReceivedAmountsFormatted && this.totalReceivedAmountsFormatted[Number(currency)]
                ? formatWlCurrency(
                    Number(this.totalReceivedAmountsFormatted![Number(currency)]),
                    Number(currency),
                    true,
                  )
                : '0',
            currency: getWlCurrencyCode(Number(currency)),
          });
        }
      });
    }
    return additionalAmounts;
  }

  @computed
  get additionalCurrenciesExpectedAmountsWithoutUsd() {
    const additionalAmounts: string[] = [];
    if (this.totalExpectedAmountsFormatted) {
      Object.keys(this.totalExpectedAmountsFormatted)?.forEach(currency => {
        if (Number(currency) !== currencies.WLCurrency.WLC_USD) {
          additionalAmounts.push(
            formatWlCurrency(Number(this.totalExpectedAmountsFormatted![Number(currency)]), Number(currency)),
          );
        }
      });
    }
    return additionalAmounts;
  }

  @computed
  get additionalCurrenciesReceivedAmountsWithoutUsd() {
    const additionalAmounts: string[] = [];
    if (this.totalReceivedAmountsFormatted) {
      Object.keys(this.totalReceivedAmountsFormatted)?.forEach(currency => {
        if (Number(currency) !== currencies.WLCurrency.WLC_USD) {
          additionalAmounts.push(
            formatWlCurrency(Number(this.totalReceivedAmountsFormatted![Number(currency)]), Number(currency)),
          );
        }
      });
    }
    return additionalAmounts;
  }

  @computed
  get additionalCurrenciesLastReceivedAmountsWithoutUsd() {
    const additionalAmounts: string[] = [];
    if (this.lastReceivedAmountsFormatted) {
      Object.keys(this.lastReceivedAmountsFormatted)?.forEach(currency => {
        if (Number(currency) !== currencies.WLCurrency.WLC_USD) {
          additionalAmounts.push(
            formatWlCurrency(Number(this.lastReceivedAmountsFormatted![Number(currency)]), Number(currency)),
          );
        }
      });
    }
    return additionalAmounts;
  }
}

export class InvoicesStore {
  private appState: AppState;

  @observable mainTab: InvoicesMainTab = 'peers';

  @observable peersList: IPeerDetailsFormatted[] = [];
  @observable peersListIsLoading: boolean = false;
  @observable transactionsList?: Operation[];
  @observable transactionsIsLoading: boolean = false;

  @observable peerStats?: protocol.IClientPeersStatsResponse | null;
  @observable peerStatsLoading: boolean = false;

  @observable transactionsStatsRaw?: protocol.IClientPeerTransactionsStatsResponse | null;
  @observable transactionsStatsLoading: boolean = false;

  @observable page: number = 0;
  @observable totalPages: number = 0;
  @observable sortedColumnPeer?: ISortedColumn = {
    name: 'name',
    direction: SORT_DIRECTION.DESC,
  };
  @observable sortedColumnTransactions?: ISortedColumn = {
    name: 'updatedAtNumber',
    direction: SORT_DIRECTION.DESC,
  };
  @observable filterPeriod: FilterPeriodInvoices = 'Current month';
  @observable customDateRange?: [Date | null, Date | null];
  // @observable periodTransaction?: FilterPeriod = 'All';
  @observable searchValue: string = '';

  @observable transactionsTableTotals?: {
    totalIncomes: { [k: number]: string } | null;
  };

  @observable peersTableTotals?: {
    totalExpectedAmountUsd?: string | null;
    totalReceivedAmountUsd?: string | null;
  };

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @computed
  get isReadOnly() {
    return (
      !!this.appState.isReadOnlyAllAccess || this.appState.userAccessGroups?.includes(AccessGroups.INVOICES_READ_ONLY)
    );
  }

  @computed
  get transactionsStats() {
    const incomesFiat = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.transactionsStatsRaw?.totalIncomes);
    const currentCurrencyIncomes = formatWlCurrency(
      Number(incomesFiat[this.appState.selectedCurrencyForConvert] ?? 0),
      this.appState.selectedCurrencyForConvert,
    );
    return {
      totalCount: this.transactionsStatsRaw?.totalCount,
      totalIncomeFiat: currentCurrencyIncomes,
    };
  }

  @action
  onChangeMainTab = (tab: InvoicesMainTab) => {
    if (tab !== this.mainTab) {
      this.resetPaging();
      if (tab === 'peers') {
        this.loadPeers();
      } else {
        this.loadTransactions();
      }
    }
    this.mainTab = tab;
  };

  @action
  changeSearchValue = (val: string) => {
    this.searchValue = val;
    if (val === '') {
      if (this.mainTab === 'peers') {
        this.loadPeers();
      } else {
        this.loadTransactions();
      }
    }
  };

  @action
  changePage = (page: number) => {
    this.page = page;
    if (this.mainTab === 'peers') {
      this.loadPeers();
    } else {
      this.loadTransactions();
    }
  };

  @computed
  get fromToNumPeriodFilter() {
    if (this.filterPeriod === 'All') {
      return { from: undefined, to: undefined };
    }
    let from = 0;
    let to = 0;
    if (this.filterPeriod === '1h') {
      from = moment().subtract('1', 'hour').unix();
    } else if (this.filterPeriod === '2h') {
      from = moment().subtract('2', 'hours').unix();
    } else if (this.filterPeriod === '3h') {
      from = moment().subtract('3', 'hours').unix();
    } else if (this.filterPeriod === '6h') {
      from = moment().subtract('6', 'hours').unix();
    } else if (this.filterPeriod === '12h') {
      from = moment().subtract('12', 'hours').unix();
    } else if (this.filterPeriod === '24h') {
      from = moment().subtract('1', 'day').unix();
    } else if (this.filterPeriod === 'Last week') {
      from = moment().subtract('1', 'week').unix();
    } else if (this.filterPeriod === 'Current month') {
      from = moment().startOf('month').unix();
    } else if (this.filterPeriod === 'Last month') {
      from = moment().subtract('1', 'month').unix();
    } else if (this.filterPeriod === 'Last 3 months') {
      from = moment().subtract('3', 'months').unix();
    } else if (this.filterPeriod === 'Last 6 months') {
      from = moment().subtract('6', 'months').unix();
    } else if (this.filterPeriod === 'Last year') {
      from = moment().subtract('1', 'year').unix();
    }

    return { from, to };
  }

  @computed
  get peersSortBy() {
    if (this.sortedColumnPeer?.name) {
      switch (this.sortedColumnPeer.name) {
        case 'name':
          return protocol.SortBy.NAME_SORT_BY;
        case 'status':
          return protocol.SortBy.STATUS_SORT_BY;
        case 'addressesCount':
          return protocol.SortBy.ENTITIES_COUNT_SORT_BY;
        case 'lastTxDate':
          return protocol.SortBy.DATE_SORT_BY;
        case 'totalReceivedAmount':
          return protocol.SortBy.AMOUNT_SORT_BY;
        case 'lastReceivedAmount':
          return protocol.SortBy.LAST_AMOUNT_SORT_BY;
      }
    }
    return undefined;
  }

  @action
  onSortColumnPeersClick = (name: string) => {
    if (this.sortedColumnPeer?.name && this.sortedColumnPeer.name === name) {
      if (this.sortedColumnPeer.direction === SORT_DIRECTION.ASC) {
        this.sortedColumnPeer = undefined;
      } else if (this.sortedColumnPeer.direction === SORT_DIRECTION.DESC) {
        this.sortedColumnPeer.direction = SORT_DIRECTION.ASC;
      } else {
        this.sortedColumnPeer.direction = SORT_DIRECTION.DESC;
      }
    } else {
      this.sortedColumnPeer = {
        name,
        direction: SORT_DIRECTION.DESC,
      };
    }
    this.page = 0;
    this.loadPeers();
  };

  @computed
  get transactionsSortBy() {
    if (this.sortedColumnTransactions?.name) {
      switch (this.sortedColumnTransactions.name) {
        case 'updatedAtNumber':
          return protocol.SortBy.DATE_SORT_BY;
        case 'formattedAmount':
          return protocol.SortBy.AMOUNT_SORT_BY;
        case 'formattedCurrency':
          return protocol.SortBy.CURRENCY_SORT_BY;
        case 'id':
          return protocol.SortBy.ID_SORT_BY;
        case 'tagsList':
          return protocol.SortBy.TAGS_SORT_BY;
      }
    }
    return undefined;
  }

  @action
  onSortColumnTransactionsClick = (name: string) => {
    if (this.sortedColumnTransactions?.name && this.sortedColumnTransactions.name === name) {
      if (this.sortedColumnTransactions.direction === SORT_DIRECTION.ASC) {
        this.sortedColumnTransactions = undefined;
      } else if (this.sortedColumnTransactions.direction === SORT_DIRECTION.DESC) {
        this.sortedColumnTransactions.direction = SORT_DIRECTION.ASC;
      } else {
        this.sortedColumnTransactions.direction = SORT_DIRECTION.DESC;
      }
    } else {
      this.sortedColumnTransactions = {
        name,
        direction: SORT_DIRECTION.DESC,
      };
    }
    this.page = 0;
    this.loadTransactions();
  };

  @action
  onChangePeriod = (period: string) => {
    this.customDateRange = undefined;
    if (this.filterPeriod === period) {
      this.filterPeriod = 'Current month';
    } else {
      this.filterPeriod = period as FilterPeriodInvoices;
    }
    this.page = 0;
    if (this.mainTab === 'peers') {
      this.loadPeers();
    } else {
      this.loadTransactions();
    }
  };

  @action
  setCustomDateRange = (range: Date | [Date | null, Date | null] | null) => {
    this.customDateRange = (range as [Date | null, Date | null]) || undefined;
    this.filterPeriod = 'Custom period';
    this.page = 0;
    if (this.mainTab === 'peers') {
      this.loadPeers();
    } else {
      this.loadTransactions();
    }
  };

  @computed
  get filterPeriodForApi() {
    if (this.filterPeriod === 'Custom period' && this.customDateRange?.length) {
      const [start, end] = this.customDateRange;
      if (start && end) {
        return {
          from: start.getTime() / 1000,
          to: (end.getTime() + 86400000) / 1000,
        };
      }
    } else {
      return this.fromToNumPeriodFilter;
    }
    return undefined;
  }

  @action
  init() {
    this.loadPeers();
  }

  @computed
  get tablePeersTotals(): ITotalFromApi[] {
    return [
      {
        key: 'totalExpectedAmountFormatted',
        value: formatWlCurrency(
          Number(this.peersTableTotals?.totalExpectedAmountUsd ?? 0),
          currencies.WLCurrency.WLC_USD,
        ),
      },
      {
        key: 'totalReceivedAmountFormatted',
        value: formatWlCurrency(
          Number(this.peersTableTotals?.totalReceivedAmountUsd ?? 0),
          currencies.WLCurrency.WLC_USD,
        ),
      },
    ];
  }

  @action
  loadPeers = () => {
    this.loadPeersStats();
    this.peersListIsLoading = true;
    this.appState.api.clientPeersListRequest(
      {
        limit: Long.fromNumber(LIMIT_PAGINATION),
        offset: Long.fromNumber(this.page * LIMIT_PAGINATION),
        filterName: this.searchValue ?? undefined,
        createdAtFrom: this.filterPeriodForApi?.from ? Long.fromNumber(this.filterPeriodForApi?.from) : undefined,
        createdAtTo: this.filterPeriodForApi?.to ? Long.fromNumber(this.filterPeriodForApi?.to) : undefined,
        sortBy: this.peersSortBy,
        sortDesc:
          this.sortedColumnPeer?.direction !== undefined
            ? this.sortedColumnPeer?.direction === SORT_DIRECTION.DESC
            : undefined,
      },
      this.onPeersResponse,
    );
  };

  @action
  onPeersResponse = (msg: protocol.IServerResponse) => {
    this.peersListIsLoading = false;
    if (msg.clientPeersListResponse?.peers?.length) {
      this.peersList = msg.clientPeersListResponse.peers.map(peer => {
        return new PeerDetails(this.appState, {
          ...peer,
          totalExpectedAmountsFormatted: convertMapWithCurrencyNamesAsKeysToWlCurrency(peer.totalExpectedAmounts),
          totalReceivedAmountsFormatted: convertMapWithCurrencyNamesAsKeysToWlCurrency(peer.totalReceivedAmounts),
          lastReceivedAmountsFormatted: convertMapWithCurrencyNamesAsKeysToWlCurrency(peer.lastReceivedAmounts),
          lastTxDateFormatted:
            peer.lastTxDate && peer.lastTxDate?.notEquals(0)
              ? new Date(peer.lastTxDate?.multiply(1000).toNumber() ?? '').toLocaleString()
              : '-',
        });
      });
      if (msg.clientPeersListResponse?.totalPages) {
        this.totalPages = msg.clientPeersListResponse?.totalPages?.toNumber();
      }
    } else {
      this.peersList = [];
      this.totalPages = 0;
    }
    this.peersTableTotals = {
      totalExpectedAmountUsd: msg?.clientPeersListResponse?.totalExpectedAmountUsd,
      totalReceivedAmountUsd: msg?.clientPeersListResponse?.totalReceivedAmountUsd,
    };
  };

  @action
  loadPeersStats = () => {
    this.peerStatsLoading = true;
    this.appState.api.clientPeersStatsRequest(
      {
        createdAtFrom: this.filterPeriodForApi?.from ? Long.fromNumber(this.filterPeriodForApi?.from) : undefined,
        createdAtTo: this.filterPeriodForApi?.to ? Long.fromNumber(this.filterPeriodForApi?.to) : undefined,
      },
      (msg: protocol.IServerResponse) => {
        this.peerStatsLoading = false;
        this.peerStats = {
          totalPeersCount: msg?.clientPeersStatsResponse?.totalPeersCount ?? Long.fromNumber(0),
          totalExpectedAmountUsd: formatWlCurrency(
            Number(msg?.clientPeersStatsResponse?.totalExpectedAmountUsd ?? '0'),
            currencies.WLCurrency.WLC_USD,
          ),
          totalReceivedAmountUsd: formatWlCurrency(
            Number(msg?.clientPeersStatsResponse?.totalReceivedAmountUsd ?? '0'),
            currencies.WLCurrency.WLC_USD,
          ),
        };
      },
    );
  };

  @action
  loadTransactionsStats = () => {
    this.transactionsStatsLoading = true;
    this.appState.api.clientPeerTransactionsStatsRequest(
      {
        updatedAtFrom: this.fromToNumPeriodFilter?.from ? Long.fromNumber(this.fromToNumPeriodFilter?.from) : undefined,
        updatedAtTo: this.fromToNumPeriodFilter?.to ? Long.fromNumber(this.fromToNumPeriodFilter?.to) : undefined,
      },
      (msg: protocol.IServerResponse) => {
        this.transactionsStatsLoading = false;
        this.transactionsStatsRaw = {
          totalCount: msg?.clientPeerTransactionsStatsResponse?.totalCount ?? Long.fromNumber(0),
          totalIncomes: msg?.clientPeerTransactionsStatsResponse?.totalIncomes,
        };
      },
    );
  };

  @computed
  get tableTransactionsTotals(): ITotalFromApi[] {
    const totalIncomes =
      this.transactionsTableTotals?.totalIncomes &&
      this.transactionsTableTotals?.totalIncomes[this.appState.selectedCurrencyForConvert]
        ? this.transactionsTableTotals?.totalIncomes[this.appState.selectedCurrencyForConvert]
        : 0;

    return [
      {
        key: 'formattedAmount',
        value: formatWlCurrency(Number(totalIncomes), this.appState.selectedCurrencyForConvert),
      },
    ];
  }

  @action
  loadTransactions = () => {
    this.loadTransactionsStats();
    this.transactionsIsLoading = true;
    this.appState.api.clientPeerTransactionsRequest(
      {
        limit: Long.fromNumber(LIMIT_PAGINATION),
        offset: Long.fromNumber(this.page * LIMIT_PAGINATION),
        sortBy: this.transactionsSortBy,
        updatedAtFrom: this.fromToNumPeriodFilter?.from ? Long.fromNumber(this.fromToNumPeriodFilter?.from) : undefined,
        updatedAtTo: this.fromToNumPeriodFilter?.to ? Long.fromNumber(this.fromToNumPeriodFilter?.to) : undefined,
        sortDesc:
          this.sortedColumnTransactions?.direction !== undefined
            ? this.sortedColumnTransactions?.direction === SORT_DIRECTION.DESC
            : undefined,
        peerName: this.searchValue,
      },
      this.onTransactionsResponse,
    );
  };

  @action
  onTransactionsResponse = (msg: protocol.IServerResponse) => {
    this.transactionsIsLoading = false;
    let transactions: Operation[] = [];
    if (msg?.clientPeerTransactionsResponse?.transactions?.length) {
      transactions = msg?.clientPeerTransactionsResponse?.transactions.map(
        transaction => new Operation(this.appState, transaction),
      );
    }

    this.transactionsTableTotals = {
      totalIncomes: convertMapWithCurrencyNamesAsKeysToWlCurrency(msg?.clientPeerTransactionsResponse?.totalIncomes),
    };
    if (transactions?.length) {
      this.fetchTags(transactions);
    } else {
      this.transactionsIsLoading = false;
      this.transactionsList = [];
    }
    this.totalPages = msg?.clientPeerTransactionsResponse?.totalPages?.toNumber() ?? 0;
  };

  @action.bound
  fetchTags(operations: Operation[]) {
    this.appState.api.tagsRequest(
      {
        filterTxIds: operations?.filter(o => !!o.id).map(o => o.id),
        filterClientIds: this.appState.clientId ? [this.appState.clientId] : [],
      },
      (msg: protocol.IServerResponse) => this.onTagsResponse(msg, operations),
    );
  }

  @action.bound
  onTagsResponse(msg: protocol.IServerResponse, operations: Operation[]) {
    if (msg?.listTagsResponse?.tags?.length) {
      const tags = msg.listTagsResponse.tags;
      this.transactionsList = operations.map(operation => {
        if (operation.id) {
          const operationTags = tags
            .filter(tag => tag.txIds?.includes(operation.id) && !!tag.name)
            .map(tag => tag.name!);
          operation.tags = operationTags;
        }
        return operation;
      });
      this.transactionsIsLoading = false;
    } else {
      this.transactionsIsLoading = false;
      this.transactionsList = operations;
    }
  }

  @action
  resetAll = () => {
    this.resetPaging();

    this.peersList = [];
    this.transactionsList = undefined;
    this.peersListIsLoading = false;
    this.transactionsIsLoading = false;
    this.transactionsTableTotals = undefined;
    this.peersTableTotals = undefined;
  };

  @action
  resetPaging = () => {
    this.page = 0;
    this.totalPages = 0;
    this.filterPeriod = 'Current month';
    this.searchValue = '';
    this.customDateRange = undefined;
    this.sortedColumnPeer = {
      name: 'name',
      direction: SORT_DIRECTION.DESC,
    };
    this.sortedColumnTransactions = {
      name: 'updatedAtNumber',
      direction: SORT_DIRECTION.DESC,
    };
  };
}
