import './Icon.scss';

export interface IconProps {
  iconName?: string;
  heightInPx?: number;
  widthInPx?: number;
  customPath?: string;
}

export const Icon = (props: IconProps) => {
  if (props.iconName || props.customPath) {
    const iconUrl = props.customPath ? props.customPath : `/images/icons/${props.iconName}-icon.svg`;
    let styles: { [key: string]: string | number } = { backgroundImage: `url(${iconUrl})` };
    if (props.heightInPx) {
      styles['minHeight'] = props.heightInPx;
      styles['height'] = props.heightInPx;
    }
    if (props.widthInPx) {
      styles['minWidth'] = props.widthInPx;
      styles['width'] = props.widthInPx;
    }
    return <div style={styles} className="icon"></div>;
  }
  return null;
};
