import { AgnosticRouteObject } from '@remix-run/router';
import { ISelectOption } from '../components/controls/Select/Select';
import { ProtectedRoutes } from './Routes';
import { snakeCaseToString } from './utils/format';

export enum AccessGroups {
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  ACCOUNTANT = 'Accountant',
  ALL = 'All',
  ALL_EXCEPT_WITHRAWALS = 'All_except_withrawals',
  INVOICES_ONLY = 'Invoices_only',
  AML_ONLY = 'Aml_only',
  FULL_FINANCIAL_ACCESS = 'Full_financial_access',
  READ_ONLY_FINANCIAL_ACCESS = 'Read-only_financial_access',
  LOGS_VIEW = 'Logs_view',
  INVOICES_READ_ONLY = 'Invoices_read-only',
  USERS_MANAGEMENT = 'Users_management',
}

export const accessGroupsOptions = (): ISelectOption[] => {
  return Object.values(AccessGroups).map(_ => ({
    label: snakeCaseToString(_),
    value: _,
  }));
};

export const AllRoutes: ProtectedRoutes[] = Object.values(ProtectedRoutes);

export const AllExceptUsersRoutes: ProtectedRoutes[] = Object.values(ProtectedRoutes).filter(
  _ => _ !== ProtectedRoutes.USERS,
);

const InvoicesOnlyRoutes: ProtectedRoutes[] = [
  ProtectedRoutes.INVOICES_MAIN_PAGE,
  ProtectedRoutes.CREATE_INVOICE_PAGE,
  ProtectedRoutes.INVOICES_PEER_PAGE,
];
const InvoicesReadOnlyRoutes: ProtectedRoutes[] = [
  ProtectedRoutes.INVOICES_MAIN_PAGE,
  ProtectedRoutes.INVOICES_PEER_PAGE,
];
const AmlOnlyRoutes: ProtectedRoutes[] = [
  ProtectedRoutes.AML_ADDRESS_PAGE,
  ProtectedRoutes.AML_MAIN_PAGE,
  ProtectedRoutes.AML_REPORT_PAGE,
];
export const AllExceptWithdrawalsRoutes: ProtectedRoutes[] = AllExceptUsersRoutes.filter(
  _ => _ !== ProtectedRoutes.WITHDRWAL && _ !== ProtectedRoutes.WITHDRWAL_RESULT,
);

export const AllReadOnlyRoutes: ProtectedRoutes[] = AllExceptUsersRoutes.filter(
  _ => ![ProtectedRoutes.WITHDRWAL_RESULT, ProtectedRoutes.WITHDRWAL, ProtectedRoutes.CREATE_INVOICE_PAGE].includes(_),
);

export function getAvailableRoutes(accessGroups?: string[] | null, withoutUsers?: boolean): AgnosticRouteObject[] {
  if (accessGroups?.length) {
    const routes = [];
    if (accessGroups.includes(AccessGroups.INVOICES_ONLY)) {
      routes.push(...InvoicesOnlyRoutes);
    }
    if (accessGroups.includes(AccessGroups.INVOICES_READ_ONLY)) {
      routes.push(...InvoicesReadOnlyRoutes);
    }
    if (accessGroups.includes(AccessGroups.AML_ONLY)) {
      routes.push(...AmlOnlyRoutes);
    }
    if (accessGroups.includes(AccessGroups.ALL_EXCEPT_WITHRAWALS)) {
      routes.push(...AllExceptWithdrawalsRoutes);
    }
    if (accessGroups.includes(AccessGroups.READ_ONLY_FINANCIAL_ACCESS)) {
      routes.push(...AllReadOnlyRoutes);
    }
    if (accessGroups.includes(AccessGroups.LOGS_VIEW)) {
      routes.push(ProtectedRoutes.ACTIVITY);
    }
    if (accessGroups.includes(AccessGroups.USERS_MANAGEMENT)) {
      routes.push(ProtectedRoutes.USERS);
    }
    if (
      accessGroups.includes(AccessGroups.ACCOUNTANT) ||
      accessGroups.includes(AccessGroups.MANAGER) ||
      accessGroups.includes(AccessGroups.FULL_FINANCIAL_ACCESS)
    ) {
      routes.push(...AllExceptUsersRoutes);
    }
    if (accessGroups.includes(AccessGroups.ALL) || accessGroups.includes(AccessGroups.ADMIN)) {
      routes.push(...AllRoutes);
    }
    return getRoutesForReactRouter(routes);
  }
  if (withoutUsers) {
    return getRoutesForReactRouter(AllExceptUsersRoutes);
  }
  return getRoutesForReactRouter(AllRoutes);
}

export function getRoutesForReactRouter(routes: ProtectedRoutes[]): AgnosticRouteObject[] {
  return Object.values(routes).map(_ => ({ path: _ }));
}

export function getMainRouteByRole(accessGroups?: string[] | null) {
  if (accessGroups?.length) {
    if (
      accessGroups.includes(AccessGroups.ALL) ||
      accessGroups.includes(AccessGroups.ADMIN) ||
      accessGroups.includes(AccessGroups.ACCOUNTANT) ||
      accessGroups.includes(AccessGroups.MANAGER) ||
      accessGroups.includes(AccessGroups.ALL_EXCEPT_WITHRAWALS) ||
      accessGroups.includes(AccessGroups.FULL_FINANCIAL_ACCESS) ||
      accessGroups.includes(AccessGroups.READ_ONLY_FINANCIAL_ACCESS)
    ) {
      return ProtectedRoutes.MAIN_PAGE;
    }
    if (accessGroups.includes(AccessGroups.INVOICES_ONLY) || accessGroups.includes(AccessGroups.INVOICES_READ_ONLY)) {
      return ProtectedRoutes.INVOICES_MAIN_PAGE;
    }
    if (accessGroups.includes(AccessGroups.AML_ONLY)) {
      return ProtectedRoutes.AML_MAIN_PAGE;
    }
    if (accessGroups.includes(AccessGroups.LOGS_VIEW)) {
      return ProtectedRoutes.ACTIVITY;
    }
    if (accessGroups.includes(AccessGroups.USERS_MANAGEMENT)) {
      return ProtectedRoutes.USERS;
    }
  }
  return ProtectedRoutes.MAIN_PAGE;
}
