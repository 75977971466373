import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Button, ButtonSize, ButtonType } from '../../components/controls/Button/Button';
import { Input } from '../../components/controls/Input/Input';
import { useMountEffect } from '../../hooks/useMountEffect';
import { useState } from '../../state/AppState';
import './LoginPage.scss';

export const LoginPage = observer(() => {
  const state = useState();
  const params = useParams();
  const { loginStore } = state;
  useMountEffect(() => {
    if (params.clientId && !state.clientId) {
      state.setClientId(params.clientId);
      state.checkSession(undefined, undefined, undefined, true);
    }
  });
  return (
    <div className="LoginPage">
      <div className="LoginPage__content">
        <div className="LoginPage__title"></div>
        <Input label="Login" value={loginStore.login} onEnter={loginStore.logIn} onChange={loginStore.changeLogin} />
        <Input
          label="Password"
          type="password"
          value={loginStore.password}
          onEnter={loginStore.logIn}
          onChange={loginStore.changePassword}
        />
        {loginStore.error ? <div className="error">{loginStore.error}</div> : null}
        <Button
          label="Sign in"
          onClick={loginStore.logIn}
          disabled={!loginStore.isValid}
          isLoading={loginStore.isLoading}
          type={ButtonType.PRIMARY}
          size={ButtonSize.BIG}
        />
      </div>
    </div>
  );
});
