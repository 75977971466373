import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { protocol } from '../../api/proto';
import { CopyButton } from '../../components/controls/CopyButton/CopyButton';
import { Input } from '../../components/controls/Input/Input';
import { Icon } from '../../components/Icon/Icon';
import Table from '../../components/Table/Table';
import { useMountEffect } from '../../hooks/useMountEffect';
import { useState } from '../../state/AppState';
import './ExplorerPage.scss';

export const ExplorerPage = observer(() => {
  const state = useState();
  const { explorerState } = state;
  const { searchValue, changeSearchValue, onStartSearch, nothingFound, resetPage, operationsLength } = explorerState;
  const params = useParams();

  useMountEffect(() => {
    if (params.clientId && !state.clientId) {
      state.setClientId(params.clientId);
    }
  });

  React.useEffect(() => {
    return () => {
      resetPage();
    };
    // eslint-disable-next-line
  }, []);

  const tableColumns = [
    {
      title: 'ID',
      key: 'id',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => {
        return (
          <td className="id-cell">
            <div className="container">
              {row.direction ? (
                <Icon
                  widthInPx={16}
                  heightInPx={16}
                  iconName={row.direction === protocol.TxDirection.IN_TX_DIR ? 'refill' : 'withdraw'}
                />
              ) : null}
              {row.id ? row.id : ''}
            </div>
          </td>
        );
      },
    },
    {
      title: 'Date and time',
      key: 'formattedDate',
      sortKey: 'updatedAtNumber',
      sortEnabled: true,
    },
    {
      title: 'Amount',
      key: 'formattedAmount',
      additionalFields: ['formattedConvertedAmounts'],
      sortEnabled: true,
    },
    {
      title: 'Currency',
      key: 'formattedCurrency',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => {
        return (
          <td className="column-cell">
            <div className="currency">
              {row['formattedCurrency']}{' '}
              <Icon
                iconName={row.currencyIconName}
                heightInPx={16}
                widthInPx={
                  Number(row.currency) === protocol.Currency.ERC20_USDT_CUR ||
                  Number(row.currency) === protocol.Currency.TRC20_USDT_CUR
                    ? 32
                    : 16
                }
              />
            </div>
            <div>({row.network})</div>
          </td>
        );
      },
    },
    {
      title: 'Fee',
      key: 'formattedFee',
      additionalFields: ['formattedConvertedFee'],
      sortEnabled: false,
    },
    {
      title: 'Tags',
      key: 'tagsList',
      sortEnabled: true,
    },
    {
      title: 'TRX ID',
      key: 'hash',
      sortEnabled: false,
      renderCustomCellComponent: (row: any) => {
        return (
          <td className="trx-cell">
            <div className="container">
              {row.shortHash}
              <CopyButton notificationText="TRX ID has been copied" sizeInPx={14} contentForCopy={row.hash} />
            </div>
          </td>
        );
      },
    },
  ];

  return (
    <div className="ExplorerPage">
      <div className="title">Explore</div>
      <div className="search-input-container">
        <Input
          label="Search by address, tag or transaction ID"
          value={searchValue}
          onChange={changeSearchValue}
          onEnter={onStartSearch}
          leftIconName="search"
          actionButtonIcon={searchValue?.length ? 'close' : undefined}
          actionButtonOnClick={() => changeSearchValue('')}
        />
        {!!operationsLength ? <div className="founded-operations">{operationsLength} records found</div> : null}
        {!!nothingFound ? <div className="nothing-found">Nothing found</div> : null}
      </div>
      <div className="ExplorerPage__operations">
        {explorerState.operations?.length ? (
          <Table
            columns={tableColumns}
            hasChildRows
            periodsFilterColumn={'updatedAtNumber'}
            data={explorerState.operations}
          />
        ) : null}
      </div>
    </div>
  );
});
