import { action, computed, makeObservable, observable } from 'mobx';
import { currencies, protocol } from '../api/proto';
import { AppState } from './AppState';
import { ProtectedRoutes } from './Routes';
import {
  chainToCurrency,
  convertMapWithCurrencyNamesAsKeysToWlCurrency,
  formatCurrency,
  formatWlCurrency,
  getCurrencyCode,
  getCurrencyIcon,
  getCurrencyTitle,
  getShortAddress,
} from './utils/Money';

export interface IOperation extends protocol.ITxRecord {
  tags?: string[];
  childs?: Operation[];
  sumAmount?: string;
  sumFee?: string;
  amountFiatSum?: { [k: number]: number } | null;
  feeFiatSum?: { [k: number]: number } | null;
}

export interface IAddressWithTags {
  address: string;
  tags?: string[];
}

export class Operation extends protocol.TxRecord implements IOperation {
  constructor(private appState: AppState, props: protocol.TxRecord | IOperation, childs?: Operation[]) {
    super(props);
    Object.assign(this, props);
    makeObservable(this);
    if (childs) {
      this.childs = childs;
    }
  }

  @observable childs?: Operation[];
  @observable tags?: string[];
  @observable sumAmount?: string | undefined;
  @observable sumFee?: string | undefined;
  @observable amountFiatSum?: { [k: number]: number } | null;
  @observable feeFiatSum?: { [k: number]: number } | null;

  @observable childsIsOpen: boolean = false;

  @computed
  get isNew(): boolean {
    if (
      this.appState.currentProtectedRoute === ProtectedRoutes.MAIN_PAGE &&
      this.appState.mainPageInfo.transactionsUpdates?.length
    ) {
      return !!this.appState.mainPageInfo.transactionsUpdates.find(tx => tx.id === this.id);
    }
    return false;
  }

  @computed
  get network(): string {
    return protocol.Chain[this.chain ?? protocol.Chain.UNK_CHAIN].replace('_CHAIN', '');
  }

  @computed
  get tagsList(): string {
    return this.tags?.length ? this.tags.join(', ') : '';
  }

  @computed
  get shortHash(): string {
    return this.hash ? getShortAddress(this.hash) : '';
  }

  @computed
  get formattedAmount(): string {
    if (this.childs?.length && !this.childsIsOpen) {
      return formatCurrency(
        Number(this.sumAmount),
        this.currency,
        !(this.currency === protocol.Currency.TRC20_USDT_CUR || this.currency === protocol.Currency.ERC20_USDT_CUR),
      );
    }
    return formatCurrency(
      Number(this.amount),
      this.currency,
      !(this.currency === protocol.Currency.TRC20_USDT_CUR || this.currency === protocol.Currency.ERC20_USDT_CUR),
    );
  }

  @computed
  get formattedFee(): string {
    if (this.currency === protocol.Currency.ETH_CUR && this.childs?.length && !this.childsIsOpen) {
      return formatCurrency(Number(this.sumFee), chainToCurrency(this.chain));
    }
    return this.meta?.fee ? formatCurrency(Number(this.meta?.fee), chainToCurrency(this.chain)) : '';
  }

  @computed
  get formattedAmountForSort(): number {
    if (this.childs?.length) {
      return Number(formatCurrency(Number(this.sumAmount), this.currency, true));
    }
    return Number(formatCurrency(Number(this.amount), this.currency, true));
  }

  @computed
  get formattedCurrency(): string {
    return getCurrencyTitle(this.currency);
  }

  @computed
  get convertedAmount(): number | undefined {
    if (this.meta?.amounts) {
      const amountsFiat = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.meta.amounts);
      return amountsFiat[this.appState.selectedCurrencyForConvert]
        ? Number(amountsFiat[this.appState.selectedCurrencyForConvert] ?? 0)
        : undefined;
    }

    return undefined;
  }

  @computed
  get convertedFee(): number | undefined {
    if (this.meta?.fees) {
      const feesFiat = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.meta.fees);
      return feesFiat[this.appState.selectedCurrencyForConvert]
        ? Number(feesFiat[this.appState.selectedCurrencyForConvert] ?? 0)
        : undefined;
    }

    return undefined;
  }

  @computed
  get formattedConvertedAmounts(): string | undefined {
    if (this.childs?.length && !this.childsIsOpen) {
      return formatWlCurrency(
        this.amountFiatSum ? this.amountFiatSum[this.appState.selectedCurrencyForConvert] ?? 0 : 0,
        this.appState.selectedCurrencyForConvert,
      );
    }
    if (this.meta?.amounts) {
      const amountsFiat = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.meta.amounts);
      return amountsFiat[this.appState.selectedCurrencyForConvert]
        ? formatWlCurrency(
            Number(amountsFiat[this.appState.selectedCurrencyForConvert] ?? 0),
            this.appState.selectedCurrencyForConvert,
          )
        : undefined;
    }

    return undefined;
  }

  @computed
  get formattedConvertedFee(): string | undefined {
    if (this.currency === protocol.Currency.ETH_CUR && this.childs?.length && !this.childsIsOpen) {
      return formatWlCurrency(
        this.feeFiatSum ? this.feeFiatSum[this.appState.selectedCurrencyForConvert] ?? 0 : 0,
        this.appState.selectedCurrencyForConvert,
      );
    }

    if (this.meta?.fees) {
      const feesFiat = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.meta.fees);
      return feesFiat[this.appState.selectedCurrencyForConvert]
        ? formatWlCurrency(
            Number(feesFiat[this.appState.selectedCurrencyForConvert] ?? 0),
            this.appState.selectedCurrencyForConvert,
          )
        : undefined;
    }
    return undefined;
  }

  @computed
  get currencyCode(): string {
    return getCurrencyCode(this.currency);
  }

  @computed
  get currencyIconName(): string {
    return getCurrencyIcon(this.currency);
  }

  @computed
  get formattedDate(): string {
    return new Date(this.updatedAt?.multiply(1000).toNumber() ?? '').toLocaleString();
  }

  @computed
  get formattedDateOnly(): string {
    return new Date(this.updatedAt?.multiply(1000).toNumber() ?? '').toLocaleDateString();
  }

  @computed
  get formattedTimeOnly(): string {
    return new Date(this.updatedAt?.multiply(1000).toNumber() ?? '').toLocaleTimeString();
  }

  @computed
  get updatedAtNumber(): number {
    return this.updatedAt?.multiply(1000).toNumber() ?? 0;
  }

  @computed
  get createdAtNumber(): number {
    return this.createdAt?.multiply(1000).toNumber() ?? 0;
  }

  @action
  toggleChildsIsOpen = () => {
    this.childsIsOpen = !this.childsIsOpen;
  };

  @computed
  get formattedConvertedAmountUsd(): string | undefined {
    const amountsFiat = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.meta?.amounts);

    return formatWlCurrency(
      amountsFiat ? Number(amountsFiat[currencies.WLCurrency.WLC_USD] ?? 0) : 0,
      currencies.WLCurrency.WLC_USD,
    );
  }

  @computed
  get formattedConvertedAmountsListWithoutUsd(): string[] {
    const additionalAmounts: string[] = [];
    if (this.meta?.amounts) {
      const amountsFiat = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.meta.amounts);
      if (amountsFiat) {
        Object.keys(amountsFiat)?.forEach(currency => {
          if (Number(currency) !== currencies.WLCurrency.WLC_USD) {
            additionalAmounts.push(formatWlCurrency(Number(amountsFiat![Number(currency)]), Number(currency)));
          }
        });
      }
    }

    return additionalAmounts;
  }

  @computed
  get formattedCustomAmounts(): string[] {
    const amounts: string[] = [];
    if (this.customAmounts) {
      const amountsFiat = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.customAmounts);
      if (amountsFiat) {
        Object.keys(amountsFiat)?.forEach(currency => {
          amounts.push(formatWlCurrency(Number(amountsFiat![Number(currency)]), Number(currency)));
        });
      }
    }

    return amounts;
  }

  @computed
  get formattedPayoutTags() {
    return this.payoutTags?.join(', ');
  }

  @computed
  get fromAddressesWithTags(): IAddressWithTags[] | undefined {
    return this.direction === protocol.TxDirection.OUT_TX_DIR &&
      this.from.includes(this.address) &&
      !this.childs?.length
      ? [
          {
            address: this.address,
            tags: this.addressTags,
          },
        ]
      : this.from?.map(address => {
          const childAddress = this.childs?.find(_ => _.address === address)?.addressTags;

          return {
            address: address,
            tags: this.address === address ? this.addressTags : childAddress ?? undefined,
          };
        });
  }

  @computed
  get toAddresses(): IAddressWithTags[] | undefined {
    return this.direction === protocol.TxDirection.IN_TX_DIR && this.to.includes(this.address) && !this.childs?.length
      ? [
          {
            address: this.address,
            tags: this.addressTags,
          },
        ]
      : this.to?.map(address => {
          const childAddress = this.childs?.find(_ => _.address === address)?.addressTags;

          return {
            address: address,
            tags: this.address === address ? this.addressTags : childAddress ?? undefined,
          };
        });
  }
}
