import { observer } from 'mobx-react';
import { ConfirmModal } from '../../ModalTypes/ConfirmModal/ConfirmModal';
import { ButtonType } from '../../../controls/Button/Button';
import { useState } from '../../../../state/AppState';

export const CancelProcessingTransactionModal = observer(() => {
  const store = useState();
  const { modalsStore, mainPageInfo } = store;
  return (
    <ConfirmModal
      title="Transaction cancellation"
      isVisible={true}
      description="Do you really want to cancel the transaction?"
      acceptButtonText="Yes"
      acceptButtonType={ButtonType.PRIMARY}
      acceptButtonClick={mainPageInfo.deleteProcessingTransaction}
      cancelButtonText="Close"
      onCancel={() => modalsStore.closeModal()}
      acceptButtonLoading={mainPageInfo.processingTransactionCancellationLoading}
    />
  );
});
