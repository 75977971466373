import { Button, ButtonSize, ButtonType } from '../../../controls/Button/Button';
import { Icon } from '../../../Icon/Icon';
import { ModalContainer } from '../../Modal';
import './ConfirmModal.scss';

interface ConfirmModalProps {
  isVisible?: boolean;
  title: string;
  description: string;
  acceptButtonText: string;
  acceptButtonType: ButtonType;
  acceptButtonClick: () => void;
  cancelButtonText: string;
  onCancel: () => void;
  acceptButtonLoading?: boolean;
}

export const ConfirmModal = ({
  isVisible,
  title,
  description,
  acceptButtonText,
  acceptButtonType,
  acceptButtonClick,
  cancelButtonText,
  onCancel,
  acceptButtonLoading,
}: ConfirmModalProps) => {
  if (!isVisible) return null;
  return (
    <ModalContainer>
      <div className="ConfirmModal">
        <div className="ConfirmModal__title">
          {title}
          <div onClick={onCancel}>
            <Icon iconName="close-modal" widthInPx={16} heightInPx={16} />
          </div>
        </div>
        <div className="ConfirmModal__description">{description}</div>
        <div className="ConfirmModal__buttons">
          <Button
            label={acceptButtonText}
            type={acceptButtonType}
            size={ButtonSize.NORMAL_XL}
            onClick={acceptButtonClick}
            isLoading={acceptButtonLoading}
          />
          <Button label={cancelButtonText} type={ButtonType.SECONDARY} size={ButtonSize.NORMAL_XL} onClick={onCancel} />
        </div>
      </div>
    </ModalContainer>
  );
};
