import './RiskScoreChart.scss';
import ReactApexChart from 'react-apexcharts';
import { Icon } from '../../Icon/Icon';
import { RISK_LEVEL } from '../../../state/utils/amlUtils';

interface RiskScoreChartProps {
  percent: number;
  riskLevelLabel?: boolean;
  isLoading?: boolean;
  riskLevel?: RISK_LEVEL;
}

enum RISK_LEVEL_MIN_PERCENTS {
  HIGH = 50,
  MEDIUM = 10,
  LOW = 0,
}

const getRiskLevel = (percent: number) => {
  if (percent > RISK_LEVEL_MIN_PERCENTS.MEDIUM && percent < RISK_LEVEL_MIN_PERCENTS.HIGH) {
    return RISK_LEVEL.MEDIUM;
  }
  if (percent > RISK_LEVEL_MIN_PERCENTS.HIGH) {
    return RISK_LEVEL.HIGH;
  }
  return RISK_LEVEL.LOW;
};

const getColorByRiskLevel = (level: RISK_LEVEL) => {
  switch (level) {
    case RISK_LEVEL.HIGH:
      return '#E02138';
    case RISK_LEVEL.LOW:
      return '#17AA75';
    case RISK_LEVEL.MEDIUM:
      return '#E08821';
    case RISK_LEVEL.UNKNOWN:
      return '#E2DFF2';
  }
};

export const RiskScoreChart = ({ percent, riskLevelLabel, riskLevel }: RiskScoreChartProps) => {
  const risk = riskLevel ?? getRiskLevel(percent);
  const color = getColorByRiskLevel(risk);
  const chartOptions = {
    colors: [color],

    plotOptions: {
      radialBar: {
        startAngle: -85,
        endAngle: 85,
        track: {
          background: '#E2DFF2',
          startAngle: -85,
          endAngle: 85,
        },
        hollow: {
          margin: 5,
          size: '63%',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
          total: {
            show: false,
          },
        },
      },
    },
    fill: {
      type: 'color',
    },
    stroke: {
      lineCap: 'round',
    },
  };
  const label = riskLevelLabel ? `${risk.charAt(0).toUpperCase() + risk.slice(1)} risk` : 'avg. risk score';
  return (
    <div className="RiskScoreChart">
      <div className="chart-container">
        <ReactApexChart
          width={250}
          height={250}
          options={chartOptions as any}
          series={[100 - percent]}
          type="radialBar"
        />
      </div>
      <div className="info-block">
        <Icon iconName={`risk-level-${riskLevel}`} heightInPx={36} widthInPx={20} />
        <div className="percent">{risk === RISK_LEVEL.UNKNOWN ? 'N/A' : `${percent}%`}</div>
        <div className="risk-label">{label}</div>
      </div>
    </div>
  );
};
