import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import './Table.scss';
import { TableFilters } from './TableFilters';
import { getFormattedTableValue, TableRow } from './TableRow';
import { TableTh } from './TableTh';
import { CustomizeColumnsModal } from './CustomizeColumnsModal/CustomizeColumnsModal';
import { TablePagination } from './TablePagination';
import { Loader } from '../Loader/Loader';
import cn from 'classnames';
import { Checkbox } from '../controls/Checkbox/Checkbox';
import { TableTotals } from './TableTotals';
import { currencies, protocol } from '../../api/proto';

export enum SORT_DIRECTION {
  ASC,
  DESC,
}

export enum COLUMN_CONTENT_TYPE {
  LONG,
}

export enum COLUMN_FORMAT {
  CURRENCY,
}

export enum TABLE_STYLE {
  DEFAULT = 'default',
  ROUNDED = 'rounded',
}

export const FILTER_PERIODS = ['All', 'Today', 'Yesterday', 'Custom period'] as const;
export type FilterPeriod = (typeof FILTER_PERIODS)[number];

export type FormatFields = {
  currencyKey?: string;
  currency?: protocol.Currency | currencies.WLCurrency;
  isWlCurrency?: boolean;
};

export interface ITotalFromApi {
  key: string;
  value?: string | number | null;
  additionalValue?: string | number | null;
}

export interface IColumn {
  key: string;
  title: string;
  sortEnabled: boolean;
  additionalFields?: string[];
  naSymbol?: string;
  sortKey?: string;
  sortFirstByColumn?: string;
  type?: COLUMN_CONTENT_TYPE;
  format?: COLUMN_FORMAT;
  formatApplyOnlyForComputedTotal?: boolean;
  renderCustomCellComponent?: (row: object) => React.ReactNode;
  withTotal?: boolean;
  totalKey?: string;
  formatFields?: FormatFields;
  isTotalsFromApi?: boolean;
  renderFilterComponent?: () => React.ReactNode;
}

export interface IColumnState extends IColumn {
  enabled: boolean;
  order: number;
}

export interface ISortedColumn {
  name: string;
  direction?: SORT_DIRECTION;
  sortFirstByColumn?: string;
}

interface ITableProps {
  columns: IColumn[];
  data: object[];
  defaultSortedColumn?: ISortedColumn;
  periodsFilterColumn?: string;
  hasChildRows?: boolean;
  openChildsInAnotherPage?: boolean;
  filtersLeftBlock?: React.ReactElement;
  filtersCustomPeriodsFilters?: React.ReactElement;
  withoutFilters?: boolean;

  pageCount?: number;
  onPageChange?: (page: number) => void;
  page?: number;
  serverSortingAndFilters?: boolean;
  sortedColumn?: ISortedColumn;
  onSortColumnClick?: (name: string) => void;
  period?: FilterPeriod;
  onChangePeriod?: (period: FilterPeriod) => void;
  customDateRange?: [Date | null, Date | null];
  setCustomDateRange?: (range: Date | [Date | null, Date | null] | null) => void;
  contentIsLoading?: boolean;
  tableStyle?: TABLE_STYLE;
  onRowClick?: (rowData: object) => void;

  selectedRows?: string[];
  selectRowKeyId?: string;
  onToggleSelectRow?: (rowId: string) => void;
  onSelectAllRows?: () => void;
  onUnSelectAllRows?: () => void;

  totalsFromApi?: ITotalFromApi[];
  tableBlockRef?: React.RefObject<HTMLDivElement>;
  onSetBlockRef?: (ref: React.RefObject<HTMLDivElement>) => void;
}

@observer
class Table extends React.Component<ITableProps> {
  constructor(props: ITableProps) {
    super(props);
    makeObservable(this);
  }

  @observable columns: IColumnState[] = [];
  @observable sortedColumn?: ISortedColumn = undefined;
  @observable period: FilterPeriod = 'All';
  @observable customDateRange?: [Date | null, Date | null];
  @observable customizeColumnsModalIsOpen: boolean = false;

  @observable ref: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount(): void {
    autorun(() => {
      if (this.ref && this.props.onSetBlockRef && this.props.tableBlockRef?.current !== this.ref?.current) {
        this.props.onSetBlockRef(this.ref);
      }
    });
    this.setNewColumns(this.props.columns);
    if (this.props.defaultSortedColumn) {
      this.sortedColumn = this.props.defaultSortedColumn;
    }
  }

  componentDidUpdate(prevProps: Readonly<ITableProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (
      prevProps.columns &&
      this.props.columns &&
      JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns)
    ) {
      this.setNewColumns(this.props.columns);
    }
  }

  @computed
  get sortedColumnCurrent() {
    if (this.props.serverSortingAndFilters) {
      return this.props.sortedColumn;
    }
    return this.sortedColumn;
  }

  @computed
  get currentPeriod() {
    if (this.props.serverSortingAndFilters && this.props.period) {
      return this.props.period;
    }
    return this.period;
  }

  @computed
  get currentCustomDateRange() {
    if (this.props.serverSortingAndFilters && this.props.customDateRange) {
      return this.props.customDateRange;
    }
    return this.customDateRange;
  }

  @computed
  get columnsWithTotal() {
    return this.enabledAndSortedColumns?.filter(col => col.withTotal);
  }

  @computed
  get totalsRow() {
    if (this.columnsWithTotal?.length) {
      const columns = this.enabledAndSortedColumns?.map((col, index) => {
        if (index === 0) {
          return 'Total';
        }
        if (col.withTotal) {
          if (col.isTotalsFromApi) {
            const total = this.props.totalsFromApi?.find(_ => (col.totalKey || col.key) === _.key);
            if (total?.value) {
              if (total.additionalValue) {
                return (
                  <div>
                    <div>{total.value}</div>
                    <div>{total.additionalValue}</div>
                  </div>
                );
              }
              return total.value;
            }
            return '';
          } else {
            const columnTotal = this.sortedData.reduce((acc, _: any) => {
              if (_[col.totalKey || col.key]) {
                return acc + Number(_[col.totalKey || col.key]);
              }
              return acc;
            }, 0);
            return String(getFormattedTableValue(col, undefined, columnTotal, true));
          }
        }
        if (col.renderFilterComponent) {
          return col.renderFilterComponent();
        }
        return '';
      });
      if (this.props.hasChildRows || !!this.props.onToggleSelectRow) {
        columns.unshift('');
      }
      return columns;
    }
    return undefined;
  }

  @action
  hideCustomizeColumnsModal = () => {
    this.customizeColumnsModalIsOpen = false;
  };

  @action
  openCustomizeColumnsModal = () => {
    this.customizeColumnsModalIsOpen = true;
  };

  @action
  setNewColumns = (columns: IColumn[]) => {
    this.columns = columns.map((col, i) => ({ ...col, enabled: true, order: i }));
  };

  @computed
  get sortedData() {
    if (this.props.serverSortingAndFilters) {
      return this.props.data;
    }
    return this.sortedColumnCurrent &&
      this.sortedColumnCurrent.name &&
      this.sortedColumnCurrent?.direction !== undefined
      ? this.filteredData.slice().sort((a: any, b: any) => {
          const colName = this.sortedColumnCurrent!.name;
          const sortDirection = this.sortedColumnCurrent!.direction;
          const sortFirstByColumn = this.sortedColumnCurrent!.sortFirstByColumn;
          if (sortFirstByColumn) {
            const firstSort = this.sort(a, b, sortFirstByColumn, sortDirection);
            if (firstSort === 0) {
              return this.sort(a, b, colName, sortDirection);
            } else {
              return firstSort;
            }
          }
          return this.sort(a, b, colName, sortDirection);
        })
      : this.filteredData;
  }

  sort = (a: any, b: any, colName: string, sortDirection?: SORT_DIRECTION) => {
    if (a[colName] && b[colName]) {
      if (typeof a[colName] === 'string') {
        if (a[colName]! > b[colName]!) {
          return sortDirection === SORT_DIRECTION.ASC ? 1 : -1;
        }
        if (a[colName]! < b[colName]!) {
          return sortDirection === SORT_DIRECTION.ASC ? -1 : 1;
        }
      } else {
        return sortDirection === SORT_DIRECTION.ASC
          ? (Number(a[colName]) || 0) - (Number(b[colName]) || 0)
          : (Number(b[colName]) || 0) - (Number(a[colName]) || 0);
      }
    } else {
      if (a[colName] && !b[colName]) return sortDirection === SORT_DIRECTION.ASC ? 1 : -1;
      if (!a[colName!] && b[colName]) return sortDirection === SORT_DIRECTION.ASC ? -1 : 1;
    }
    return 0;
  };

  @action
  onSortColumnClick = (name: string, sortFirstByColumn?: string) => {
    if (this.props.serverSortingAndFilters && this.props.onSortColumnClick) {
      this.props.onSortColumnClick(name);
    } else {
      if (this.sortedColumn?.name && this.sortedColumn.name === name) {
        if (this.sortedColumn.direction === SORT_DIRECTION.ASC) {
          this.sortedColumn = undefined;
        } else if (this.sortedColumn.direction === SORT_DIRECTION.DESC) {
          this.sortedColumn.direction = SORT_DIRECTION.ASC;
        } else {
          this.sortedColumn.direction = SORT_DIRECTION.DESC;
        }
      } else {
        this.sortedColumn = {
          name,
          direction: SORT_DIRECTION.DESC,
          sortFirstByColumn: sortFirstByColumn,
        };
      }
    }
  };

  @action
  onChangePeriod = (period: FilterPeriod) => {
    if (this.props.serverSortingAndFilters && this.props.onChangePeriod) {
      this.props.onChangePeriod(period);
    } else {
      if (period !== 'Custom period') {
        this.customDateRange = undefined;
      }
      if (this.period === period) {
        this.period = 'All';
      } else {
        this.period = period;
      }
    }
  };

  @computed
  get fromToNumTotalFilter() {
    let from = 0;
    let to = 0;
    if (this.period === 'Today') {
      from = moment().startOf('day').unix();
    } else if (this.period === 'Yesterday') {
      from = moment().subtract('1', 'days').startOf('day').unix();
      to = moment().startOf('day').unix();
    } /* else if (this.period === 'Last week') {
      from = moment().subtract('7', 'days').startOf('day').unix();
    } else if (this.period === 'Last month') {
      from = moment().subtract('1', 'month').startOf('day').unix();
    } else if (this.period === 'Last 3 months') {
      from = moment().subtract('3', 'month').startOf('day').unix();
    } else if (this.period === 'Last 6 months') {
      from = moment().subtract('6', 'month').startOf('day').unix();
    } else if (this.period === 'Last year') {
      from = moment().subtract('1', 'year').startOf('day').unix();
    }*/
    return { from, to };
  }

  @action
  setCustomDateRange = (range: Date | [Date | null, Date | null] | null) => {
    if (this.props.serverSortingAndFilters && this.props.setCustomDateRange) {
      this.props.setCustomDateRange(range);
    } else {
      this.customDateRange = (range as [Date | null, Date | null]) || undefined;
      this.period = 'Custom period';
    }
  };

  @computed
  get filteredData(): object[] {
    const { from, to } = this.fromToNumTotalFilter;
    if (this.props.periodsFilterColumn) {
      if (
        this.period === 'Today' /*||
        this.period === 'Last week' ||
        this.period === 'Last month' ||
        this.period === 'Last 3 months' ||
        this.period === 'Last 6 months' ||
        this.period === 'Last year'*/
      ) {
        return this.props.data.filter(_ => {
          return (_ as any)[this.props.periodsFilterColumn!] >= (from * 1000)! ?? false;
        });
      } else if (this.period === 'Yesterday' && from && to) {
        return this.props.data.filter(_ => {
          return (
            (_ as any)[this.props.periodsFilterColumn!] >= from * 1000 &&
            (_ as any)[this.props.periodsFilterColumn!] <= to * 1000
          );
        });
      } else if (this.period === 'Custom period' && this.customDateRange?.length) {
        const [start, end] = this.customDateRange;

        if (start && end) {
          return this.props.data.filter(_ => {
            return (
              (_ as any)[this.props.periodsFilterColumn!] >= start.getTime() &&
              (_ as any)[this.props.periodsFilterColumn!] <= end.getTime() + 86400000
            );
          });
        }
      }
    }

    return this.props.data;
  }

  @computed
  get enabledAndSortedColumns() {
    return this.columns.filter(_ => !!_.enabled).sort((a, b) => a.order - b.order);
  }

  @computed
  get sortedColumns() {
    return this.columns.slice().sort((a, b) => a.order - b.order);
  }

  @action
  toggleColumnStatus = (col: IColumnState) => {
    if (this.columns[this.columns.indexOf(col)]) {
      this.columns[this.columns.indexOf(col)].enabled = !this.columns[this.columns.indexOf(col)].enabled;
    }
  };

  @action
  reorderColumns = (index: number, destinationIndex: number) => {
    const columns = this.sortedColumns;
    const [removed] = columns.splice(index, 1);
    columns.splice(destinationIndex, 0, removed);
    this.columns = columns.map((col, i) => ({
      ...col,
      order: i,
    }));
  };

  renderRows = () => {
    return this.sortedData.map((_, i) => {
      const row = _ as any;
      const elements = [
        row.childsIsOpen && !this.props.openChildsInAnotherPage ? (
          [
            <TableRow
              hasChildRows={this.props.hasChildRows}
              columns={this.enabledAndSortedColumns}
              key={row.id}
              rowData={row}
              onRowClick={this.props.onRowClick}
              selectedRows={this.props.selectedRows}
              selectRowKeyId={this.props.selectRowKeyId}
              onToggleSelectRow={this.props.onToggleSelectRow}
              openChildsInAnotherPage={this.props.openChildsInAnotherPage}
            />,
            row.childs?.map((child: any) => (
              <TableRow
                hasChildRows={this.props.hasChildRows}
                columns={this.enabledAndSortedColumns}
                key={row.id}
                isChild={true}
                rowData={child}
                onRowClick={this.props.onRowClick}
                selectedRows={this.props.selectedRows}
                selectRowKeyId={this.props.selectRowKeyId}
                onToggleSelectRow={this.props.onToggleSelectRow}
                openChildsInAnotherPage={this.props.openChildsInAnotherPage}
              />
            )),
          ]
        ) : (
          <TableRow
            hasChildRows={this.props.hasChildRows}
            columns={this.enabledAndSortedColumns}
            key={row.id}
            rowData={row}
            onRowClick={this.props.onRowClick}
            selectedRows={this.props.selectedRows}
            selectRowKeyId={this.props.selectRowKeyId}
            onToggleSelectRow={this.props.onToggleSelectRow}
            openChildsInAnotherPage={this.props.openChildsInAnotherPage}
          />
        ),
      ];
      if (this.props.periodsFilterColumn) {
        const thisElementDate = moment.unix(row[this.props.periodsFilterColumn] / 1000);
        const prevElementDate =
          this.sortedData[i - 1] && (this.sortedData[i - 1] as any)[this.props.periodsFilterColumn]
            ? moment.unix((this.sortedData[i - 1] as any)[this.props.periodsFilterColumn] / 1000)
            : undefined;
        const dateElement = (
          <tr className="date-row" key={this.props.periodsFilterColumn}>
            <td colSpan={this.props.hasChildRows ? this.columns.length + 1 : this.columns.length}>
              {thisElementDate.format('DD.MM.YYYY, dddd')}
            </td>
          </tr>
        );
        if (i === 0) {
          elements.unshift(dateElement);
        }
        if (thisElementDate.date() && prevElementDate?.date() && thisElementDate.date() !== prevElementDate.date()) {
          elements.unshift(dateElement);
        }
      }
      return elements;
    });
  };

  onPageChange = (page: number) => {
    if (this.props.onPageChange) {
      this.props.onPageChange(page);
      this.ref?.current?.scrollTo(0, 0);
    }
  };

  render() {
    return (
      <div className={cn('Table-container', this.props.tableStyle)}>
        {!this.props.withoutFilters && (
          <TableFilters
            period={this.currentPeriod}
            onChangePeriod={this.onChangePeriod}
            setCustomDateRange={this.setCustomDateRange}
            customDateRange={this.currentCustomDateRange}
            openCustomizeColumnsModal={this.openCustomizeColumnsModal}
            filtersLeftBlock={this.props.filtersLeftBlock}
            filtersCustomPeriodsFilters={this.props.filtersCustomPeriodsFilters}
          />
        )}
        <div ref={this.ref} className={cn('Table-block', { loading: this.props.contentIsLoading })}>
          <table className="Table">
            <thead>
              <tr>
                {this.props.hasChildRows ? (
                  <th className="fixed-col"></th>
                ) : this.props.onToggleSelectRow ? (
                  <th className="fixed-col">
                    <Checkbox
                      isActive={this.props.selectedRows?.length === this.sortedData?.length}
                      toggle={
                        this.props.selectedRows?.length === this.sortedData?.length
                          ? this.props.onUnSelectAllRows
                          : this.props.onSelectAllRows
                      }
                    />
                  </th>
                ) : null}
                {this.enabledAndSortedColumns?.map(col => (
                  <TableTh
                    onSortClick={this.onSortColumnClick}
                    sortedColumn={this.sortedColumnCurrent}
                    columnName={col.sortKey || col.key}
                    title={col.title}
                    sortEnabled={col.sortEnabled}
                    sortFirstByColumn={col.sortFirstByColumn}
                  />
                ))}
              </tr>
            </thead>

            {!this.props.contentIsLoading && [
              this.columnsWithTotal?.length ? <TableTotals totals={this.totalsRow} /> : null,
              <tbody>{this.renderRows()}</tbody>,
            ]}
          </table>
        </div>
        {this.props.contentIsLoading && (
          <div className="Table__loader-container">
            <Loader />
          </div>
        )}
        {this.props.pageCount && this.props.onPageChange && this.props.page !== undefined ? (
          <TablePagination pageCount={this.props.pageCount} page={this.props.page} onPageChange={this.onPageChange} />
        ) : null}
        <CustomizeColumnsModal
          isOpen={this.customizeColumnsModalIsOpen}
          onCloseModal={this.hideCustomizeColumnsModal}
          columns={this.columns}
          toggleColumnStatus={this.toggleColumnStatus}
          reorderColumns={this.reorderColumns}
        />
      </div>
    );
  }
}

export default Table;
