export enum ProtectedRoutes {
  MAIN_PAGE = '/dashboard/user/:clientId',
  WITHDRWAL = '/dashboard/user/:clientId/withdrawal/:currency',
  WITHDRWAL_RESULT = '/dashboard/user/:clientId/withdrawal/:currency/result',
  EXPLORER = '/dashboard/user/:clientId/explorer',
  ACTIVITY = '/dashboard/user/:clientId/activity',
  AML_MAIN_PAGE = '/dashboard/user/:clientId/aml',
  AML_REPORT_PAGE = '/dashboard/user/:clientId/aml/:reportId',
  AML_ADDRESS_PAGE = '/dashboard/user/:clientId/aml/:reportId/:reportRowId',
  AML_SINGLE_CHECK_PAGE = '/dashboard/user/:clientId/aml/single/:id',
  INVOICES_MAIN_PAGE = '/dashboard/user/:clientId/invoices',
  CREATE_INVOICE_PAGE = '/dashboard/user/:clientId/invoices/create',
  INVOICES_PEER_PAGE = '/dashboard/user/:clientId/invoices/peer/:peerId',
  USERS = '/dashboard/user/:clientId/users',
  SYSTEM_ADDRESSES = '/dashboard/user/:clientId/system-addresses',
  TX_INFO = '/dashboard/user/:clientId/tx-info',
}

export const menuRoutes = [
  {
    route: ProtectedRoutes.MAIN_PAGE,
    additionalRoutes: [ProtectedRoutes.WITHDRWAL, ProtectedRoutes.WITHDRWAL_RESULT, ProtectedRoutes.TX_INFO],
    title: 'Home',
  },
  {
    route: ProtectedRoutes.AML_MAIN_PAGE,
    title: 'AML',
    additionalRoutes: [ProtectedRoutes.AML_ADDRESS_PAGE, ProtectedRoutes.AML_REPORT_PAGE],
  },
  {
    route: ProtectedRoutes.INVOICES_MAIN_PAGE,
    title: 'Invoices',
    additionalRoutes: [ProtectedRoutes.CREATE_INVOICE_PAGE, ProtectedRoutes.INVOICES_PEER_PAGE],
  },
  {
    route: ProtectedRoutes.USERS,
    title: 'Users',
  },
  {
    route: ProtectedRoutes.SYSTEM_ADDRESSES,
    title: 'System addresses',
  },
];
