import { observer } from 'mobx-react';
import { useState } from '../../../state/AppState';
import { currenciesOptions } from '../../../state/utils/Money';
import { Button, ButtonSize, ButtonType } from '../../controls/Button/Button';
import { Checkbox } from '../../controls/Checkbox/Checkbox';
import { SearchableSelectMultiple } from '../../controls/SearchableSelectMultiple/SearchableSelectMultiple';
import './SystemAddressesFilters.scss';

export const SystemAddressesFilters = observer(() => {
  const state = useState();
  const { systemAddressesStore } = state;

  return (
    <div className="SystemAddressesFilters ContentBlock">
      <div className="ContentBlockTitle">Filters</div>
      <div className="SystemAddressesFilters__filters">
        <div className="SystemAddressesFilters__filters__inputs">
          <SearchableSelectMultiple
            label="Currencies"
            placeholder={'Select currencies'}
            className="SystemAddressesFilters__filters__currenciesSelect"
            options={currenciesOptions}
            selectedValues={systemAddressesStore.filterCurrencies}
            onChange={systemAddressesStore.changeCurrencies}
            withClearButton
          />
          <div className="SystemAddressesFilters__filters__checkbox">
            <div className="">Show address with token balance</div>
            <Checkbox
              isActive={systemAddressesStore.filterOnlyTokenBalanceEnabled}
              toggle={systemAddressesStore.toggleOnlyTokenBalanceFilter}
            />
          </div>
        </div>
        <div className="SystemAddressesFilters__filters__buttons">
          <Button
            label="Submit"
            onClick={systemAddressesStore.updateFilteredData}
            type={ButtonType.PRIMARY}
            size={ButtonSize.NORMAL_XL}
          />
          <Button
            label="Clear"
            onClick={systemAddressesStore.resetFilters}
            type={ButtonType.SECONDARY_TYPE_2}
            size={ButtonSize.NORMAL_XL}
          />
        </div>
      </div>
    </div>
  );
});
