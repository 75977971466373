import { Icon } from '../../Icon/Icon';
import './AddressTag.scss';
import cn from 'classnames';

export const AddressTag = ({ tag, className }: { tag: string; className?: string }) => {
  const isInvoiceTag = tag.startsWith('peer_');
  return (
    <div className={cn('AddressTag', className, { invoiceTag: isInvoiceTag })}>
      {isInvoiceTag ? <Icon iconName="invoice-tag" widthInPx={14} heightInPx={14} /> : null}
      {tag}
    </div>
  );
};
