import { makeObservable, observable, action, computed } from 'mobx';
import { protocol } from '../api/proto';
import { AppState } from './AppState';
import { Operation } from './Operation';
import { processOperations } from './utils/OperationsUtils';

interface ILoadingsSearchs {
  trxId: boolean;
  address: boolean;
  tag: boolean;
}

export class ExplorerState {
  private appState: AppState;

  @observable searchValue?: string = '';
  @observable loadings: ILoadingsSearchs = {
    trxId: false,
    tag: false,
    address: false,
  };
  operations = observable.array<Operation>([]);
  @observable operationsLength?: number = undefined;
  @observable nothingFoundError: ILoadingsSearchs = {
    trxId: false,
    tag: false,
    address: false,
  };

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  changeSearchValue = (val: string) => {
    this.searchValue = val;
  };

  @action
  onStartSearch = () => {
    if (this.searchValue) {
      this.operationsLength = undefined;
      this.nothingFoundError = {
        trxId: false,
        tag: false,
        address: false,
      };
      this.searchByTrxId();
      this.searchByAddress();
      this.searchByTagName();
    }
  };

  @computed
  get nothingFound() {
    return this.nothingFoundError.address && this.nothingFoundError.trxId && this.nothingFoundError.tag;
  }

  @action
  searchByTrxId = () => {
    this.loadings.trxId = true;
    this.appState.api.transactions(
      {
        txHash: this.searchValue,
      },
      (msg: protocol.IServerResponse) => this.onSearchResponse(msg, 'trxId'),
    );
  };

  @action
  searchByAddress = () => {
    this.loadings.trxId = true;
    this.appState.api.transactions(
      {
        address: this.searchValue,
      },
      (msg: protocol.IServerResponse) => this.onSearchResponse(msg, 'address'),
    );
  };

  @action
  searchByTagName = () => {
    this.loadings.tag = true;
    this.appState.api.tagsRequest(
      {
        filterTagNames: [this.searchValue!],
      },
      (msg: protocol.IServerResponse) => {
        if (msg.listTagsResponse?.tags?.length) {
          let txIds: number[] = [];
          msg.listTagsResponse?.tags.forEach(tag => {
            if (tag.txIds?.length) {
              txIds = txIds.concat(tag.txIds);
            }
          });
          this.appState.api.transactions(
            {
              txId: txIds[0],
            },
            (msg: protocol.IServerResponse) => this.onSearchResponse(msg, 'tag'),
          );
        } else {
          this.loadings.tag = false;
          this.nothingFoundError.tag = true;
        }
      },
    );
  };

  @action
  onSearchResponse = (msg: protocol.IServerResponse, searchBy: keyof ILoadingsSearchs) => {
    this.loadings[searchBy] = false;
    if (!msg?.transactionsResponse?.transactions?.length) {
      this.nothingFoundError[searchBy] = true;
    } else {
      this.operationsLength = msg?.transactionsResponse?.transactions?.length;
    }
    this.processOperations(msg);
  };

  @action.bound
  processOperations(response: protocol.IServerResponse) {
    let operations: Operation[] = [];
    if (response?.transactionsResponse?.transactions?.length) {
      operations = processOperations(response.transactionsResponse.transactions, this.appState);
    }

    if (operations?.length) {
      this.fetchTags(operations);
    } else {
      this.operations.replace([]);
    }
  }

  @action.bound
  fetchTags(operations: Operation[]) {
    this.appState.api.tagsRequest(
      {
        filterTxIds: operations?.filter(o => !!o.id).map(o => o.id),
      },
      (msg: protocol.IServerResponse) => this.onTagsResponse(msg, operations),
    );
  }

  @action.bound
  onTagsResponse(msg: protocol.IServerResponse, operations: Operation[]) {
    if (msg?.listTagsResponse?.tags?.length) {
      const tags = msg.listTagsResponse.tags.map(_ => ({ ..._ }));
      this.operations.replace(
        operations.map(operation => {
          if (operation.id) {
            const operationTags = tags
              .filter(tag => tag.txIds?.includes(operation.id) && !!tag.name)
              .map(tag => tag.name!);
            operation.tags = operationTags;
            if (operation.childs?.length) {
              operation.childs = operation.childs.map(child => {
                child.tags = operationTags;
                return child;
              });
            }
          }
          return operation;
        }),
      );
    }
  }

  @action
  resetPage = () => {
    this.operations.replace([]);
    this.nothingFoundError = {
      trxId: false,
      tag: false,
      address: false,
    };
    this.loadings = {
      trxId: false,
      address: false,
      tag: false,
    };
    this.searchValue = undefined;
    this.operationsLength = undefined;
  };
}
