import { AddressTag } from '../AddressTag/AddressTag';
import './TableTagsList.scss';

export const TableTagsList = ({ tags }: { tags: string[] }) => {
  return (
    <td>
      {tags?.length ? (
        <div className="TableTagsList">
          {tags.map(tag => (
            <AddressTag tag={tag} />
          ))}
        </div>
      ) : (
        '—'
      )}
    </td>
  );
};
