import Long from 'long';
import { action, computed, makeObservable, observable } from 'mobx';
import { currencies, protocol } from '../api/proto';
import { ISelectOption } from '../components/controls/Select/Select';
import { IAMLAddress } from './AmlReportStore';
import { AppState } from './AppState';
import { NotificationType } from './NotificationsState';
import { formatServerErrors } from './utils/format';
import { convertAmountToApi, formatCurrency, getFullCurrencyCode } from './utils/Money';
import { ISortedColumn, SORT_DIRECTION } from '../components/Table/Table';
import { AddressToWithdrawFrom } from './WithdrawalState';

const LIMIT_PAGINATION = 50;

const CHECKED_TAG = 'aml_checked';
export interface IAMLBatchCheckFormatted extends protocol.IAMLBatchCheck {
  lastCheckAtNumber?: number;
  createdAtNumber?: number;
  lastCheckAtFormatted?: string;
  createdAtFormatted?: string;
}

export interface IAMLCheckFormatted extends protocol.IAMLCheck {
  updatedAtNumber?: number;
  createdAtNumber?: number;
  updatedAtFormatted?: string;
  createdAtFormatted?: string;
}

export interface ICheckingReportInfo {
  checked?: number;
  total?: number;
  lowRisk?: number;
  mediumRisk?: number;
  highRisk?: number;
  unknownRisk?: number;
}

export interface IAmlReport extends protocol.IAMLCheck, protocol.IAMLCheckReport {}

export interface IAmlCheckedAddress extends protocol.IAMLClientAddress, IAMLAddress {
  lastCheckedAtNum?: number;
  lastCheckedAtFormatted?: string;
}

export type AmlPageTab = 'check' | 'reports' | 'single-reports';

type SelectedAddress = {
  currency: protocol.Currency;
  address: string;
  tags?: string[];
};
export class AmlStore {
  private appState: AppState;

  @observable tagsList: string[] = [];

  @observable statistics?: protocol.IAMLClientOverallStatsResponse;
  @observable statisticsLoading: boolean = false;

  @observable addresses?: IAmlCheckedAddress[];
  @observable addressesLoading: boolean = false;

  @observable amlPageTab: AmlPageTab = 'check';
  @observable filterAddresses: string[] = [];
  @observable filterCurrencies: protocol.Currency[] = [];
  @observable filterExcludingTags: string[] = [];
  @observable filterIncludingTags: string[] = [];
  @observable filterAmountFrom: string = '';
  @observable filterAmountTo: string = '';
  @observable filterCheckFraction?: string = '';
  @observable filterExcludeCheckedAddresses: boolean = false;

  @observable reportsList: protocol.IAMLClientBatch[] = [];
  @observable isReportsListLoading: boolean = false;

  @observable singleChecksList: IAMLCheckFormatted[] = [];
  @observable isSIngleChecksListLoading: boolean = false;

  @observable checkError?: string;
  @observable checksPreviewTotal?: number;

  @observable selectedAddresses: SelectedAddress[] = [];
  @observable addTagForWithdrawIsLoading: boolean = false;

  @observable singleCheckFilterAddress?: string = '';
  @observable singleCheckCurrency?: protocol.Currency;
  @observable singleCheckLoading: boolean = false;
  @observable singleCheckError?: string;

  @observable page: number = 0;
  @observable totalPages: number = 0;
  @observable totalCountAddresses: number = 0;
  @observable sortedColumnAddresses?: ISortedColumn = {
    name: 'riskScore',
    direction: SORT_DIRECTION.DESC,
  };

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @computed
  get isReadOnlyAccess() {
    return this.appState.isReadOnlyAllAccess;
  }

  @computed
  get filterExcludingTagsWithAdditionalTag() {
    if (this.filterExcludeCheckedAddresses) {
      return [...this.filterExcludingTags, CHECKED_TAG];
    }
    return this.filterExcludingTags;
  }

  @action
  changeTab = (tab: AmlPageTab) => {
    if (this.amlPageTab !== tab) {
      this.amlPageTab = tab;
      if (tab === 'reports') {
        this.fetchAmlReportsList();
      }
      if (tab === 'check') {
        this.loadAddresses();
      }
      if (tab === 'single-reports') {
        this.fetchSingleChecksList();
      }
    }
  };

  @action
  changePage = (page: number) => {
    this.page = page;
    this.loadAddresses();
  };

  @computed
  get addressesSortBy() {
    if (this.sortedColumnAddresses?.name) {
      switch (this.sortedColumnAddresses.name) {
        case 'address':
          return protocol.SortBy.ADDRESS_SORT_BY;
        case 'riskScore':
          return protocol.SortBy.RISK_SCORE_SORT_BY;
        case 'balanceNum':
          return protocol.SortBy.BALANCE_SORT_BY;
        case 'currency':
          return protocol.SortBy.CURRENCY_SORT_BY;
        case 'lastCheckedAtNum':
          return protocol.SortBy.DATE_SORT_BY;
      }
    }
    return undefined;
  }

  @action
  onSortColumnAddressesClick = (name: string) => {
    if (this.sortedColumnAddresses?.name && this.sortedColumnAddresses.name === name) {
      if (this.sortedColumnAddresses.direction === SORT_DIRECTION.ASC) {
        this.sortedColumnAddresses = undefined;
      } else if (this.sortedColumnAddresses.direction === SORT_DIRECTION.DESC) {
        this.sortedColumnAddresses.direction = SORT_DIRECTION.ASC;
      } else {
        this.sortedColumnAddresses.direction = SORT_DIRECTION.DESC;
      }
    } else {
      this.sortedColumnAddresses = {
        name,
        direction: SORT_DIRECTION.DESC,
      };
    }
    this.page = 0;
    this.loadAddresses();
  };

  @computed
  get isWithdrawalButtonEnabled() {
    if (this.selectedAddresses?.length) {
      let firstCurrency = this.selectedAddresses[0].currency;
      return !this.selectedAddresses.find(_ => _.currency !== firstCurrency);
    }
    return false;
  }

  @action
  onToggleAddressRow = (address: string) => {
    if (this.selectedAddresses.find(_ => _.address === address)) {
      this.selectedAddresses = this.selectedAddresses.filter(_ => _.address !== address);
    } else {
      const addressWithTags = this.addresses?.find(_ => _.address === address);
      if (addressWithTags?.currency && addressWithTags?.address) {
        this.selectedAddresses.push({
          tags: addressWithTags.tags ?? undefined,
          address: addressWithTags.address ?? '',
          currency: addressWithTags.currency,
        });
      }
    }
  };

  @action
  onSelectAllRows = () => {
    this.selectedAddresses =
      this.addresses
        ?.filter(_ => !!_.address && !!_.currency)
        .map(_ => ({
          address: _.address ?? '',
          tags: _.tags ?? undefined,
          currency: _.currency!,
        })) ?? [];
  };

  @action
  unselectAllRows = () => {
    this.selectedAddresses = [];
  };

  @action
  changeAddresses = (values?: string[]) => {
    this.filterAddresses = values || [];
    this.cancelCheck();
  };

  @action
  changeExcludingTagsFilter = (values: (string | number)[]) => {
    this.filterExcludingTags = values as string[];
    this.cancelCheck();
  };

  @action
  changeIncludingTagsFilter = (values: (string | number)[]) => {
    this.filterIncludingTags = values as string[];
    this.cancelCheck();
  };

  @action
  changeCurrencies = (values: (string | number)[]) => {
    this.filterCurrencies = values as protocol.Currency[];
    this.cancelCheck();
  };

  @action
  changeAmountFrom = (value: string) => {
    this.filterAmountFrom = value;
    this.cancelCheck();
  };

  @action
  changeAmountTo = (value: string) => {
    this.filterAmountTo = value;
    this.cancelCheck();
  };

  @action
  changeCheckFraction = (value: string) => {
    this.filterCheckFraction = value;
    this.cancelCheck();
  };

  @action
  toggleFilterExcludeCheckedAddresses = () => {
    this.filterExcludeCheckedAddresses = !this.filterExcludeCheckedAddresses;
  };

  @computed
  get tagsOptions(): ISelectOption[] {
    return this.tagsList?.filter(tag => tag !== CHECKED_TAG).map(tag => ({ label: tag, value: tag }));
  }

  @action
  changeSingleCheckAddress = (value: string) => {
    this.singleCheckFilterAddress = value;
  };

  @action
  changeSingleCheckCurrency = (currency?: string | number) => {
    this.singleCheckCurrency = currency as protocol.Currency;
  };

  @action
  initAml = () => {
    //this.loadCheckedAccounts();
    this.loadOverallStat();
    this.loadTags();
    if (this.amlPageTab === 'check') {
      this.loadAddresses();
    }
    if (this.amlPageTab === 'reports') {
      this.fetchAmlReportsList();
    }
    if (this.amlPageTab === 'single-reports') {
      this.fetchSingleChecksList();
    }
  };

  @action
  loadOverallStat = () => {
    this.statisticsLoading = true;
    this.appState.api.amlOverallStatRequest((msg: protocol.IServerResponse) => {
      if (msg?.amlClientOverallStatsResponse) {
        this.statistics = msg.amlClientOverallStatsResponse;
      }
      this.statisticsLoading = false;
    });
  };

  @action
  fetchAmlReportsList = () => {
    this.isReportsListLoading = true;
    this.appState.api.amlClientBatchChecksListRequest({}, this.onAmlClientBatchChecksListResponse);
  };

  @action
  loadTags = () => {
    if (this.appState.clientId) {
      this.appState.api.tagsRequest(
        {
          filterClientIds: [this.appState.clientId],
        },
        (msg: protocol.IServerResponse) => {
          if (msg?.listTagsResponse?.tags?.length) {
            this.tagsList = msg?.listTagsResponse?.tags?.filter(tag => !!tag.name)?.map(tag => tag.name!);
          }
        },
      );
    }
  };

  @action
  onAmlClientBatchChecksListResponse = (msg: protocol.IServerResponse) => {
    this.isReportsListLoading = false;
    if (msg?.amlClientBatchChecksListResponse?.batches?.length) {
      this.reportsList = msg.amlClientBatchChecksListResponse.batches.map(_ => ({
        ..._,
        lastCheckAtNumber: _.lastCheckAt?.toNumber(),
        createdAtNumber: _.createdAt?.toNumber(),
        lastCheckAtFormatted: _.lastCheckAt?.notEquals(0)
          ? new Date(_.lastCheckAt?.multiply(1000).toNumber() ?? '').toLocaleString()
          : undefined,
        createdAtFormatted: new Date(_.createdAt?.multiply(1000).toNumber() ?? '').toLocaleString(),
      }));
    }
  };

  @action
  checkPreview = () => {
    this.appState.api.amlBatchCheckRequest(
      {
        filters: {
          addresses: this.filterAddresses,
          excludeTags: this.filterExcludingTagsWithAdditionalTag,
          currencies: this.filterCurrencies,
          includeTags: this.filterIncludingTags,
          minBalanceUsd: this.filterAmountFrom
            ? convertAmountToApi(this.filterAmountFrom, currencies.WLCurrency.WLC_USD)
            : null,
          maxBalanceUsd: this.filterAmountTo
            ? convertAmountToApi(this.filterAmountTo, currencies.WLCurrency.WLC_USD)
            : null,
          checkFraction: this.filterCheckFraction ? Long.fromNumber(Number(this.filterCheckFraction)) : null,
        },
        estimate: true,
      },
      this.onCheckPreview,
    );
    this.loadAddresses();
  };

  @action
  onCheckPreview = (msg: protocol.IServerResponse) => {
    if (msg?.amlClientBatchCheckStartResponse?.totalChecks) {
      if (msg.amlClientBatchCheckStartResponse.totalChecks?.toNumber() === 0) {
        this.checkError = 'No addresses for check';
      } else {
        this.checksPreviewTotal = msg.amlClientBatchCheckStartResponse.totalChecks?.toNumber();
      }
    } else {
      this.checkError = formatServerErrors(msg.error);
    }
  };

  @action
  loadAddresses = () => {
    this.addressesLoading = true;
    this.appState.api.amlAddressesListRequest(
      {
        filters: {
          addresses: this.filterAddresses,
          excludeTags: this.filterExcludingTagsWithAdditionalTag,
          includeTags: this.filterIncludingTags,
          currencies: this.filterCurrencies,
          minBalanceUsd: this.filterAmountFrom
            ? convertAmountToApi(this.filterAmountFrom, currencies.WLCurrency.WLC_USD)
            : null,
          maxBalanceUsd: this.filterAmountTo
            ? convertAmountToApi(this.filterAmountTo, currencies.WLCurrency.WLC_USD)
            : null,
          checkFraction: this.filterCheckFraction ? Long.fromNumber(Number(this.filterCheckFraction)) : null,
        },
        limit: Long.fromNumber(LIMIT_PAGINATION),
        offset: Long.fromNumber(this.page * LIMIT_PAGINATION),
        sortBy: this.addressesSortBy,
        sortDesc:
          this.sortedColumnAddresses?.direction !== undefined
            ? this.sortedColumnAddresses?.direction === SORT_DIRECTION.DESC
            : undefined,
      },
      this.onLoadAddresses,
    );
  };

  @action
  onLoadAddresses = (msg: protocol.IServerResponse) => {
    this.totalPages = msg?.amlClientAddressesListResponse?.totalPages?.toNumber() ?? 0;
    this.totalCountAddresses = msg?.amlClientAddressesListResponse?.totalCount?.toNumber() ?? 0;
    this.addresses = [];
    if (msg?.amlClientAddressesListResponse?.addresses?.length) {
      this.addresses = msg?.amlClientAddressesListResponse?.addresses.map(address => {
        const balancesFormatted =
          address.balances && Object.values(address.balances)?.length
            ? Object.values(address.balances)
                .map(_ => ({
                  ..._,
                  balanceFormatted: _.currency && _.balance ? formatCurrency(Number(_.balance), _.currency) : '-',
                }))
                .sort((a, b) => {
                  if (a.currency === address.currency) return -1;
                  if (b.currency === address.currency) return 1;
                  return 0;
                })
            : [];
        const balanceNumForSort = balancesFormatted?.find(_ => _.currency === address.currency)?.balance;
        return {
          ...address,
          lastCheckedAtNum: address.lastCheckedAt?.toNumber(),
          lastCheckedAtFormatted: address.lastCheckedAt?.notEquals(0)
            ? new Date(address.lastCheckedAt?.multiply(1000).toNumber() ?? '').toLocaleString()
            : undefined,
          balanceNum: balanceNumForSort ? Number(balanceNumForSort) : undefined,
          balancesFormatted,
          tagsList: address.tags?.join(', ') ?? '-',
        };
      });
    }
    this.addressesLoading = false;
  };

  @action
  cancelCheck = () => {
    this.checksPreviewTotal = undefined;
    this.checkError = undefined;
  };

  @action
  startCheckOfUncheckedAddresses = () => {
    this.filterExcludingTags = [CHECKED_TAG];
    this.newCheckStart();
  };

  @action
  checkAllAddresses = () => {
    this.resetFilters();
    this.newCheckStart();
  };

  @action
  newCheckStart = () => {
    const params = this.selectedAddresses?.length
      ? {
          filters: {
            addresses: this.selectedAddresses?.map(_ => _.address),
          },
        }
      : {
          filters: {
            addresses: this.selectedAddresses?.map(_ => _.address),
            excludeTags: this.filterExcludingTagsWithAdditionalTag,
            includeTags: this.filterIncludingTags,
            currencies: this.filterCurrencies,
            minBalanceUsd: this.filterAmountFrom
              ? convertAmountToApi(this.filterAmountFrom, currencies.WLCurrency.WLC_USD)
              : null,
            maxBalanceUsd: this.filterAmountTo
              ? convertAmountToApi(this.filterAmountTo, currencies.WLCurrency.WLC_USD)
              : null,
            checkFraction: this.filterCheckFraction ? Long.fromNumber(Number(this.filterCheckFraction)) : null,
          },
        };
    this.appState.api.amlBatchCheckRequest(params, this.onCheckStartResponse);
  };

  @action
  onCheckStartResponse = (msg: protocol.IServerResponse) => {
    if (msg?.amlClientBatchCheckStartResponse?.batchCheckId) {
      this.appState.navigate!(
        `/dashboard/user/${this.appState.clientId}/aml/${msg.amlClientBatchCheckStartResponse.batchCheckId}`,
      );

      this.appState.amlReport.reportId = msg.amlClientBatchCheckStartResponse.batchCheckId;
    }
  };

  @action
  goToReports = () => {
    this.amlPageTab = 'reports';
    if (this.appState.navigate) this.appState.navigate(`/dashboard/user/${this.appState.clientId}/aml`);
  };

  @computed
  get selectedAddressesList() {
    return this.selectedAddresses?.map(_ => _.address) ?? [];
  }

  @action
  onWithdrawFromAddressesClick = () => {
    if (this.selectedAddresses?.length) {
      const selectedAddressesCurrency = this.selectedAddresses[0].currency;
      const selectedAddressesWithTags = this.selectedAddresses?.map(_ => ({
        address: _.address!,
        tags: _.tags ?? undefined,
      }));
      this.addTagForWithdrawIsLoading = true;
      if (this.appState.navigate) {
        this.appState.navigate(
          `/dashboard/user/${this.appState.clientId}/withdrawal/${getFullCurrencyCode(
            Number(selectedAddressesCurrency),
          )}`,
        );
        this.appState.withdrawalState.changeAddressesToWithdrawFrom(selectedAddressesWithTags ?? []);
      }
    }
  };

  @action
  singleCheckStart = () => {
    this.singleCheckLoading = true;
    this.appState.api.amlSingleCheckRequest(
      {
        address: this.singleCheckFilterAddress,
        currency: this.singleCheckCurrency,
        forceCheck: true,
      },
      this.onSingleCheckResponse,
    );
  };

  @action
  onSingleCheckResponse = (msg: protocol.IServerResponse) => {
    this.singleCheckLoading = false;
    if (msg?.amlSingleCheckResponse?.id) {
      this.appState.navigate!(`/dashboard/user/${this.appState.clientId}/aml/single/${msg.amlSingleCheckResponse.id}`);
    } else {
      this.appState.notifications.addNotification(formatServerErrors(msg.error), NotificationType.ERROR);
    }
  };

  @action
  fetchSingleChecksList = () => {
    this.isSIngleChecksListLoading = true;
    this.appState.api.amlClientUnboundChecksListRequest({}, this.onSingleChecksListResponse);
  };

  @action
  onSingleChecksListResponse = (msg: protocol.IServerResponse) => {
    this.isSIngleChecksListLoading = false;
    this.singleChecksList =
      msg?.amlClientUnboundChecksListResponse?.checks?.map(_ => ({
        ..._,
        updatedAtNumber: _.updatedAt?.toNumber(),
        createdAtNumber: _.createdAt?.toNumber(),
        updatedAtFormatted: _.updatedAt?.notEquals(0)
          ? new Date(_.updatedAt?.multiply(1000).toNumber() ?? '').toLocaleString()
          : undefined,
        createdAtFormatted: new Date(_.createdAt?.multiply(1000).toNumber() ?? '').toLocaleString(),
      })) ?? [];
  };

  @action
  resetPage = () => {
    this.reportsList = [];
    this.tagsList = [];
    this.statistics = undefined;
    this.statisticsLoading = false;
    this.resetFilters();
    this.selectedAddresses = [];
    this.addTagForWithdrawIsLoading = false;
    this.singleCheckFilterAddress = '';
    this.singleCheckCurrency = undefined;
    this.singleCheckLoading = false;
    this.singleCheckError = undefined;
    this.singleChecksList = [];
    this.isSIngleChecksListLoading = false;
    this.amlPageTab = 'check';
    this.resetPaging();
  };

  @action
  resetFilters = (fromUi?: boolean) => {
    this.filterAddresses = [];
    this.filterAmountFrom = '';
    this.filterAmountTo = '';
    this.filterCurrencies = [];
    this.filterExcludingTags = [];
    this.filterIncludingTags = [];
    this.filterCheckFraction = '';
    this.filterExcludeCheckedAddresses = false;
    this.checksPreviewTotal = undefined;
    this.checkError = undefined;
    if (fromUi) {
      this.loadAddresses();
    }
  };

  @action
  resetPaging = () => {
    this.page = 0;
    this.totalPages = 0;
    this.sortedColumnAddresses = {
      name: 'riskScore',
      direction: SORT_DIRECTION.DESC,
    };
    this.totalCountAddresses = 0;
  };
}
