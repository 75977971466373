import { currencies, protocol } from '../../api/proto';
import { ISelectOption } from '../../components/controls/Select/Select';
import { round } from './round';

export enum FiatCurrencyApi {
  USD = 'USD',
  EUR = 'EUR',
  ARS = 'ARS',
  PEN = 'PEN',
  BOB = 'BOB',
  BRL = 'BRL',
  CLP = 'CLP',
}

export function getWlCurrencyByName(currency: FiatCurrencyApi) {
  switch (currency) {
    case FiatCurrencyApi.USD:
      return currencies.WLCurrency.WLC_USD;
    case FiatCurrencyApi.EUR:
      return currencies.WLCurrency.WLC_EUR;
    case FiatCurrencyApi.ARS:
      return currencies.WLCurrency.WLC_ARS;
    case FiatCurrencyApi.PEN:
      return currencies.WLCurrency.WLC_PEN;
    case FiatCurrencyApi.BOB:
      return currencies.WLCurrency.WLC_BOB;
    case FiatCurrencyApi.BRL:
      return currencies.WLCurrency.WLC_BRL;
    case FiatCurrencyApi.CLP:
      return currencies.WLCurrency.WLC_CLP;
    default:
      return undefined;
  }
}

export function convertMapWithCurrencyNamesAsKeysToWlCurrency(obj?: { [k: string]: any } | null) {
  const res: { [k: number]: any } | null | undefined = {};
  if (obj) {
    Object.keys(obj)?.forEach(currency => {
      const wlCurrency = getWlCurrencyByName(currency as FiatCurrencyApi);
      if (wlCurrency) {
        res[wlCurrency] = obj[currency];
      }
    });
  }
  return res;
}

export function isWlCurrency(currency: protocol.Currency | currencies.WLCurrency) {
  return !!Object.values(currencies.WLCurrency).includes(currency as number);
}

export function getCurrencyTitle(currency: number) {
  switch (currency as protocol.Currency) {
    case protocol.Currency.BTC_CUR:
      return 'Bitcoin';
    case protocol.Currency.ETH_CUR:
      return 'Etherium';
    case protocol.Currency.TRX_CUR:
      return 'TRON';
    case protocol.Currency.TRC20_USDT_CUR:
      return 'USDT (TRC20)';
    case protocol.Currency.ERC20_USDT_CUR:
      return 'USDT (ERC20)';
    default:
      return '';
  }
}

export function getCurrencyCode(currency: number) {
  switch (currency as protocol.Currency) {
    case protocol.Currency.BTC_CUR:
      return 'BTC';
    case protocol.Currency.ETH_CUR:
      return 'ETH';
    case protocol.Currency.TRX_CUR:
      return 'TRX';
    case protocol.Currency.TRC20_USDT_CUR:
      return 'USDT';
    case protocol.Currency.ERC20_USDT_CUR:
      return 'USDT';
    default:
      return '';
  }
}

export function getWlCurrencyCode(currency: currencies.WLCurrency, short?: boolean) {
  switch (currency) {
    case currencies.WLCurrency.WLC_USD:
      return short ? '$' : 'USD';
    case currencies.WLCurrency.WLC_EUR:
      return short ? '€' : 'EUR';
    default:
      return currencies.WLCurrency[currency].replace('WLC_', '');
  }
}

export function getFullCurrencyCode(currency: number) {
  switch (currency as protocol.Currency) {
    case protocol.Currency.BTC_CUR:
      return 'BTC';
    case protocol.Currency.ETH_CUR:
      return 'ETH';
    case protocol.Currency.TRX_CUR:
      return 'TRX';
    case protocol.Currency.TRC20_USDT_CUR:
      return 'USDT-TRC20';
    case protocol.Currency.ERC20_USDT_CUR:
      return 'USDT-ERC20';
    default:
      return '';
  }
}

export function getCurrencyIcon(currency: number) {
  switch (currency as protocol.Currency) {
    case protocol.Currency.BTC_CUR:
      return 'btc';
    case protocol.Currency.ETH_CUR:
      return 'eth';
    case protocol.Currency.TRX_CUR:
      return 'trx';
    case protocol.Currency.TRC20_USDT_CUR:
      return 'usdt-trc20';
    case protocol.Currency.ERC20_USDT_CUR:
      return 'usdt-erc20';
    default:
      return '';
  }
}

export function getCurrencyByCode(currency: string) {
  switch (currency) {
    case 'BTC':
      return protocol.Currency.BTC_CUR;
    case 'ETH':
      return protocol.Currency.ETH_CUR;
    case 'TRX':
      return protocol.Currency.TRX_CUR;
    case 'USDT-TRC20':
      return protocol.Currency.TRC20_USDT_CUR;
    case 'USDT-ERC20':
      return protocol.Currency.ERC20_USDT_CUR;
    default:
      return undefined;
  }
}

export function getCurrencyCodeByChain(chain?: protocol.Chain) {
  switch (chain) {
    case protocol.Chain.BTC_CHAIN:
      return 'BTC';
    case protocol.Chain.ETH_CHAIN:
      return 'ETH';
    case protocol.Chain.TRX_CHAIN:
      return 'TRX';
    default:
      return '';
  }
}

export function getCurrencyTitleByChain(chain?: protocol.Chain) {
  switch (chain) {
    case protocol.Chain.BTC_CHAIN:
      return 'BTC';
    case protocol.Chain.ETH_CHAIN:
      return 'ETH';
    case protocol.Chain.TRX_CHAIN:
      return 'TRON';
    default:
      return '';
  }
}

export function chainToCurrency(chain?: protocol.Chain) {
  switch (chain) {
    case protocol.Chain.BTC_CHAIN:
      return protocol.Currency.BTC_CUR;
    case protocol.Chain.ETH_CHAIN:
      return protocol.Currency.ETH_CUR;
    case protocol.Chain.TRX_CHAIN:
      return protocol.Currency.TRX_CUR;
    default:
      return protocol.Currency.BTC_CUR;
  }
}

export function currencyToChain(currency?: protocol.Currency) {
  switch (currency) {
    case protocol.Currency.BTC_CUR:
      return protocol.Chain.BTC_CHAIN;
    case protocol.Currency.ETH_CUR:
      return protocol.Chain.ETH_CHAIN;
    case protocol.Currency.TRX_CUR:
      return protocol.Chain.TRX_CHAIN;
    case protocol.Currency.ERC20_USDT_CUR:
      return protocol.Chain.ETH_CHAIN;
    case protocol.Currency.TRC20_USDT_CUR:
      return protocol.Chain.TRX_CHAIN;
    default:
      return protocol.Chain.BTC_CHAIN;
  }
}

function getDenomitation(currency: protocol.Currency) {
  switch (currency) {
    case protocol.Currency.BTC_CUR:
      return 8;
    case protocol.Currency.ETH_CUR:
      return 18;
    case protocol.Currency.TRX_CUR:
      return 6;
    case protocol.Currency.TRC20_USDT_CUR:
      return 6;
    case protocol.Currency.ERC20_USDT_CUR:
      return 6;
    default:
      return 0;
  }
}

function getWlCurrencyDenomination(currency: currencies.WLCurrency) {
  return 2;
}

export function getCurrencyCodeForConvert(currency: protocol.Currency) {
  switch (currency) {
    case protocol.Currency.BTC_CUR:
      return currencies.WLCurrency.WLC_BTC;
    case protocol.Currency.ETH_CUR:
      return currencies.WLCurrency.WLC_ETH;
    //case protocol.Currency.TRX_CUR: return currencies.WLCurrency.TRON;
    case protocol.Currency.TRC20_USDT_CUR:
      return currencies.WLCurrency.WLC_USDTTRC20;
    case protocol.Currency.ERC20_USDT_CUR:
      return currencies.WLCurrency.WLC_USDTERC20;
    default:
      return undefined;
  }
}

export function getCryptoCurrencyCodeByWlCurrency(currency: currencies.WLCurrency) {
  switch (currency) {
    case currencies.WLCurrency.WLC_BTC:
      return protocol.Currency.BTC_CUR;
    case currencies.WLCurrency.WLC_ETH:
      return protocol.Currency.ETH_CUR;
    //case protocol.Currency.TRX_CUR: return currencies.WLCurrency.TRON;
    case currencies.WLCurrency.WLC_USDTTRC20:
      return protocol.Currency.TRC20_USDT_CUR;
    case currencies.WLCurrency.WLC_USDTERC20:
      return protocol.Currency.ERC20_USDT_CUR;
    default:
      return undefined;
  }
}

export function getWlCurrencyByFiatCurrency(currency: protocol.FiatCurrency) {
  switch (currency) {
    case protocol.FiatCurrency.USD_FIAT_CUR:
      return currencies.WLCurrency.WLC_USD;
    case protocol.FiatCurrency.EUR_FIAT_CUR:
      return currencies.WLCurrency.WLC_EUR;
    case protocol.FiatCurrency.ARS_FIAT_CUR:
      return currencies.WLCurrency.WLC_ARS;
    case protocol.FiatCurrency.PEN_FIAT_CUR:
      return currencies.WLCurrency.WLC_PEN;
    case protocol.FiatCurrency.BRL_FIAT_CUR:
      return currencies.WLCurrency.WLC_BRL;
    case protocol.FiatCurrency.BOB_FIAT_CUR:
      return currencies.WLCurrency.WLC_BOB;
    case protocol.FiatCurrency.CLP_FIAT_CUR:
      return currencies.WLCurrency.WLC_CLP;
    default:
      return currencies.WLCurrency.WLC_USD;
  }
}

export function formatCurrency(
  value: number,
  currency: protocol.Currency,
  removeCurrencyCode?: boolean,
  roundSomeCurrenciesValues?: boolean,
): string {
  const currencyCode = getCurrencyCode(currency);
  if (value === 0) {
    return removeCurrencyCode ? '0' : `0 ${currencyCode}`;
  } else {
    let denom = getDenomitation(currency);
    const currencyValue = getCurrencyValue(value, denom, currency, currencyCode, roundSomeCurrenciesValues);

    return removeCurrencyCode ? currencyValue : `${currencyValue} ${currencyCode}`;
  }
}
function getCurrencyValue(
  value: number,
  denom: number,
  currency: protocol.Currency,
  currencyCode: string,
  roundSomeCurrenciesValues?: boolean,
) {
  const denomValue = denominateValue(value, denom, currencyCode);
  if (denomValue !== '0') {
    if (roundSomeCurrenciesValues) {
      if (
        currency === protocol.Currency.ERC20_USDT_CUR ||
        currency === protocol.Currency.TRC20_USDT_CUR ||
        currency === protocol.Currency.TRX_CUR
      ) {
        return String(round(Number(denomValue), 3));
      }
      if (currency === protocol.Currency.ETH_CUR) {
        return String(round(Number(denomValue), 8));
      }
    }
    return denomValue;
  }
  return denomValue;
}

export function formatWlCurrency(value: number, currency: currencies.WLCurrency, removeCurrencyCode?: boolean): string {
  const currencyCode = getWlCurrencyCode(currency);
  if (value === 0) {
    return removeCurrencyCode ? '0' : `0 ${currencyCode}`;
  } else {
    let denom = getWlCurrencyDenomination(currency);
    return removeCurrencyCode
      ? denominateValue(value, denom, currencyCode)
      : `${denominateValue(value, denom, currencyCode)} ${currencyCode}`;
  }
}

export function denominateValue(value: number, denom: number, currencyCode: string): string {
  if (!value || !currencyCode) {
    return '0';
  }

  let strValue = value!.toString();

  if (strValue === '0') {
    return '0';
  }

  const isNegative = strValue[0] === '-';

  if (isNegative) {
    strValue = strValue.substring(1);
  }

  if (strValue.length <= denom) {
    const zerosCount = denom - strValue.length + 1;
    for (let i = 0; i < zerosCount; i++) {
      strValue = '0' + strValue;
    }
  }
  const leadingSign = isNegative ? '-' : '';

  return leadingSign + strValue.substr(0, strValue.length - denom) + '.' + strValue.substr(strValue.length - denom);
}

export function convertAmountToApi(
  value: number | string,
  currency: protocol.Currency | currencies.WLCurrency,
): string | null {
  const denom = isWlCurrency(currency)
    ? getWlCurrencyDenomination(currency as currencies.WLCurrency)
    : getDenomitation(currency as protocol.Currency);
  let strValue = String(value);

  if (strValue === '0') {
    return '0';
  }

  if (strValue.indexOf('.') >= 0) {
    let points = strValue.length - strValue.indexOf('.') - 1;
    if (points <= denom) {
      strValue += Array(denom - points)
        .fill('0')
        .join('');
    } else {
      strValue = strValue.substr(0, strValue.indexOf('.') + denom + 1);
    }
    strValue = strValue.replace('.', '');
  } else {
    strValue += Array(denom).fill('0').join('');
  }
  return strValue.replace(/^0+/, '');
}

export function getShortAddress(address: string): string {
  if (address.length > 10) {
    return address.substring(0, 4) + '***' + address.substring(address.length - 4, address.length);
  }
  return address;
}

export const currenciesOptionsForConvert: ISelectOption[] = [
  { value: currencies.WLCurrency.WLC_USD, label: 'USD' },
  { value: currencies.WLCurrency.WLC_EUR, label: 'EUR' },
  { value: currencies.WLCurrency.WLC_ARS, label: 'ARS' },
  { value: currencies.WLCurrency.WLC_PEN, label: 'PEN' },
  { value: currencies.WLCurrency.WLC_BOB, label: 'BOB' },
  { value: currencies.WLCurrency.WLC_BRL, label: 'BRL' },
  { value: currencies.WLCurrency.WLC_CLP, label: 'CLP' },
];

export const currenciesOptions: ISelectOption[] = [
  {
    value: protocol.Currency.BTC_CUR,
    label: 'BTC',
  },
  {
    value: protocol.Currency.ETH_CUR,
    label: 'ETH',
  },
  {
    value: protocol.Currency.TRX_CUR,
    label: 'TRX',
  },
  {
    value: protocol.Currency.ERC20_USDT_CUR,
    label: 'USDT ERC20',
  },
  {
    value: protocol.Currency.TRC20_USDT_CUR,
    label: 'USDT TRC20',
  },
];

export const fiatCurrenciesOptions: ISelectOption[] = [
  { value: protocol.FiatCurrency.USD_FIAT_CUR, label: 'USD' },
  { value: protocol.FiatCurrency.EUR_FIAT_CUR, label: 'EUR' },
  { value: protocol.FiatCurrency.ARS_FIAT_CUR, label: 'ARS' },
  { value: protocol.FiatCurrency.PEN_FIAT_CUR, label: 'PEN' },
  { value: protocol.FiatCurrency.BOB_FIAT_CUR, label: 'BOB' },
  { value: protocol.FiatCurrency.BRL_FIAT_CUR, label: 'BRL' },
  { value: protocol.FiatCurrency.CLP_FIAT_CUR, label: 'CLP' },
];
