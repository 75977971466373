
import './Select.scss';

export interface ISelectOption {
  label: string;
  value: string | number;
}

interface ISelectProps {
  options: ISelectOption[];
  value: string | number;
  onChange: (value: string | number) => void;
}

export const Select = (props: ISelectProps) => {
  return (
    <div className="select">
      <select value={props.value} onChange={(e) => props.onChange(e.currentTarget.value)}>
        {
          props.options.map((option) => {
            return (
              <option key={option.value} value={option.value}>{option.label}</option>
            )
          })
        }
      </select>
    </div>
  )
}