import { protocol } from '../../../../api/proto';
import { getCurrencyIcon, getCurrencyTitle } from '../../../../state/utils/Money';
import { Icon } from '../../../Icon/Icon';

export const CurrencyCell = ({ row }: { row: any }) => {
  if (!row['formattedCurrency'] && !row['currency']) return <td>-</td>;
  return (
    <td className="column-cell">
      <div className="currency">
        {row['formattedCurrency'] ?? getCurrencyTitle(row['currency'])}{' '}
        <Icon
          iconName={row.currencyIconName ?? getCurrencyIcon(row['currency'])}
          heightInPx={16}
          widthInPx={
            Number(row.currency) === protocol.Currency.ERC20_USDT_CUR ||
            Number(row.currency) === protocol.Currency.TRC20_USDT_CUR
              ? 32
              : 16
          }
        />
      </div>
      {row.network ? <div>({row.network})</div> : null}
    </td>
  );
};
