import { observer } from 'mobx-react';
import { useState } from '../../state/AppState';
import { TOTAL_PERIODS } from '../../state/MainPageInfoStore';
import { PeriodFilters } from '../PeriodFilters/PeriodFilters';
import { TotalItem, TotalTypes } from './TotalItem';
import cn from 'classnames';
import './TotalsBlock.scss';
import { formatSecondsToTimeHHmmss } from '../../state/utils/DateTime';

const formatUpdates = (updatesValue: string) => {
  return (updatesValue[0] === '-' ? '' : '+') + updatesValue;
};

export const TotalsBlock = observer((props: { minimizeView: boolean }) => {
  const state = useState();
  const { mainPageInfo } = state;
  const {
    totalTransactionsLength,
    totalIncomeTransactionsFormatted,
    totalOutcomeTransactionsFormatted,
    totalPeriod,
    changeTotalPeriod,
    totalDeltaTransactions,
    totalIncomeTransactionsLength,
    totalOutcomeTransactionsLength,
    customDateRange,
    setCustomDateRange,
    lastCheckTimeFormatted,
    lastCheckTimeFormattedType2,
    totalTransactionsLengthUpdates,
    totalIncomeTransactionsLengthUpdates,
    totalOutcomeTransactionsLengthUpdates,
    totalIncomeTransactionsUpdates,
    totalOutcomeTransactionsUpdates,
    totalDeltasTransactionsUpdates,
  } = mainPageInfo;
  const lastCheckTime = lastCheckTimeFormattedType2 ? `Updated ${lastCheckTimeFormattedType2} ago` : '';
  return (
    <div className={cn('TotalBlock', { 'minimize-view': props.minimizeView })}>
      <div className="head-block">
        <div className="title">Overview</div>
        <PeriodFilters
          customDateRange={customDateRange}
          setCustomDateRange={setCustomDateRange}
          periods={TOTAL_PERIODS as unknown as string[]}
          value={totalPeriod!}
          onChange={changeTotalPeriod}
        />
      </div>
      <div className="total-list">
        <TotalItem
          income={String(totalIncomeTransactionsLength)}
          outcome={String(totalOutcomeTransactionsLength)}
          title="TOTAL TRANSACTIONS"
          type={TotalTypes.DEEP_BLUE}
          value={String(totalTransactionsLength)}
          updatesValue={
            totalTransactionsLengthUpdates ? `${formatUpdates(String(totalTransactionsLengthUpdates))}` : undefined
          }
          updatesValueIncome={
            totalIncomeTransactionsLengthUpdates
              ? `${formatUpdates(String(totalIncomeTransactionsLengthUpdates))}`
              : undefined
          }
          updatesValueOutcome={
            totalOutcomeTransactionsLengthUpdates
              ? `${formatUpdates(String(totalOutcomeTransactionsLengthUpdates))}`
              : undefined
          }
          updatesTimeBottom={
            totalTransactionsLengthUpdates ||
            totalIncomeTransactionsLengthUpdates ||
            totalOutcomeTransactionsLengthUpdates
              ? lastCheckTime
                ? lastCheckTime
                : undefined
              : undefined
          }
        />
        <TotalItem
          title="TOTAL INCOME"
          type={TotalTypes.BLUE}
          value={totalIncomeTransactionsFormatted}
          updatesValue={totalIncomeTransactionsUpdates ? `${formatUpdates(totalIncomeTransactionsUpdates)}` : undefined}
          updatesTimeBottom={totalIncomeTransactionsUpdates ? lastCheckTime : undefined}
        />
        <TotalItem
          title="TOTAL OUTCOME"
          type={TotalTypes.RED}
          value={totalOutcomeTransactionsFormatted}
          updatesValue={
            totalOutcomeTransactionsUpdates ? `${formatUpdates(totalOutcomeTransactionsUpdates)}` : undefined
          }
          updatesTimeBottom={totalOutcomeTransactionsUpdates ? lastCheckTime : undefined}
        />
        <TotalItem
          title="TOTAL DELTA"
          type={TotalTypes.GREEN}
          value={totalDeltaTransactions}
          updatesValue={totalDeltasTransactionsUpdates ? `${formatUpdates(totalDeltasTransactionsUpdates)}` : undefined}
          updatesTimeBottom={totalDeltasTransactionsUpdates ? lastCheckTime : undefined}
        />
      </div>
    </div>
  );
});
