import ReactPaginate from 'react-paginate';

interface TablePaginationProps {
  onPageChange: (page: number) => void;
  pageCount: number;
  page: number;
}

export const TablePagination = ({ onPageChange, pageCount, page }: TablePaginationProps) => {
  return (
    <div className="TablePagination">
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <span>
            <span className="next-button">next </span>
            {'>'}
          </span>
        }
        onPageChange={(selectedItem: { selected: number }) => onPageChange(selectedItem.selected)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={
          <span>
            {'<'}
            <span className="previous-button"> previous</span>
          </span>
        }
        renderOnZeroPageCount={undefined}
        forcePage={page}
      />
    </div>
  );
};
