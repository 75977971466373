import { observer } from 'mobx-react';
import { useState } from '../../../state/AppState';
import { IAddressGroup } from '../../../state/SystemAddressesStore';
import { chainToCurrency, getCurrencyIcon, getCurrencyTitle } from '../../../state/utils/Money';
import { Button, ButtonSize, ButtonType } from '../../controls/Button/Button';
import { Icon } from '../../Icon/Icon';
import Table, { TABLE_STYLE } from '../../Table/Table';
import './SystemAddressesTable.scss';
import copy from 'copy-to-clipboard';
import { CopyButton } from '../../controls/CopyButton/CopyButton';

const ActionsCell = observer(({ row }: { row: IAddressGroup }) => {
  const state = useState();
  const { systemAddressesStore } = state;
  return (
    <td>
      <div className="SystemAddressesTable__buttons">
        <Button
          onClick={() => systemAddressesStore.generateNewAddress(row)}
          label={'Regenerate'}
          isLoading={systemAddressesStore.regenerateAddressId === row.id}
          type={ButtonType.SECONDARY_TYPE_2}
          size={ButtonSize.SMALL}
        />
      </div>
    </td>
  );
});

const systemAddressesTableColumns = [
  {
    title: 'Address',
    key: 'lastSystemAddress',
    sortEnabled: false,
    renderCustomCellComponent: (row: IAddressGroup) => (
      <td>
        <div className="SystemAddressesTable__address">
          <div>{row.lastSystemAddress?.address}</div>
          {row.lastSystemAddress?.address ? (
            <CopyButton
              sizeInPx={14}
              contentForCopy={row.lastSystemAddress?.address || ''}
              notificationText="Address copied"
            />
          ) : null}
        </div>
      </td>
    ),
  },
  {
    title: 'Chain',
    key: 'formattedChain',
    sortKey: 'chain',
    sortEnabled: true,
    renderCustomCellComponent: (row: IAddressGroup) => {
      const currency = chainToCurrency(row.chain ?? undefined);
      const currencyIconName = getCurrencyIcon(currency);
      return (
        <td className="column-cell">
          <div className="currency">
            {getCurrencyTitle(currency)} <Icon iconName={currencyIconName} heightInPx={16} widthInPx={16} />
          </div>
        </td>
      );
    },
  },
  {
    title: 'Tag',
    key: 'tag',
    sortKey: 'tag',
    sortEnabled: true,
  },
  {
    title: 'Balance',
    key: 'systemAddressesBalanceFormatted',
    sortKey: 'systemAddressesBalance',
    sortEnabled: true,
  },
  {
    title: 'Token addresses balance',
    key: 'tokenAddressesBalanceFormatted',
    sortKey: 'tokenAddressesBalance',
    sortEnabled: true,
  },
  {
    title: 'Qty of adresses with same tag',
    key: 'tokenAddressesCount',
    sortKey: 'tokenAddressesCount',
    sortEnabled: true,
  },
  {
    title: '',
    key: '',
    sortEnabled: false,
    renderCustomCellComponent: (row: IAddressGroup) => <ActionsCell row={row} />,
  },
];

export const SystemAddressesTable = observer(() => {
  const state = useState();
  const { systemAddressesStore } = state;
  return (
    <div className="ContentBlock SystemAddressesTable">
      <div className="ContentBlockTitle">
        {systemAddressesStore.addressGroupsFiltered?.length
          ? `${systemAddressesStore.addressGroupsFiltered?.length} addresses`
          : 'No addresses found'}
      </div>
      <Table
        columns={systemAddressesTableColumns}
        contentIsLoading={systemAddressesStore.isAddressesLoading}
        data={systemAddressesStore.addressGroupsFiltered || []}
        tableStyle={TABLE_STYLE.ROUNDED}
        withoutFilters
      />
    </div>
  );
});
