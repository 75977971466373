import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../../state/utils/DateTime';
import './PeriodFilters.scss';
import cn from 'classnames';

interface PeriodFiltersProps {
  periods: string[];
  value: string;
  onChange: (period: string) => void;
  customDateRange?: [Date | null, Date | null];
  setCustomDateRange?: (range: Date | [Date | null, Date | null] | null) => void;
  type?: 'default' | 'big-view';
}

export const PeriodFilters = (props: PeriodFiltersProps) => {
  const [datePickerIsOpen, setDatePickerIsOpen] = React.useState(false);
  const [startDate, endDate] = props.customDateRange || [];
  const ref = React.useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setDatePickerIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div className={cn('PeriodFilters', props.type)}>
      <div className="PeriodFilters-block">
        {datePickerIsOpen ? (
          <div className="datepicker-container" ref={ref}>
            <ReactDatePicker
              inline
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={update => {
                if (props.setCustomDateRange) props.setCustomDateRange(update);
              }}
            />
          </div>
        ) : null}
        {props.periods.map(_ => {
          if (_ === 'Custom period') {
            return (
              <div
                key={_}
                className={`PeriodFilters-date ${_ === props.value ? 'PeriodFilters-date--active' : ''}`}
                onClick={() => {
                  setDatePickerIsOpen(true);
                }}
              >
                {_}
                {startDate && endDate ? (
                  <span>
                    {' '}
                    ({formatDate(startDate as Date)} - {formatDate(endDate as Date)})
                  </span>
                ) : null}
              </div>
            );
          }
          return (
            <div
              key={_}
              className={`PeriodFilters-date ${_ === props.value ? 'PeriodFilters-date--active' : ''}`}
              onClick={() => {
                props.onChange(_);
              }}
            >
              {_}
            </div>
          );
        })}
      </div>
    </div>
  );
};
