import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import cn from 'classnames';
import './Modals.scss';

interface ModalContainerProps {
  type?: 'default' | 'rounded';
}

export const ModalContainer = observer((props: PropsWithChildren<ModalContainerProps>) => {
  return (
    <div className="modal-container">
      <div className="modal-background" />
      <div className={cn('modal', props.type)}>{props.children}</div>
    </div>
  );
});
