import { computed, makeObservable } from 'mobx';
import { protocol } from '../api/proto';
import { AppState } from './AppState';
import { getCountryCodeByName } from './utils/getCountryCodeByName';

export class ActivityRecord extends protocol.ClientRequestLogsResponse.Log {
  constructor(private appState: AppState, props: protocol.ClientRequestLogsResponse.ILog) {
    super(props);
    Object.assign(this, props);
    makeObservable(this);
  }

  @computed
  get createdAtNumber() {
    return this.createdAt?.multiply(1000)?.toNumber();
  }

  @computed
  get formattedDate(): string {
    return new Date(this.createdAt?.multiply(1000).toNumber() ?? '').toLocaleString();
  }

  @computed
  get countryCode(): string | undefined {
    return this.countryName ? getCountryCodeByName(this.countryName) : undefined;
  }
}
