import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SystemAddressesFilters } from '../../components/systemAddresses/SystemAddressesFilters/SystemAddressesFilters';
import { SystemAddressesTable } from '../../components/systemAddresses/SystemAddressesTable/SystemAddressesTable';
import { useMountEffect } from '../../hooks/useMountEffect';
import { useState } from '../../state/AppState';

export const SystemAddressesPage = observer(() => {
  const state = useState();
  const params = useParams();
  const { systemAddressesStore } = state;

  useMountEffect(() => {
    if (params.clientId && !state.clientId) {
      state.setClientId(params.clientId);
    }
    systemAddressesStore.fetchAddresses();
  });
  useEffect(() => {
    return () => {
      systemAddressesStore.resetPage();
    };
  }, []);
  return (
    <div className="SystemAddressesPage PageContainer">
      <div className="PageTitle">System addresses</div>
      <SystemAddressesFilters />
      <SystemAddressesTable />
    </div>
  );
});
