import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { protocol } from '../../api/proto';
import { useState } from '../../state/AppState';
import { IBalanceTotalInfo, IBalanceTotalInfoRaw } from '../../state/MainPageInfoStore';
import { ProtectedRoutes } from '../../state/Routes';
import {
  formatCurrency,
  formatWlCurrency,
  getCurrencyCode,
  getCurrencyIcon,
  getCurrencyTitle,
  getFullCurrencyCode,
} from '../../state/utils/Money';
import { Button, ButtonType } from '../controls/Button/Button';
import { Icon } from '../Icon/Icon';
import cn from 'classnames';

export const TotalBalancesItem = observer(
  (props: {
    balancesTotal: { [key: number]: IBalanceTotalInfo };
    currency: string;
    balancesUpdates?: { [key: number]: IBalanceTotalInfoRaw };
  }) => {
    const state = useState();
    const navigate = useNavigate();
    const { balancesTotal, currency, balancesUpdates } = props;
    const currencyCode = getCurrencyCode(Number(currency));
    const currencyTitle = getCurrencyTitle(Number(currency));
    const iconName = getCurrencyIcon(Number(currency));
    const amount = balancesTotal[Number(currency)]?.amount;
    const convertedAmount = balancesTotal[Number(currency)]?.amountFiatFormatted;
    const formattedAmount = formatCurrency(amount, Number(currency), true, true);
    const isWithdrawAvailable = !!state.availableRoutes.find(_ => _.path === ProtectedRoutes.WITHDRWAL);

    const updatesAmount = balancesUpdates ? balancesUpdates[Number(currency)]?.amount : undefined;
    const formattedUpdateAmount = updatesAmount
      ? formatCurrency(updatesAmount, Number(currency), false, true)
      : undefined;

    if (!amount) return null;
    return (
      <div key={currency} className={`balance-item ${!amount ? 'disabled' : ''}`}>
        <div className="">
          <div className="balance-item__top">
            <div className="currency-title">{currencyTitle}</div>
            <Icon
              widthInPx={
                Number(currency) === protocol.Currency.ERC20_USDT_CUR ||
                Number(currency) === protocol.Currency.TRC20_USDT_CUR
                  ? 32
                  : 16
              }
              heightInPx={16}
              iconName={iconName}
            />
          </div>
          <div className="amounts-container">
            <div className="balance-item__amount">
              <span className="amount">{formattedAmount}</span>
              <span className="currency">{currencyCode}</span>
            </div>
            {formattedUpdateAmount ? (
              <div className="balance-item__updates">
                <div className={cn('balance-item__updates__amount', { isNegative: formattedUpdateAmount[0] === '-' })}>
                  {formattedUpdateAmount[0] === '-' ? '' : '+'}
                  {formattedUpdateAmount}
                </div>
                ({state.mainPageInfo.lastCheckTimeFormatted}) ago
              </div>
            ) : null}
            <div className="balance-item__usd-eur-amounts">
              <span>{convertedAmount || `${formatWlCurrency(0, state.selectedCurrencyForConvert)}`}</span>
            </div>
          </div>
        </div>
        {isWithdrawAvailable ? (
          <div className="withdrawal-button-container">
            <Button
              label="Withdrawal"
              onClick={() =>
                navigate(`/dashboard/user/${state.clientId}/withdrawal/${getFullCurrencyCode(Number(currency))}`)
              }
              disabled={!amount}
              type={ButtonType.PRIMARY}
            />
          </div>
        ) : null}
      </div>
    );
  },
);
