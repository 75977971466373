import Long from 'long';
import { makeObservable, observable, action, computed } from 'mobx';
import moment from 'moment';
import { currencies, protocol } from '../api/proto';
import { FilterPeriod, ISortedColumn, ITotalFromApi, SORT_DIRECTION } from '../components/Table/Table';
import { AppState } from './AppState';
import { IOperation, Operation } from './Operation';
import { PayoutRequestRecord } from './PayoutRequestRecord';
import { formatSecondsToTime, formatSecondsToTimeHHmmss } from './utils/DateTime';
import {
  getCurrencyCodeForConvert,
  getCryptoCurrencyCodeByWlCurrency,
  formatWlCurrency,
  chainToCurrency,
  currencyToChain,
  convertMapWithCurrencyNamesAsKeysToWlCurrency,
} from './utils/Money';
import { processOperations, processPayoutRequestRecords } from './utils/OperationsUtils';
import { ModalType } from './ModalsStore';
import { NotificationType } from './NotificationsState';
import { formatServerErrors } from './utils/format';

export const OPERATION_TABS = ['Refills', 'Withdrawal', 'Pending', 'Payout requests'] as const;
export type OperationTab = (typeof OPERATION_TABS)[number];
export const TOTAL_PERIODS = ['All', 'Today', 'Yesterday', 'Custom period'] as const;
export type TotalPeriod = (typeof TOTAL_PERIODS)[number];

export interface IBalanceTotalItem {
  currency: protocol.Currency;
  balance: string;
}

export interface IBalanceTotalInfoRaw {
  amount: number;
  balanceFiat?: { [k: number]: string } | null;
}

export interface IBalanceTotalInfo {
  amount: number;
  amountFiat: number;
  amountFiatFormatted?: string;
}

export interface IMainAccount extends protocol.MainAccountsResponse.IAccount {
  convertedAmount?: number;
  convertedAmountFormatted?: string;
}

const LAST_CHECK_UPDATES_TIME_STORAGE_NAME = 'main_page_last_check_updates_time';
const CHECK_UPDATES_TIMEOUT = 60000;

export class MainPageInfoStore {
  private appState: AppState;

  @observable balancesTotalRaw: { [key: number]: IBalanceTotalInfoRaw } = {};
  @observable balancesUpdates: { [key: number]: IBalanceTotalInfoRaw } = {};
  @observable mainAccounts?: IMainAccount[];
  @observable operationType: OperationTab = 'Refills';
  @observable totalPeriod?: TotalPeriod = 'Today';
  operations = observable.array<Operation>([]);
  @observable transactionsUpdates: protocol.ITxRecord[] = [];
  @observable operationsRaw: protocol.ITxRecord[] = [];
  @observable customDateRange?: [Date | null, Date | null];
  processingOperations = observable.array<PayoutRequestRecord>([]);
  @observable processingOperationsLoading?: boolean;
  @observable transactionsStats?: protocol.ITransactionsStatsResponse;
  @observable transactionsStatsUpdates?: protocol.ITransactionsStatsResponse;
  @observable isTransactionsLoading: boolean = false;
  @observable balancesLastUpdateStamp?: number;

  @observable page: number = 0;
  @observable totalPages: number = 0;
  @observable sortedColumn?: ISortedColumn = {
    name: 'updatedAtNumber',
    direction: SORT_DIRECTION.DESC,
  };
  @observable periodTransaction?: FilterPeriod = 'Today';
  @observable customDateRangeTransactions?: [Date | null, Date | null];

  @observable transactionsTableTotals?: {
    totalAmounts: { [k: number]: string } | null;
    totalFees: { [k: number]: string } | null;
  };

  @observable balancesLoading: boolean = false;
  @observable isTransactionsStatsLoading: boolean = false;
  @observable hasChangesLoading: boolean = false;

  @observable lastCheckTime?: number;
  @observable prevCheckTime?: number;
  @observable updateBlockVisible?: boolean;
  @observable timeFromLastCheck?: number;
  @observable timeFromPrevCheckFormatted?: string;

  updatesTimeout?: number = undefined;
  interval?: number = undefined;

  pageScrollXPosition?: { x: number; y: number };
  tableScrollPosition?: { x: number; y: number };

  @observable tableRef?: React.RefObject<HTMLDivElement>;

  @observable processingTransactionIdForDelete?: number;
  @observable processingTransactionCancellationLoading?: boolean;

  @observable filterCurrency?: protocol.Currency = protocol.Currency.UNK_CUR;

  @action
  onChangeFilterCurrency = (val: number | string) => {
    this.filterCurrency = val as number;
    this.fetchTransactions();
  };

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
    this.interval = window.setInterval(this.onTick, 1000);
  }

  @action
  init = () => {
    if (!this.appState.txInfoStore.operations?.length) {
      this.setLastCheckTimeFromLocalStorage();
      this.fetchAll();
    } else {
      window.scrollTo(this.pageScrollXPosition?.x ?? 0, this.pageScrollXPosition?.y ?? 0);
      setTimeout(() => {
        if (this.tableRef?.current) {
          this.tableRef.current.scrollTo(this.tableScrollPosition?.x ?? 0, this.tableScrollPosition?.y ?? 0);
        }
      }, 0);
    }
  };

  @action
  fetchAll = () => {
    this.fetchBalances(true);
    this.fetchTransactions(true);
    this.fetchStats(true);
    this.checkUpdates(true);
  };

  @computed
  get isLoading() {
    return this.balancesLoading || this.isTransactionsLoading || this.isTransactionsStatsLoading;
  }

  @computed
  get balancesTotal(): { [key: number]: IBalanceTotalInfo } {
    let balances: { [key: number]: IBalanceTotalInfo } = {};
    Object.keys(this.balancesTotalRaw).forEach((currency: string) => {
      const balanceRaw = this.balancesTotalRaw[Number(currency)];
      const fiatAmount =
        balanceRaw.balanceFiat && balanceRaw.balanceFiat[this.appState.selectedCurrencyForConvert]
          ? balanceRaw.balanceFiat[this.appState.selectedCurrencyForConvert]
          : 0;
      balances[Number(currency)] = {
        amount: balanceRaw.amount,
        amountFiat: Number(fiatAmount),
        amountFiatFormatted: formatWlCurrency(Number(fiatAmount), this.appState.selectedCurrencyForConvert),
      };
    });
    return balances;
  }

  @action
  changePage = (page: number) => {
    this.page = page;
    this.fetchTransactions();
  };

  @action
  changeTotalPeriod = (period: string) => {
    this.totalPeriod = period as TotalPeriod;
    this.customDateRange = undefined;
    this.fetchStats();
  };

  @action
  setCustomDateRange = (range: Date | [Date | null, Date | null] | null) => {
    this.customDateRange = (range as [Date | null, Date | null]) || undefined;
    this.totalPeriod = 'Custom period';
    this.fetchStats();
  };

  @action
  onSortColumnClick = (name: string) => {
    if (this.sortedColumn?.name && this.sortedColumn.name === name) {
      if (this.sortedColumn.direction === SORT_DIRECTION.ASC) {
        this.sortedColumn = undefined;
      } else if (this.sortedColumn.direction === SORT_DIRECTION.DESC) {
        this.sortedColumn.direction = SORT_DIRECTION.ASC;
      } else {
        this.sortedColumn.direction = SORT_DIRECTION.DESC;
      }
    } else {
      this.sortedColumn = {
        name,
        direction: SORT_DIRECTION.DESC,
      };
    }
    this.page = 0;
    this.fetchTransactions();
  };

  @computed
  get sortBy() {
    if (this.sortedColumn?.name) {
      switch (this.sortedColumn.name) {
        case 'updatedAtNumber':
          return protocol.SortBy.DATE_SORT_BY;
        case 'formattedAmount':
          return protocol.SortBy.AMOUNT_SORT_BY;
        case 'formattedCurrency':
          return protocol.SortBy.CURRENCY_SORT_BY;
        case 'id':
          return protocol.SortBy.ID_SORT_BY;
        case 'tagsList':
          return protocol.SortBy.TAGS_SORT_BY;
      }
    }
    return undefined;
  }

  @computed
  get balancesTotalIsBalanceExists() {
    const balancesKeysWithAmount = Object.keys(this.balancesTotal).filter((cur: string) => {
      return this.balancesTotal[Number(cur)]?.amount > 0;
    });
    return !!balancesKeysWithAmount?.length;
  }

  @action
  onChangePeriod = (period: FilterPeriod) => {
    if (period !== 'Custom period') {
      this.customDateRangeTransactions = undefined;
    }
    if (this.periodTransaction === period) {
      this.periodTransaction = 'All';
    } else {
      this.periodTransaction = period;
    }
    this.fetchTransactions();
    this.page = 0;
  };

  @action
  setCustomDateRangeTransactions = (range: Date | [Date | null, Date | null] | null) => {
    this.customDateRangeTransactions = (range as [Date | null, Date | null]) || undefined;
    this.periodTransaction = 'Custom period';
    this.fetchTransactions();
    this.page = 0;
  };

  @computed
  get fromToNumPeriodFilter() {
    let from = 0;
    let to = 0;
    if (this.periodTransaction === 'Today') {
      from = moment().startOf('day').unix();
    } else if (this.periodTransaction === 'Yesterday') {
      from = moment().subtract('1', 'days').startOf('day').unix();
      to = moment().startOf('day').unix();
    }
    return { from, to };
  }

  @computed
  get transactionsPeriod() {
    if (this.periodTransaction === 'Today' || this.periodTransaction === 'Yesterday') {
      return this.fromToNumPeriodFilter;
    } else if (this.periodTransaction === 'Custom period' && this.customDateRangeTransactions?.length) {
      const [start, end] = this.customDateRangeTransactions;
      if (start && end) {
        return {
          from: start.getTime() / 1000,
          to: (end.getTime() + 86400000) / 1000,
        };
      }
    }
    return undefined;
  }

  @computed
  get totalTransactionsLength() {
    return this.transactionsStats?.totalCount?.toNumber() || 0;
  }

  @computed
  get totalTransactionsLengthUpdates() {
    return this.transactionsStatsUpdates?.totalCount
      ? this.transactionsStatsUpdates?.totalCount?.toNumber()
      : undefined;
  }

  @computed
  get totalIncomeTransactionsLength() {
    return this.transactionsStats?.totalInCount?.toNumber() || 0;
  }

  @computed
  get totalIncomeTransactionsLengthUpdates() {
    return this.transactionsStatsUpdates?.totalInCount
      ? this.transactionsStatsUpdates?.totalInCount?.toNumber()
      : undefined;
  }

  @computed
  get totalOutcomeTransactionsLength() {
    return this.transactionsStats?.totalOutCount?.toNumber() || 0;
  }

  @computed
  get totalOutcomeTransactionsLengthUpdates() {
    return this.transactionsStatsUpdates?.totalOutCount
      ? this.transactionsStatsUpdates?.totalOutCount?.toNumber()
      : undefined;
  }

  @computed
  get totalIncomeTransactions() {
    const totalIncomes = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.transactionsStats?.totalIncomes);
    return Number(totalIncomes[this.appState.selectedCurrencyForConvert] ?? 0);
  }

  @computed
  get totalOutcomeTransactions() {
    const totalOutcomes = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.transactionsStats?.totalOutcomes);
    return Number(totalOutcomes[this.appState.selectedCurrencyForConvert] ?? 0);
  }

  @computed
  get totalIncomeTransactionsFormatted() {
    return formatWlCurrency(this.totalIncomeTransactions, this.appState.selectedCurrencyForConvert);
  }

  @computed
  get totalOutcomeTransactionsFormatted() {
    return formatWlCurrency(this.totalOutcomeTransactions, this.appState.selectedCurrencyForConvert);
  }

  @computed
  get totalDeltaTransactions() {
    const totalDeltas = convertMapWithCurrencyNamesAsKeysToWlCurrency(this.transactionsStats?.totalDeltas);
    return formatWlCurrency(
      Number(totalDeltas[this.appState.selectedCurrencyForConvert] ?? 0),
      this.appState.selectedCurrencyForConvert,
    );
  }

  @computed
  get totalIncomeTransactionsUpdates() {
    const totalIncomesUpdates = convertMapWithCurrencyNamesAsKeysToWlCurrency(
      this.transactionsStatsUpdates?.totalIncomes,
    );
    return totalIncomesUpdates[this.appState.selectedCurrencyForConvert]
      ? formatWlCurrency(
          Number(totalIncomesUpdates[this.appState.selectedCurrencyForConvert] ?? 0),
          this.appState.selectedCurrencyForConvert,
        )
      : undefined;
  }

  @computed
  get totalOutcomeTransactionsUpdates() {
    const totalOutcomesUpdates = convertMapWithCurrencyNamesAsKeysToWlCurrency(
      this.transactionsStatsUpdates?.totalOutcomes,
    );
    return totalOutcomesUpdates[this.appState.selectedCurrencyForConvert]
      ? formatWlCurrency(
          Number(totalOutcomesUpdates[this.appState.selectedCurrencyForConvert] ?? 0),
          this.appState.selectedCurrencyForConvert,
        )
      : undefined;
  }

  @computed
  get totalDeltasTransactionsUpdates() {
    const totalDeltasUpdates = convertMapWithCurrencyNamesAsKeysToWlCurrency(
      this.transactionsStatsUpdates?.totalDeltas,
    );
    return totalDeltasUpdates[this.appState.selectedCurrencyForConvert]
      ? formatWlCurrency(
          Number(totalDeltasUpdates[this.appState.selectedCurrencyForConvert] ?? 0),
          this.appState.selectedCurrencyForConvert,
        )
      : undefined;
  }

  @computed
  get fromToNumTotalFilter() {
    let from = 0;
    let to = 0;
    if (this.totalPeriod === 'Today') {
      from = moment().startOf('day').unix();
    } else if (this.totalPeriod === 'Yesterday') {
      from = moment().subtract('1', 'days').startOf('day').unix();
      to = moment().startOf('day').unix();
    }
    return { from, to };
  }

  @computed
  get periodTotal() {
    if (this.totalPeriod === 'Today' || this.totalPeriod === 'Yesterday') {
      return this.fromToNumTotalFilter;
    } else if (this.totalPeriod === 'Custom period' && this.customDateRange?.length) {
      const [start, end] = this.customDateRange;
      if (start && end) {
        return {
          from: start.getTime() / 1000,
          to: (end.getTime() + 86400000) / 1000,
        };
      }
    }
    return undefined;
  }

  @computed
  get filteredProcessingOperations(): PayoutRequestRecord[] {
    return this.processingOperations.filter(
      _ =>
        _.status === protocol.PayoutRequestStatus.PENDING_PAYOUT_REQUEST_STATUS ||
        _.status === protocol.PayoutRequestStatus.PROCESSING_PAYOUT_REQUEST_STATUS,
    );
  }

  @computed
  get tableTotals(): ITotalFromApi[] {
    const totalAmount =
      this.transactionsTableTotals?.totalAmounts &&
      this.transactionsTableTotals?.totalAmounts[this.appState.selectedCurrencyForConvert]
        ? this.transactionsTableTotals?.totalAmounts[this.appState.selectedCurrencyForConvert]
        : 0;
    const totalFee =
      this.transactionsTableTotals?.totalFees &&
      this.transactionsTableTotals?.totalFees[this.appState.selectedCurrencyForConvert]
        ? this.transactionsTableTotals?.totalFees[this.appState.selectedCurrencyForConvert]
        : 0;
    return [
      {
        key: 'formattedAmount',
        value: formatWlCurrency(Number(totalAmount), this.appState.selectedCurrencyForConvert),
      },
      {
        key: 'formattedFee',
        value: formatWlCurrency(Number(totalFee), this.appState.selectedCurrencyForConvert),
      },
    ];
  }

  @action
  fetchBalances = (onInit?: boolean) => {
    if (!onInit) {
      //this.updateLastUpdatesTimeout();
    }
    this.balancesLoading = true;
    this.appState.api.balancesTotalsRequest({}, this.onBalancesResponse);
  };

  @action
  onBalancesResponse = (msg: protocol.IServerResponse) => {
    this.balancesLoading = false;
    this.balancesLastUpdateStamp = new Date().getTime();
    const newBalancesTotal: { [key: number]: IBalanceTotalInfoRaw } = {};
    Object.values(protocol.Currency).forEach(value => {
      if (Number(value) !== 0) {
        newBalancesTotal[Number(value)] = {
          amount: 0,
        };
      }
    });
    if (msg.clientTotalBalancesResponse?.balances?.length) {
      msg.clientTotalBalancesResponse.balances.forEach((item: protocol.ClientTotalBalancesResponse.IBalance) => {
        newBalancesTotal[item.currency as number] = {
          amount: Number(item.balance),
          balanceFiat: convertMapWithCurrencyNamesAsKeysToWlCurrency(item.balanceFiat),
        };
      });
    }
    this.balancesTotalRaw = newBalancesTotal;
  };

  /*  @action
  convertBalancesToFiat = (newTotalBalances?: { [key: number]: IBalanceTotalInfo }, cb?: () => void) => {
    const entriesForConvertToFiat: protocol.IConvertCurrenciesEntry[] = [];
    const balances = newTotalBalances ? newTotalBalances : this.balancesTotal;
    Object.keys(balances).forEach(currency => {
      if (balances[Number(currency)].amount !== 0 && Number(currency) !== protocol.Currency.TRX_CUR) {
        entriesForConvertToFiat.push({
          amount: String(balances[Number(currency)].amount),
          fromCurrencyCode: getCurrencyCodeForConvert(Number(currency)),
          toCurrencyCode: this.appState.selectedCurrencyForConvert,
        });
      }
    });
    this.appState.api.convertCurrencies(entriesForConvertToFiat, (msg: protocol.IServerResponse) => {
      if (cb) cb();
      this.onConvertBalancesResponse(msg);
    });
  };

  @action
  onConvertBalancesResponse = (msg: protocol.IServerResponse) => {
    if (msg?.convertCryptoToFiatResponse?.entries?.length) {
      msg.convertCryptoToFiatResponse.entries.forEach(entry => {
        if (entry.fromCurrencyCode) {
          const code = getCryptoCurrencyCodeByWlCurrency(entry.fromCurrencyCode);
          if (code && this.balancesTotal[code]) {
            this.balancesTotal[code].convertedAmount = Number(entry.amount);
            this.balancesTotal[code].convertedAmountFormatted = formatWlCurrency(
              Number(entry.amount),
              entry.toCurrencyCode as currencies.WLCurrency,
            );
          }
        }
      });
    }
  };*/

  @action
  fetchMainAccounts = () => {
    this.appState.api.mainAccounts(this.onMainAccountsResponse);
  };

  @action
  onMainAccountsResponse = (msg: protocol.IServerResponse) => {
    if (msg?.mainAccountsResponse?.accounts?.length) {
      const newAccounts = msg.mainAccountsResponse.accounts;
      this.convertMainAccountsToFiat(newAccounts, () => {
        this.mainAccounts = newAccounts;
      });
    }
  };

  @action
  convertMainAccountsToFiat = (newAccounts?: IMainAccount[], cb?: () => void) => {
    const accounts = newAccounts ? newAccounts : this.mainAccounts;

    if (accounts?.length) {
      const entries: protocol.IConvertCurrenciesEntry[] = accounts
        .filter(item => item.chain && item.chain !== protocol.Chain.TRX_CHAIN)
        .map(item => ({
          amount: item.balance,
          fromCurrencyCode: getCurrencyCodeForConvert(chainToCurrency(item.chain!)),
          toCurrencyCode: this.appState.selectedCurrencyForConvert,
        }));
      this.appState.api.convertCurrencies(entries, (msg: protocol.IServerResponse) => {
        if (cb) cb();
        setTimeout(() => this.onConvertAccountsResponse(msg), 0);
      });
    }
  };

  @action
  onConvertAccountsResponse = (msg: protocol.IServerResponse) => {
    if (msg?.convertCryptoToFiatResponse?.entries?.length) {
      msg.convertCryptoToFiatResponse.entries.forEach(entry => {
        if (entry.fromCurrencyCode) {
          const currency = getCryptoCurrencyCodeByWlCurrency(entry.fromCurrencyCode);
          const chain = currencyToChain(currency);

          this.mainAccounts = this.mainAccounts?.map(account => {
            if (account.chain === chain) {
              return {
                ...account,
                convertedAmount: Number(entry.amount),
                convertedAmountFormatted: formatWlCurrency(
                  Number(entry.amount),
                  entry.toCurrencyCode as currencies.WLCurrency,
                ),
              };
            }
            return account;
          });
        }
      });
    }
  };

  @action.bound
  updateOperationTab(type: OperationTab) {
    if (type === 'Payout requests') {
      this.fetchProcessingTransactions();
    }
    if (this.operationType !== type) {
      this.operationType = type;
      if (type !== 'Payout requests') {
        this.page = 0;
        this.fetchTransactions();
      }
    }
  }

  @computed
  get transactionDirection() {
    if (this.operationType === 'Refills') {
      return protocol.TxDirection.IN_TX_DIR;
    } else if (this.operationType === 'Withdrawal') {
      return protocol.TxDirection.OUT_TX_DIR;
    }
    return undefined;
  }

  @computed
  get transactionStatus() {
    if (this.operationType === 'Refills' || this.operationType === 'Withdrawal') {
      return protocol.TxStatus.CONFIRMED_TX_STATUS;
    } else if (this.operationType === 'Pending') {
      return protocol.TxStatus.PENDING_TX_STATUS;
    }
    return undefined;
  }

  @action.bound
  fetchStats(onInit?: boolean) {
    if (!onInit) {
      //this.updateLastUpdatesTimeout();
    }
    this.isTransactionsStatsLoading = true;
    this.appState.api.transactionsStats(
      {
        updatedAtFrom: this.periodTotal?.from ? Long.fromNumber(this.periodTotal.from) : undefined,
        updatedAtTo: this.periodTotal?.to ? Long.fromNumber(this.periodTotal.to) : undefined,
      },
      this.processStats,
    );
  }

  @action.bound
  processStats(response: protocol.IServerResponse) {
    this.isTransactionsStatsLoading = false;
    if (response?.transactionsStatsResponse) {
      this.transactionsStats = response.transactionsStatsResponse;
    }
  }

  @action.bound
  fetchTransactions(onInit?: boolean) {
    if (!onInit) {
      //this.updateLastUpdatesTimeout();
    }
    this.isTransactionsLoading = true;
    this.appState.api.transactionsPaged(
      {
        clientId: this.appState.clientId,
        limit: Long.fromNumber(50),
        offset: Long.fromNumber(this.page * 50),
        direction: this.transactionDirection,
        status: this.transactionStatus,
        sortBy: this.sortBy,
        sortDesc:
          this.sortedColumn?.direction !== undefined ? this.sortedColumn?.direction === SORT_DIRECTION.DESC : undefined,
        updatedAtFrom: this.transactionsPeriod?.from ? Long.fromNumber(this.transactionsPeriod.from) : undefined,
        updatedAtTo: this.transactionsPeriod?.to ? Long.fromNumber(this.transactionsPeriod.to) : undefined,
        currency: !!this.filterCurrency ? this.filterCurrency : undefined,
      },
      this.processOperations,
    );
  }

  @action.bound
  fetchProcessingTransactions() {
    this.processingOperationsLoading = true;
    this.appState.api.listPayoutRequestsRequest(this.onProcessingTransactionsResponse);
  }

  @action.bound
  processOperations(response: protocol.IServerResponse) {
    let operations: Operation[] = [];
    if (response?.transactionsPagedResponse?.transactions?.length) {
      operations = processOperations(response.transactionsPagedResponse.transactions, this.appState);
      this.operationsRaw = response.transactionsPagedResponse.transactions;
      this.totalPages = response?.transactionsPagedResponse.totalPages?.toNumber() || 0;
    } else {
      this.totalPages = 0;
    }
    this.transactionsTableTotals = {
      totalAmounts: convertMapWithCurrencyNamesAsKeysToWlCurrency(response?.transactionsPagedResponse?.totalAmounts),
      totalFees: convertMapWithCurrencyNamesAsKeysToWlCurrency(response?.transactionsPagedResponse?.totalFees),
    };
    if (operations?.length) {
      this.fetchTags(operations);
    } else {
      this.isTransactionsLoading = false;
      this.operations.replace([]);
    }
  }

  @action.bound
  fetchTags(operations: Operation[]) {
    this.appState.api.tagsRequest(
      {
        filterTxIds: operations?.filter(o => !!o.id).map(o => o.id),
        filterClientIds: this.appState.clientId ? [this.appState.clientId] : [],
      },
      (msg: protocol.IServerResponse) => this.onTagsResponse(msg, operations),
    );
  }

  @action.bound
  onTagsResponse(msg: protocol.IServerResponse, operations: Operation[]) {
    if (msg?.listTagsResponse?.tags?.length) {
      const tags = msg.listTagsResponse.tags.map(_ => ({ ..._ }));
      this.operations.replace(
        operations.map(operation => {
          if (operation.id) {
            const operationTags = tags
              .filter(tag => tag.txIds?.includes(operation.id) && !!tag.name)
              .map(tag => tag.name!);
            operation.tags = operationTags;
            if (operation.childs?.length) {
              operation.childs = operation.childs.map(child => {
                child.tags = operationTags;
                return child;
              });
            }
          }
          return operation;
        }),
      );
      this.isTransactionsLoading = false;
    } else {
      this.isTransactionsLoading = false;
      this.operations.replace(operations);
    }
  }

  @action.bound
  onProcessingTransactionsResponse(msg: protocol.IServerResponse) {
    this.processingOperationsLoading = false;
    if (msg?.listPayoutRequestsResponse?.payoutRequests?.length) {
      this.processingOperations.replace(
        processPayoutRequestRecords(msg.listPayoutRequestsResponse.payoutRequests, this.appState),
      );
    }
  }

  @computed
  get storageLastUpdateItemName() {
    return LAST_CHECK_UPDATES_TIME_STORAGE_NAME + '|' + this.appState.clientId;
  }

  onUpdateClick = () => {
    window.clearTimeout(this.updatesTimeout);
    this.fetchAll();
  };

  @computed
  get lastCheckTimeFormatted() {
    return this.timeFromLastCheck ? formatSecondsToTime(this.timeFromLastCheck) : undefined;
  }

  @computed
  get lastCheckTimeFormattedType2() {
    return this.timeFromLastCheck ? formatSecondsToTimeHHmmss(this.timeFromLastCheck) : undefined;
  }

  @action.bound
  onTick = () => {
    if (this.lastCheckTime) {
      const timeFromUpdate = moment().unix() - this.lastCheckTime;
      this.timeFromLastCheck = timeFromUpdate;
    }
    if (this.prevCheckTime) {
      const timeFromPrevCheck = moment().unix() - this.prevCheckTime;
      this.timeFromPrevCheckFormatted = formatSecondsToTime(timeFromPrevCheck);
    }
  };

  @action.bound
  checkUpdates(loadUpdates?: boolean) {
    if (this.lastCheckTime) {
      this.hasChangesLoading = true;
      const lastCheckTimeLong = Long.fromNumber(this.lastCheckTime);
      this.appState.api.hasChangesDashboardRequest(
        { lastCheckTime: lastCheckTimeLong },
        (msg: protocol.IServerResponse) => this.processHasUpdates(msg, lastCheckTimeLong, loadUpdates),
      );
    } else {
      this.setLastCheckTime();
    }
    this.updatesTimeout = window.setTimeout(this.checkUpdates, CHECK_UPDATES_TIMEOUT);
  }

  @action.bound
  processHasUpdates(msg: protocol.IServerResponse, lastCheckTime: Long, loadUpdates?: boolean) {
    this.hasChangesLoading = false;

    if (msg.hasChangesDashboardResponse?.hasChanges) {
      if (loadUpdates) {
        this.updateBlockVisible = false;
        this.appState.api.latestChangesDashboardRequest({ lastCheckTime: lastCheckTime }, this.processUpdates);
      } else {
        this.updateBlockVisible = true;
      }
    } else {
      this.updateBlockVisible = false;
    }
  }

  @action.bound
  processUpdates(msg: protocol.IServerResponse) {
    this.prevCheckTime = this.lastCheckTime ?? undefined;
    this.setLastCheckTime();

    if (msg.latestChangesDashboardResponse?.totalBalances?.length) {
      const balancesUpdates: { [key: number]: IBalanceTotalInfoRaw } = {};
      msg.latestChangesDashboardResponse?.totalBalances.forEach(balance => {
        balancesUpdates[balance.currency as number] = {
          amount: Number(balance.balance),
        };
      });
      this.balancesUpdates = balancesUpdates;
    }
    if (msg.latestChangesDashboardResponse?.transactionsStats) {
      this.transactionsStatsUpdates = msg.latestChangesDashboardResponse.transactionsStats;
    }

    this.transactionsUpdates = msg.latestChangesDashboardResponse?.transactions ?? [];
  }

  @action
  setLastCheckTimeFromLocalStorage = () => {
    const lastCheckTime = localStorage.getItem(this.storageLastUpdateItemName);

    if (lastCheckTime) {
      this.lastCheckTime = Number(lastCheckTime);
    }
  };

  setLastCheckTime = () => {
    const lastCheckTime = Math.round(new Date().getTime() / 1000);
    this.lastCheckTime = lastCheckTime;
    localStorage.setItem(this.storageLastUpdateItemName, String(lastCheckTime));
  };

  resetLastUpdates = () => {
    window.clearTimeout(this.updatesTimeout);
    window.clearInterval(this.interval);
  };

  @action
  onTxTableRowClick = (row: IOperation | PayoutRequestRecord) => {
    this.pageScrollXPosition = {
      x: window.scrollX,
      y: window.scrollY,
    };
    if (this.tableRef?.current) {
      this.tableScrollPosition = {
        y: this.tableRef.current.scrollTop,
        x: this.tableRef.current.scrollLeft,
      };
    }
    this.appState.txInfoStore.goToInfoPage(row);
  };

  @action
  onSetTableRef = (ref: React.RefObject<HTMLDivElement>) => {
    this.tableRef = ref;
  };

  @action
  openDeleteProcessingTransactionModal = (id: number) => {
    this.processingTransactionIdForDelete = id;
    this.appState.modalsStore.openModal(ModalType.CANCEL_PROCESSING_TRANSACTION);
  };

  @action
  deleteProcessingTransaction = () => {
    if (this.processingTransactionIdForDelete) {
      this.processingTransactionCancellationLoading = true;
      this.appState.api.cancelPendingPayoutRequestRequest(
        { id: this.processingTransactionIdForDelete },
        (msg: protocol.IServerResponse) => {
          this.processingTransactionCancellationLoading = false;
          if (!msg.error) {
            this.appState.notifications.addNotification('Transaction canceled successfully', NotificationType.SUCCESS);
            this.appState.modalsStore.closeModal();
            this.fetchProcessingTransactions();
            if (this.appState.txInfoStore.operations?.length) {
              this.appState.txInfoStore.operations = (
                this.appState.txInfoStore.operations as PayoutRequestRecord[]
              )?.filter(_ => _.id !== this.processingTransactionIdForDelete);
            }
          } else {
            this.appState.notifications.addNotification(formatServerErrors(msg.error), NotificationType.ERROR);
          }
        },
      );
    }
  };

  @action
  resetPage = () => {
    if (!this.appState.txInfoStore.operations?.length) {
      this.operations.replace([]);
      this.pageScrollXPosition = undefined;
      this.transactionsStatsUpdates = undefined;
      this.page = 0;
      this.periodTransaction = 'All';
      this.transactionsTableTotals = undefined;
      this.customDateRangeTransactions = undefined;
      this.sortedColumn = {
        name: 'updatedAtNumber',
        direction: SORT_DIRECTION.DESC,
      };
      this.processingTransactionCancellationLoading = undefined;
      this.resetLastUpdates();
      this.lastCheckTime = undefined;
      this.updateBlockVisible = false;
      this.isTransactionsStatsLoading = false;
      this.hasChangesLoading = false;
      this.transactionsUpdates = [];
      this.processingOperationsLoading = false;
    }
  };
}
