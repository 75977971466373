import { Link } from 'react-router-dom';
import { Icon } from '../../Icon/Icon';
import './BackButton.scss';

interface IBackButtonProps {
  label: string;
  link: string;
}

export const BackButton = ({ label, link }: IBackButtonProps) => {
  return (
    <Link to={link} className="BackButton">
      <Icon iconName="arrow-back-blue" widthInPx={10} heightInPx={16} />
      {label}
    </Link>
  );
};
