import cn from 'classnames';
import { Icon } from '../Icon/Icon';
import './TotalsBlock.scss';

export enum TotalTypes {
  DEEP_BLUE = 'deep-blue',
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  YELLOW = 'yellow',
}
interface TotalItemProps {
  value: string;
  title: string;
  type: TotalTypes;
  income?: string;
  outcome?: string;
  updatesValue?: string;
  updatesValueIncome?: string;
  updatesValueOutcome?: string;
  updatesTimeBottom?: string;
}

export const TotalItem = (props: TotalItemProps) => {
  return (
    <div className={cn('TotalItem', props.type)}>
      <div className="title">{props.title}</div>
      <div className="value-block">
        <div
          className={cn('value', {
            withUpdatesAdditionalValues:
              (props.income || props.outcome) &&
              (props.updatesValue || props.updatesValueIncome || props.updatesValueOutcome),
          })}
        >
          <div>{props.value}</div>
          {props.updatesValue ? <div className="value-updates">{props.updatesValue}</div> : null}
        </div>
        {props.income && props.outcome ? (
          <div className="additional-values-block">
            <div className="additional-value">
              <div className="additional-value__value">
                <Icon iconName="income" widthInPx={16} heightInPx={16} />
                {props.income}
              </div>
              {props.updatesValueIncome ? <div className="value-updates">{props.updatesValueIncome}</div> : null}
            </div>
            <div className="additional-value">
              <div className="additional-value__value">
                <Icon iconName="outcome" widthInPx={16} heightInPx={16} />
                {props.outcome}
              </div>
              {props.updatesValueOutcome ? <div className="value-updates">{props.updatesValueOutcome}</div> : null}
            </div>
          </div>
        ) : null}
      </div>
      {props.updatesTimeBottom ? <div className="TotalItem__updates-time-bottom">{props.updatesTimeBottom}</div> : null}
    </div>
  );
};
