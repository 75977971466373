import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AmlReportAddressStatistics } from '../../components/aml/AmlReportAddressStatistics/AmlReportAddressStatistics';
import { Icon } from '../../components/Icon/Icon';
import { useMountEffect } from '../../hooks/useMountEffect';
import { useState } from '../../state/AppState';
import './AmlAddressPage.scss';

export const AmlAddressPage = observer(() => {
  const state = useState();
  const params = useParams();
  const navigate = useNavigate();
  const { amlReport } = state;
  const { setReportRowId, resetReportRowPage, reportRowData, reportAddressesLoading } = amlReport;
  useMountEffect(() => {
    if (params.clientId) {
      state.setClientId(params.clientId);
      if (params.reportId && params.reportRowId) {
        setReportRowId(params.reportRowId, Number(params.reportId));
      } else if (params.reportId) {
        navigate(`/dashboard/user/${state.clientId}/aml/${params.reportId}`);
      } else {
        navigate(`/dashboard/user/${state.clientId}/aml`);
      }
    }
  });
  useEffect(() => {
    return () => {
      resetReportRowPage();
    };
  }, [resetReportRowPage]);
  return (
    <div className="AmlAddressPage">
      <div
        onClick={() => navigate(`/dashboard/user/${state.clientId}/aml/${params.reportId}`)}
        className="AmlAddressPage-back-button"
      >
        <Icon iconName="arrow-back-blue" widthInPx={10} heightInPx={16} />
        Report
      </div>
      <div className="AmlAddressPage__title">
        Address <span>{reportRowData?.address}</span>
      </div>
      <div className="AmlAddressPage__content">
        <AmlReportAddressStatistics
          isLoading={reportAddressesLoading}
          sources={reportRowData?.sources}
          riskScore={reportRowData?.riskScore}
          riskLevel={reportRowData?.riskLevel}
        />
        <div className="AmlAddressPage__info">
          {/*<div className="AmlAddressPage__info-block">
            <div className="AmlAddressPage__info-title">Tags</div>
            <div className="AmlAddressPage__info-value">dopiqwd, qwmpdqwsd</div>
          </div>*/}
          <div className="AmlAddressPage__info-block">
            <div className="AmlAddressPage__info-title">Last check</div>
            <div className="AmlAddressPage__info-value">
              {reportRowData?.lastCheckedAt && reportRowData?.lastCheckedAt?.notEquals(0)
                ? new Date(reportRowData?.lastCheckedAt?.toNumber() * 1000).toLocaleDateString()
                : 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
