import { action, computed, makeObservable, observable } from 'mobx';
import { protocol } from '../api/proto';
import { IAMLCheckFormatted, IAmlReport } from './AmlStore';
import { AppState } from './AppState';
import { formatServerErrors } from './utils/format';
import { NotificationType } from './NotificationsState';

const CHECK_REPORT_TIMEOUT = 10000; //ms

export class AmlSingleCheckStore {
  private appState: AppState;

  @observable checkId?: number;
  @observable isChecking?: boolean = false;
  @observable isLoading: boolean = false;
  checkingTimeout?: number;

  @observable check?: IAMLCheckFormatted = undefined;

  @computed
  get checkAgainButtonDisabled() {
    return !!(this.isLoading || this.isChecking || !this.check?.address || !this.check?.currency);
  }

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  initAmlCheck = (checkId: number) => {
    this.checkId = checkId;
    this.loadCheckResult();
  };

  @action
  loadCheckResult = () => {
    this.isLoading = true;
    this.appState.api.amlCheckResultRequest({ id: this.checkId }, this.onCheckResultResponse);
  };

  @action
  onCheckResultResponse = (msg: protocol.IServerResponse) => {
    this.isLoading = false;

    if (msg?.amlCheckResultResponse?.check) {
      const check = msg?.amlCheckResultResponse?.check;
      if (
        check.status === protocol.AMLCheckStatus.PROCESSING_AML_CHECK_STATUS ||
        check.status === protocol.AMLCheckStatus.PENDING_AML_CHECK_STATUS ||
        !check.status
      ) {
        this.isChecking = true;
        this.fetchingCheck();
      } else {
        this.processCheck(check);
      }
    }
  };

  @action
  fetchingCheck = () => {
    if (this.checkId && this.isChecking) {
      this.appState.api.amlCheckResultRequest({ id: this.checkId }, this.onFetchingCheckResponse);
    }
  };

  @action
  onFetchingCheckResponse = (msg: protocol.IServerResponse) => {
    if (msg?.amlCheckResultResponse?.check) {
      const check = msg?.amlCheckResultResponse?.check;
      if (
        check.status === protocol.AMLCheckStatus.PROCESSING_AML_CHECK_STATUS ||
        check.status === protocol.AMLCheckStatus.PENDING_AML_CHECK_STATUS ||
        !check.status
      ) {
        this.processCheck(check);
        this.checkingTimeout = window.setTimeout(this.fetchingCheck, CHECK_REPORT_TIMEOUT);
      } else {
        this.isChecking = false;
        this.processCheck(check);
      }
    } else {
      this.isChecking = false;
    }
  };

  @action
  processCheck = (check: protocol.IAMLCheck) => {
    this.check = {
      ...check,
      updatedAtNumber: check.updatedAt?.toNumber(),
      createdAtNumber: check.createdAt?.toNumber(),
      updatedAtFormatted: check.updatedAt?.notEquals(0)
        ? new Date(check.updatedAt?.multiply(1000).toNumber() ?? '').toLocaleString()
        : undefined,
      createdAtFormatted: new Date(check.createdAt?.multiply(1000).toNumber() ?? '').toLocaleString(),
    };
  };

  @action
  goToChecks = () => {
    this.appState.aml.amlPageTab = 'single-reports';
    if (this.appState.navigate) this.appState.navigate(`/dashboard/user/${this.appState.clientId}/aml`);
  };

  @action
  checkAgainStart = () => {
    this.isLoading = true;
    this.appState.api.amlSingleCheckRequest(
      {
        address: this.check?.address,
        currency: this.check?.currency,
        forceCheck: true,
      },
      this.onSingleCheckResponse,
    );
  };

  @action
  onSingleCheckResponse = (msg: protocol.IServerResponse) => {
    if (msg?.amlSingleCheckResponse?.id) {
      this.appState.navigate!(`/dashboard/user/${this.appState.clientId}/aml/single/${msg.amlSingleCheckResponse.id}`);
      this.initAmlCheck(msg.amlSingleCheckResponse.id);
    } else {
      this.appState.notifications.addNotification(formatServerErrors(msg.error), NotificationType.ERROR);
    }
  };

  @action
  resetPage = () => {
    this.checkId = undefined;
    this.check = undefined;
    this.isChecking = false;
    this.isLoading = false;
    window.clearTimeout(this.checkingTimeout);
  };
}
