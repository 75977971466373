import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from '../../components/Icon/Icon';
import { PeriodFilters } from '../../components/PeriodFilters/PeriodFilters';
import Table from '../../components/Table/Table';
import { useMountEffect } from '../../hooks/useMountEffect';
import { ActivityPeriod, ACTIVITY_PERIODS } from '../../state/ActivityState';
import { useState } from '../../state/AppState';
import './ActivityPage.scss';

export const ActivityPage = observer(() => {
  const state = useState();
  const { activityState } = state;
  const { resetPage } = activityState;
  const params = useParams();

  useMountEffect(() => {
    if (params.clientId && !state.clientId) {
      state.setClientId(params.clientId);
    }
    activityState.fetchLogs();
  });

  React.useEffect(() => {
    return () => {
      resetPage();
    };
    // eslint-disable-next-line
  }, []);

  const tableColumns = [
    {
      title: 'Date and time',
      key: 'formattedDate',
      sortEnabled: true,
      sortKey: 'createdAtNumber',
    },
    {
      title: 'Request name',
      key: 'requestName',
      sortEnabled: false,
    },
    {
      title: 'IP',
      key: 'ip',
      sortEnabled: true,
    },
    {
      title: 'Country, city',
      key: 'countryName',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => {
        return (
          <td>
            <div className="d-flex-row nowrap">
              <span className="ActivityPage-table-country-name">{row['countryName']}</span>
              <Icon customPath={`/images/icons/flags/Country=${row.countryCode}.svg`} heightInPx={16} widthInPx={16} />
              {`, ${row['cityName']}`}
            </div>
          </td>
        );
      },
    },
    {
      title: 'User Agent',
      key: 'userAgent',
      sortEnabled: true,
    },
  ];

  return (
    <div className="ActivityPage">
      <div className="ActivityPage__list">
        <Table
          columns={tableColumns}
          hasChildRows
          periodsFilterColumn={'createdAtNumber'}
          data={activityState.activityLogsList}
          filtersLeftBlock={<div className="ActivityPage__title">Activity</div>}
          filtersCustomPeriodsFilters={
            <PeriodFilters
              onChange={(period: string) => activityState.changePeriod(period as ActivityPeriod)}
              value={activityState.activityPeriod}
              periods={ACTIVITY_PERIODS as unknown as string[]}
              //setCustomDateRange={props.setCustomDateRange}
              //customDateRange={props.customDateRange}
            />
          }
        />
      </div>
    </div>
  );
});
