import cn from 'classnames';
import { Icon } from '../../Icon/Icon';
import './Button.scss';
import { Loader } from '../../Loader/Loader';

export enum ButtonSize {
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  NORMAL = 'normal',
  NORMAL_XL = 'normal-xl',
  BIG = 'big',
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  PRIMARY_ROUNDED = 'primary-rounded',
  SECONDARY_TYPE_2 = 'secondary-2',
  CANCEL = 'cancel',
}

interface ButtonProps {
  size?: ButtonSize;
  onClick: React.MouseEventHandler;
  disabled?: boolean;
  label: string;
  iconName?: string;
  type?: ButtonType;
  className?: string;
  isLoading?: boolean;
  iconSizes?: {
    width: number;
  };
}

export const Button = (props: ButtonProps) => {
  return (
    <button
      disabled={props.disabled || props.isLoading}
      onClick={props.onClick}
      className={cn('button', props.className, props.size, props.type)}
    >
      {props.iconName ? <Icon iconName={props.iconName} widthInPx={props.iconSizes?.width ?? 8} /> : null}
      {props.isLoading ? (
        <div className="button-loader">
          <Loader />
        </div>
      ) : null}
      {props.label}
    </button>
  );
};
