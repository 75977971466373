import { protocol } from '../../api/proto';
import { AppState } from '../AppState';
import { IOperation, Operation } from '../Operation';
import { PayoutRequestRecord } from '../PayoutRequestRecord';
import { convertMapWithCurrencyNamesAsKeysToWlCurrency } from './Money';

export function processOperations(operationsRaw: protocol.ITxRecord[], appState: AppState) {
  if (!operationsRaw.length) return [];

  let operations: Operation[] = [];
  const operationsMap: { [mergeId: string]: protocol.ITxRecord[] } = {};
  const operationsMapKeys: string[] = [];
  operationsRaw.forEach(trx => {
    if (
      ((trx.currency !== protocol.Currency.ETH_CUR &&
        trx.currency !== protocol.Currency.TRX_CUR &&
        trx.currency !== protocol.Currency.ERC20_USDT_CUR &&
        trx.currency !== protocol.Currency.TRC20_USDT_CUR) ||
        ((trx.currency === protocol.Currency.ETH_CUR || trx.currency === protocol.Currency.TRX_CUR) &&
          !trx.payoutRequestId) ||
        ((trx.currency === protocol.Currency.ERC20_USDT_CUR || trx.currency === protocol.Currency.TRC20_USDT_CUR) &&
          !trx.operationId)) &&
      trx.hash
    ) {
      if (operationsMap[trx.hash]) {
        operationsMap[trx.hash].push(trx);
      } else {
        operationsMap[trx.hash] = [trx];
        operationsMapKeys.push(trx.hash);
      }
    } else if (
      (trx.currency === protocol.Currency.ETH_CUR || trx.currency === protocol.Currency.TRX_CUR) &&
      trx.payoutRequestId
    ) {
      if (operationsMap[trx.payoutRequestId]) {
        operationsMap[trx.payoutRequestId].push(trx);
      } else {
        operationsMap[trx.payoutRequestId] = [trx];
        operationsMapKeys.push(String(trx.payoutRequestId));
      }
    } else if (
      (trx.currency === protocol.Currency.ERC20_USDT_CUR || trx.currency === protocol.Currency.TRC20_USDT_CUR) &&
      trx.operationId
    ) {
      if (operationsMap[trx.operationId]) {
        operationsMap[trx.operationId].push(trx);
      } else {
        operationsMap[trx.operationId] = [trx];
        operationsMapKeys.push(String(trx.operationId));
      }
    }
  });
  operationsMapKeys.forEach(mergeId => {
    if (operationsMap[mergeId]?.length) {
      const operation = operationsMap[mergeId];
      if (operationsMap[mergeId]?.length > 1) {
        const operationSum: IOperation = operation.reduce(
          (acc: IOperation, trx: protocol.ITxRecord) => {
            const amountFiatSum: { [k: number]: number } | null | undefined = acc.amountFiatSum ?? {};
            const fiatAmounts = convertMapWithCurrencyNamesAsKeysToWlCurrency(trx.meta?.amounts);
            Object.keys(fiatAmounts).forEach((currency: string) => {
              amountFiatSum[Number(currency)] =
                (amountFiatSum[Number(currency)] ? Number(amountFiatSum[Number(currency)]) : 0) +
                (fiatAmounts[Number(currency)] ? Number(fiatAmounts[Number(currency)]) : 0);
            });
            const feeFiatSum: { [k: number]: number } | null | undefined = acc.feeFiatSum ?? {};
            const fiatFees = convertMapWithCurrencyNamesAsKeysToWlCurrency(trx.meta?.fees);
            Object.keys(fiatFees).forEach((currency: string) => {
              feeFiatSum[Number(currency)] =
                (feeFiatSum[Number(currency)] ? Number(feeFiatSum[Number(currency)]) : 0) +
                (fiatFees[Number(currency)] ? Number(fiatFees[Number(currency)]) : 0);
            });
            return {
              ...trx,
              sumAmount: acc.sumAmount && trx.amount ? String(Number(acc.sumAmount) + Number(trx.amount)) : '0',
              amountFiatSum,
              sumFee: acc.sumFee && trx.meta?.fee ? String(Number(acc.sumFee) + Number(trx.meta.fee)) : '0',
              feeFiatSum,
            };
          },
          { sumAmount: '0', sumFee: '0' },
        );
        operations.push(
          new Operation(
            appState,
            operationSum as protocol.TxRecord,
            operation.slice(0, operation.length - 1).map(_ => new Operation(appState, _ as protocol.TxRecord)),
          ),
        );
      } else {
        operations.push(new Operation(appState, operation[0] as protocol.TxRecord));
      }
    }
  });
  return operations;
}

export function processPayoutRequestRecords(operationsRaw: protocol.IPayoutRequestRecord[], appState: AppState) {
  if (!operationsRaw.length) return [];

  let operations: PayoutRequestRecord[] = [];
  const operationsMap: { [mergeId: string]: protocol.IPayoutRequestRecord[] } = {};
  const operationsMapKeys: string[] = [];
  operationsRaw.forEach(trx => {
    if (
      trx.status === protocol.PayoutRequestStatus.PENDING_PAYOUT_REQUEST_STATUS ||
      trx.status === protocol.PayoutRequestStatus.PROCESSING_PAYOUT_REQUEST_STATUS
    ) {
      if (trx.operationId) {
        if (operationsMap[trx.operationId]) {
          operationsMap[trx.operationId].push(trx);
        } else {
          operationsMap[trx.operationId] = [trx];
          operationsMapKeys.push(trx.operationId);
        }
      } else {
        if (operationsMap[trx.id!.toString()]) {
          operationsMap[trx.id!.toString()].push(trx);
        } else {
          operationsMap[trx.id!.toString()] = [trx];
          operationsMapKeys.push(trx.id!.toString());
        }
      }
    }
  });
  operationsMapKeys.forEach(mergeId => {
    if (operationsMap[mergeId]?.length) {
      const operation = operationsMap[mergeId];
      if (operationsMap[mergeId]?.length > 1) {
        operations.push(
          new PayoutRequestRecord(
            appState,
            operation[operation.length - 1],
            operation
              .slice(0, operation.length - 1)
              .map(_ => new PayoutRequestRecord(appState, _ as protocol.IPayoutRequestRecord)),
          ),
        );
      } else {
        operations.push(new PayoutRequestRecord(appState, operation[0] as protocol.IPayoutRequestRecord));
      }
    }
  });
  return operations;
}
