import React, { MouseEvent, MouseEventHandler } from 'react';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { IAddressWithTags } from '../../../state/Operation';
import { Icon } from '../../Icon/Icon';
import { CopyButton } from '../../controls/CopyButton/CopyButton';
import './FromToInfoCell.scss';
import cn from 'classnames';
import { getShortAddress } from '../../../state/utils/Money';
import { AddressTag } from '../AddressTag/AddressTag';

export const FromToInfoCell = ({
  row,
  withoutTrxId,
  fullDetails,
}: {
  row: any;
  withoutTrxId?: boolean;
  fullDetails?: boolean;
}) => {
  return (
    <td className="FromToInfoCell">
      <AddressesItem
        iconName="from-tx"
        fullDetails={fullDetails}
        addresses={row.fromAddressesWithTags ?? []}
        label="From"
      />
      <AddressesItem iconName="to-tx" fullDetails={fullDetails} addresses={row.toAddresses ?? []} label="To" />
      {!withoutTrxId ? (
        <div className="FromToInfoCell__row">
          <div className="FromToInfoCell__row__value">
            {row.shortHash ? (
              <div className="FromToInfoCell__address">
                <div className="FromToInfoCell__row__labelBlock">
                  <div className="FromToInfoCell__row__label">Trx: </div>
                </div>
                <div> {row.shortHash}</div>
                <CopyButton notificationText="TRX ID has been copied" sizeInPx={14} contentForCopy={row.hash} />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </td>
  );
};

export const sortTags = (a: string, b: string) => {
  if (a.startsWith('peer_')) {
    return -1;
  }
  if (b.startsWith('peer_')) {
    return 1;
  }
  return 1;
};

const AddressesItem = ({
  addresses,
  label,
  iconName,
  fullDetails,
}: {
  addresses: IAddressWithTags[];
  label: string;
  iconName: string;
  fullDetails?: boolean;
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(fullDetails ?? false);
  const [tagsViewAll, setTagsViewAll] = React.useState(fullDetails ?? false);

  const toggleShowMore = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsComponentVisible(!isComponentVisible);
  };

  const toggleViewAllTags = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setTagsViewAll(!tagsViewAll);
  };

  const tags = addresses[0]?.tags?.length
    ? tagsViewAll
      ? addresses[0].tags.sort(sortTags)
      : addresses[0].tags.sort(sortTags).slice(0, 1)
    : [];

  return (
    <div ref={fullDetails ? undefined : ref} className={cn('FromToInfoCell__row', { fullDetails: fullDetails })}>
      {addresses?.length ? (
        <div className="FromToInfoCell__row__value">
          <div className="FromToInfoCell__row__labelBlock">
            <Icon iconName={iconName} widthInPx={16} heightInPx={16} />
            <span className="FromToInfoCell__row__label">{label}:</span>
          </div>
          <div>
            <div className="FromToInfoCell__addressItem">
              {addresses[0].tags?.length ? (
                <div className={cn('FromToInfoCell__tagsList', { 'margin-right': addresses[0].tags?.length > 1 })}>
                  {tags.map((tag, i) => (
                    <AddressTag tag={tag} className={i === tags.length - 1 ? 'last' : ''} />
                  ))}
                  {addresses[0].tags?.length > 1 ? (
                    <div
                      onClick={toggleViewAllTags}
                      className={cn('FromToInfoCell__tag__button', { 'hide-btn': tagsViewAll })}
                    >
                      {tagsViewAll
                        ? 'Hide'
                        : `+ ${addresses[0].tags?.length - 1} ${addresses[0].tags?.length - 1 === 1 ? 'tag' : 'tags'}`}
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="FromToInfoCell__address">
                {getShortAddress(addresses[0].address ?? '')}
                <div className="FromToInfoCell__buttons">
                  <CopyButton
                    notificationText="Address has been copied"
                    sizeInPx={14}
                    contentForCopy={addresses[0].address ?? ''}
                  />

                  {addresses?.length > 1 ? (
                    <div className="FromToInfoCell__smallButton" onClick={toggleShowMore}>
                      +{addresses?.length - 1}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {isComponentVisible ? (
              <div className="FromToInfoCell__row__moreItems">
                <div className="FromToInfoCell__row__moreItems__title">
                  <div>
                    {addresses.length - 1} {addresses.length - 1 > 1 ? 'addresses' : 'address'}
                  </div>
                  <div className="FromToInfoCell__row__moreItems__close" onClick={toggleShowMore}>
                    <Icon iconName="close" widthInPx={12} heightInPx={12} />
                  </div>
                </div>

                {addresses.slice(1, addresses.length).map(address => (
                  <MoreItemsAddress fullDetails={fullDetails} address={address} />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const MoreItemsAddress = ({ address, fullDetails }: { address: IAddressWithTags; fullDetails?: boolean }) => {
  const [tagsViewAll, setTagsViewAll] = React.useState(fullDetails ?? false);
  const toggleViewAllTags = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setTagsViewAll(!tagsViewAll);
  };
  const tags = address.tags?.length
    ? tagsViewAll
      ? address.tags.sort(sortTags)
      : address.tags.sort(sortTags).slice(0, 1)
    : [];

  return (
    <div className="FromToInfoCell__row__moreItems__item">
      <div className="FromToInfoCell__addressItem">
        {address.tags ? (
          <div className={cn('FromToInfoCell__tagsList', { 'margin-right': address.tags?.length > 1 })}>
            {tags.map((tag, i) => (
              <AddressTag tag={tag} className={i === tags.length - 1 ? 'last' : ''} />
            ))}
            {address.tags?.length > 1 ? (
              <div onClick={toggleViewAllTags} className="FromToInfoCell__tag__button">
                {tagsViewAll
                  ? 'Hide'
                  : `+ ${address.tags?.length - 1} ${address.tags?.length - 1 === 1 ? 'tag' : 'tags'}`}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="FromToInfoCell__address">
          {getShortAddress(address.address)}
          <CopyButton notificationText="Address has been copied" sizeInPx={14} contentForCopy={address.address ?? ''} />
        </div>
      </div>
    </div>
  );
};
