import './Checkbox.scss';
import cn from 'classnames';
import { Icon } from '../../Icon/Icon';

export enum CheckboxType {
  DEFAULT = 'default',
  GRAY = 'gray',
}
interface CheckboxProps {
  isActive?: boolean;
  toggle?: () => void;
  type?: CheckboxType;
}

export const Checkbox = (props: CheckboxProps) => {
  return (
    <div onClick={props.toggle} className={cn('Checkbox', props.type, { isActive: props.isActive })}>
      {props.isActive && (
        <Icon iconName={props.type === CheckboxType.GRAY ? 'check-black' : 'check'} widthInPx={10} heightInPx={6} />
      )}
    </div>
  );
};
