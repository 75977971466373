import { makeObservable, observable, action } from 'mobx';
import { AppState } from './AppState';

export enum ModalType {
  CANCEL_PROCESSING_TRANSACTION = 'cancel_processing_transaction',
}

export class ModalsStore {
  private appState: AppState;

  @observable activeModal?: ModalType;

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  openModal = (type: ModalType) => {
    this.activeModal = type;
  };

  @action
  closeModal = () => {
    this.activeModal = undefined;
  };
}
