import { makeObservable, observable, action, computed } from 'mobx';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import APIConnector from '../api/APIConnector';
import { MainPageInfoStore } from './MainPageInfoStore';
import { NotificationsState } from './NotificationsState';
import { WithdrawalState } from './WithdrawalState';
import { NavigateFunction } from 'react-router-dom';
import { currencies, protocol } from '../api/proto';
import { ExplorerState } from './ExplorerState';
import { ActivityState } from './ActivityState';
import { AmlStore } from './AmlStore';
import { AmlReportStore } from './AmlReportStore';
import { InvoicesStore } from './InvoicesStore';
import { InvoiceCreatingStore } from './InvoiceCreatingStore';
import { InvoicePeerStore } from './InvoicePeerStore';
import { UsersStore } from './UsersStore';
import { LoginStore } from './LoginStore';
import { formatServerErrors } from './utils/format';
import { AccessGroups, getAvailableRoutes, getMainRouteByRole } from './Roles';
import { AgnosticRouteObject } from '@remix-run/router';
import { menuRoutes, ProtectedRoutes } from './Routes';
import { SystemAddressesStore } from './SystemAddressesStore';
import { TxInfoStore } from './TxInfoStore';
import { ModalsStore } from './ModalsStore';
import { AmlSingleCheckStore } from './AmlSingleCheckStore';

export class AppState {
  navigate?: NavigateFunction = undefined;

  @observable currentProtectedRoute?: ProtectedRoutes;

  @observable isInitialized: boolean = false;
  @observable isCheckingSession: boolean = false;
  @observable isAuthUser: boolean = false;
  @observable userAccessGroups?: string[];
  @observable clientId?: string = undefined;
  @observable token?: string;
  @observable selectedCurrencyForConvert: currencies.WLCurrency = currencies.WLCurrency.WLC_USD;
  @observable errorPageText?: string;

  @observable waitingMessageFromApp: boolean = false;

  @observable availableRoutes: AgnosticRouteObject[] = getAvailableRoutes();

  api: APIConnector = new APIConnector(this);

  @observable mainPageInfo = new MainPageInfoStore(this);
  @observable notifications = new NotificationsState(this);
  @observable withdrawalState = new WithdrawalState(this);
  @observable explorerState = new ExplorerState(this);
  @observable activityState = new ActivityState(this);
  @observable aml = new AmlStore(this);
  @observable amlReport = new AmlReportStore(this);
  @observable amlSingleCheckStore = new AmlSingleCheckStore(this);
  @observable invoicesStore = new InvoicesStore(this);
  @observable invoiceCreatingStore = new InvoiceCreatingStore(this);
  @observable invoicePeerStore = new InvoicePeerStore(this);
  @observable usersStore = new UsersStore(this);
  @observable loginStore = new LoginStore(this);
  @observable systemAddressesStore = new SystemAddressesStore(this);
  @observable txInfoStore = new TxInfoStore(this);
  @observable modalsStore = new ModalsStore(this);

  constructor() {
    makeObservable(this);
  }

  @computed
  get isReadOnlyAllAccess() {
    return this.userAccessGroups?.includes(AccessGroups.READ_ONLY_FINANCIAL_ACCESS);
  }

  @computed
  get availableRoutesMenuItems() {
    return menuRoutes.filter(item => !!this.availableRoutes.find(r => r.path === item.route));
  }

  @action
  setWaitingMessageFromApp(isWaiting: boolean) {
    this.waitingMessageFromApp = isWaiting;
  }

  @action
  setClientId = (clientId: string) => {
    this.clientId = clientId;
  };

  @action
  initNavigate = (navigate: NavigateFunction) => {
    this.navigate = navigate;
  };

  @action
  setCurrentProtectedRoute = (route: ProtectedRoutes) => {
    this.currentProtectedRoute = route;
  };

  @action
  convertCurrencies = (items: protocol.IConvertCurrenciesEntry[], cb: (msg: protocol.IServerResponse) => void) => {
    this.api.convertCurrencies(items, cb);
  };

  @action
  changeCurrencyForConvert = (value: string | number) => {
    this.selectedCurrencyForConvert = Number(value);
    this.mainPageInfo.convertMainAccountsToFiat();
  };

  @action
  checkSession = (
    withRedirectToAvailablePage?: boolean,
    redirectToPage?: string,
    token?: string,
    loginPage?: boolean,
  ) => {
    this.isCheckingSession = true;
    this.api.checkSessionRequest(
      { token: this.getTokenFromLocalStorage() ?? token },
      (msg: protocol.IServerResponse) => {
        this.isCheckingSession = false;
        if (!msg.error) {
          this.isInitialized = true;
          if (msg?.checkSessionResponse?.user?.id) {
            this.isAuthUser = true;
            this.userAccessGroups = msg?.checkSessionResponse?.user.accessGroups || undefined;
            this.availableRoutes = getAvailableRoutes(msg.checkSessionResponse.user.accessGroups);
            if (withRedirectToAvailablePage) {
              const route = getMainRouteByRole(msg.checkSessionResponse?.user?.accessGroups);
              if (this.navigate && this.clientId) this.navigate(route.replace(':clientId', this.clientId));
              setTimeout(() => this.setWaitingMessageFromApp(false), 0);
            } else if (redirectToPage) {
              window.location.href = redirectToPage;
              setTimeout(() => this.setWaitingMessageFromApp(false), 0);
            }
          } else {
            this.userAccessGroups = undefined;
            this.availableRoutes = getAvailableRoutes(undefined, true);
            if (loginPage && this.navigate && this.clientId) {
              this.navigate(ProtectedRoutes.MAIN_PAGE.replace(':clientId', this.clientId));
            }
          }
        } else {
          if (
            msg.error &&
            [
              protocol.ServerError.InvalidToken,
              protocol.ServerError.TokenExpired,
              protocol.ServerError.EmptyToken,
            ].includes(msg.error)
          ) {
            if (this.navigate && !loginPage) this.navigate(`/dashboard/user/${this.clientId}/login`);
          } else {
            this.errorPageText = formatServerErrors(msg.error);
            if (this.navigate) this.navigate(`/error`);
          }
        }
      },
    );
  };

  @action
  setTokenToLocalStorage = (token: string) => {
    this.token = token;
    window.localStorage?.setItem('token', token);
  };

  @action
  getTokenFromLocalStorage = () => {
    const token = window.localStorage?.getItem('token') ?? '';
    this.token = token ?? undefined;
    return token;
  };

  @action
  removeTokenFromLocalStorage = () => {
    window.localStorage?.removeItem('token');
  };

  @action
  logOut = () => {
    this.removeTokenFromLocalStorage();
    this.isAuthUser = false;
    this.token = undefined;
    this.isInitialized = false;
    if (this.navigate) this.navigate(`/dashboard/user/${this.clientId}/login`);
  };
}

export const store = new AppState();

export function useState(): AppState {
  return React.useContext(MobXProviderContext).store;
}
