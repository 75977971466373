import { makeObservable, observable, action, computed } from 'mobx';
import { AppState } from './AppState';
import { currencies, protocol } from '../api/proto';
import {
  convertAmountToApi,
  convertMapWithCurrencyNamesAsKeysToWlCurrency,
  currencyToChain,
  formatCurrency,
  formatWlCurrency,
  getCurrencyByCode,
  getCurrencyCode,
  getCurrencyCodeForConvert,
  getCurrencyIcon,
  getFullCurrencyCode,
  getWlCurrencyCode,
  isWlCurrency,
} from './utils/Money';
import { formatServerErrors } from './utils/format';
import { debounce } from './utils/debounce';
import { round } from './utils/round';
import { ISelectOption } from '../components/controls/Select/Select';
import { IBalanceTotalInfo, IBalanceTotalInfoRaw } from './MainPageInfoStore';
import { IAddressSelectOption } from '../components/withdrawal/AddressesSearchableSelectMultipleVirtualized/AddressesSearchableSelectMultipleVirtualized';

export class WithdrawalResult extends protocol.PayoutRequestRecord {
  constructor(result: protocol.IPayoutRequestRecord) {
    super();
    Object.assign(this, result);
    makeObservable(this);
  }

  @computed
  get iconName() {
    if (
      this.status === protocol.PayoutRequestStatus.COMPLETED_PAYOUT_REQUEST_STATUS ||
      this.status === protocol.PayoutRequestStatus.PENDING_PAYOUT_REQUEST_STATUS
    ) {
      return 'success';
    }
    if (
      this.status === protocol.PayoutRequestStatus.FAILED_PAYOUT_REQUEST_STATUS ||
      this.status === protocol.PayoutRequestStatus.UNK_PAYOUT_REQUEST_STATUS
    ) {
      return 'error';
    }
    return undefined;
  }

  @computed
  get title() {
    switch (this.status) {
      case protocol.PayoutRequestStatus.UNK_PAYOUT_REQUEST_STATUS:
        return 'Unknown status';
      case protocol.PayoutRequestStatus.PENDING_PAYOUT_REQUEST_STATUS:
        return 'Pending withdrawal';
      case protocol.PayoutRequestStatus.PROCESSING_PAYOUT_REQUEST_STATUS:
        return 'Processing payout withdrawal';
      case protocol.PayoutRequestStatus.COMPLETED_PAYOUT_REQUEST_STATUS:
        return 'Successful withdrawal';
      case protocol.PayoutRequestStatus.FAILED_PAYOUT_REQUEST_STATUS:
        return 'Unsuccessful withdrawal';
      default:
        return 'Unknown status';
    }
  }

  @computed
  get formattedAmount() {
    return this.requestedAmount && this.currency
      ? formatCurrency(Number(this.requestedAmount), this.currency, true)
      : undefined;
  }
}

interface IFee {
  feeCrypto?: string;
  feeFiat?: string;
  feeRate?: string;
}

interface ICheckTokenPayoutSystemBalanceInfo extends protocol.ICheckTokenPayoutSystemBalanceResponse {}

interface IClientAddress extends protocol.IClientAddress {
  formattedAmount?: string;
}

interface ISelectedTokenAddress extends protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedTokenAddress {
  formattedAmount?: string;
}

interface ISelectedNetworkFeeAddress extends protocol.CheckTokenPayoutSystemBalanceResponse.ISelectedNetworkFeeAddress {
  formattedFeeAmount?: string;
}

export type AddressToWithdrawFrom = {
  address: string;
  tags?: string[];
};

export class WithdrawalState {
  private appState: AppState;

  @observable withdrawalPageTab: 'form' | 'confirm' = 'form';
  @observable selectedCurrency?: protocol.Currency = undefined;
  @observable address: string = '';
  @observable amount?: string = '';
  @observable fee?: IFee;
  @observable feeIsLoading: boolean = false;
  @observable feeRequestError?: string;
  @observable currencyForAmount?: protocol.Currency | currencies.WLCurrency;
  @observable feeRate?: string;
  @observable totalBalance?: IBalanceTotalInfoRaw;

  @observable balancesLoading?: boolean = false;
  @observable addressesLoading?: boolean = false;

  @observable error?: string = '';
  @observable checkTokenPayoutRequestError?: string = '';

  @observable isWithdrawRequestLoading?: boolean = false;
  @observable withdrawalResult?: WithdrawalResult;

  @observable feeRatesIsLoading: boolean = false;
  @observable feeRatesList?: protocol.FeeRatesResponse.IRate[];
  @observable selectedFeeRateIndex?: number;

  @observable addressesTags: string[] = [];
  @observable selectedAddessesTags: string[] = [];

  @observable addressesList: IClientAddress[] = [];
  @observable selectedAddresses: string[] = [];

  @observable activateNewTronAddress: boolean = false;

  @observable tags: string[] = [];

  @observable checkTokenPayoutRequestLoading: boolean = false;
  @observable checkTokenPayoutSystemBalanceInfo?: ICheckTokenPayoutSystemBalanceInfo;
  @observable selectedTokenAddresses?: ISelectedTokenAddress[];
  @observable selectedNetworkFeeAddresses?: ISelectedNetworkFeeAddress[];

  convertCurrenciesAbortController = new AbortController();
  feeRequestAbortController = new AbortController();
  balancesAbortController = new AbortController();
  addressesAbortController = new AbortController();

  @observable addressesToWithdrawFrom?: AddressToWithdrawFrom[];

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @computed
  get selectedTokenAddressesAmountsSum() {
    if (this.selectedCurrency) {
      const amount =
        this.selectedTokenAddresses?.reduce((sum, _) => {
          return sum + Number(_.amount ?? 0);
        }, 0) ?? 0;
      return formatCurrency(amount, this.selectedCurrency);
    }
    return '-';
  }

  @computed
  get selectedNetworkFeeAddressesFeeSum() {
    if (this.selectedCurrency) {
      const nativeCurrency =
        this.selectedCurrency === protocol.Currency.ERC20_USDT_CUR
          ? protocol.Currency.ETH_CUR
          : protocol.Currency.TRX_CUR;
      const amount =
        this.selectedNetworkFeeAddresses?.reduce((sum, _) => {
          return sum + Number(_.feeAmount ?? 0);
        }, 0) ?? 0;
      return formatCurrency(amount, nativeCurrency);
    }
    return '-';
  }

  @computed
  get sendTrxForAutoActivationIsVisible() {
    return this.selectedCurrency && this.selectedCurrency === protocol.Currency.TRC20_USDT_CUR;
  }

  @computed
  get addressesTagsOptions(): ISelectOption[] {
    return this.addressesTags.map(_ => ({ label: _, value: _ }));
  }

  @computed
  get addressesOptions(): IAddressSelectOption[] {
    return this.addressesList.map(_ => ({ label: _.address ?? '', value: _.address ?? '', tags: _.tags ?? undefined }));
  }

  @computed
  get isAddressesSelectEnabled(): boolean {
    return !!(
      this.selectedCurrency &&
      (this.selectedCurrency === protocol.Currency.ERC20_USDT_CUR ||
        this.selectedCurrency === protocol.Currency.TRC20_USDT_CUR)
    );
  }

  @computed
  get isUsdtWithdraw(): boolean {
    return !!(
      this.selectedCurrency &&
      (this.selectedCurrency === protocol.Currency.ERC20_USDT_CUR ||
        this.selectedCurrency === protocol.Currency.TRC20_USDT_CUR)
    );
  }

  @computed
  get selectedCurrencyCode() {
    return this.selectedCurrency ? getFullCurrencyCode(this.selectedCurrency) : '';
  }

  @computed
  get currencyForAmountCode() {
    if (this.currencyForAmount) {
      if (isWlCurrency(this.currencyForAmount)) {
        return getWlCurrencyCode(this.currencyForAmount as currencies.WLCurrency);
      } else {
        return getFullCurrencyCode(this.currencyForAmount);
      }
    }
    return '';
  }

  @computed
  get currencyForAmountShortCode() {
    if (this.currencyForAmount) {
      if (isWlCurrency(this.currencyForAmount)) {
        return getWlCurrencyCode(this.currencyForAmount as currencies.WLCurrency);
      } else {
        return getCurrencyCode(this.currencyForAmount as protocol.Currency);
      }
    }
    return '';
  }

  @computed
  get selectedCurrencyCodeForIcon() {
    return this.selectedCurrency ? getCurrencyIcon(this.selectedCurrency) : '';
  }

  @computed
  get availableBalance() {
    let balance = 0;
    if (this.currencyForAmount && this.totalBalanceFormatted) {
      if (isWlCurrency(this.currencyForAmount)) {
        balance = this.totalBalanceFormatted?.amountFiat;
        if (balance) {
          return formatWlCurrency(balance, this.currencyForAmount as currencies.WLCurrency, true);
        }
      } else {
        balance = this.totalBalanceFormatted?.amount;
        if (balance && this.selectedCurrency) {
          return formatCurrency(balance, this.selectedCurrency, true);
        }
      }
    }

    return undefined;
  }

  @computed
  get totalBalanceFormatted(): IBalanceTotalInfo {
    const fiatAmount =
      this.totalBalance?.balanceFiat && this.totalBalance.balanceFiat[this.appState.selectedCurrencyForConvert]
        ? this.totalBalance.balanceFiat[this.appState.selectedCurrencyForConvert]
        : 0;
    return {
      amount: this.totalBalance?.amount ?? 0,
      amountFiat: Number(fiatAmount),
      amountFiatFormatted: formatWlCurrency(Number(fiatAmount), this.appState.selectedCurrencyForConvert),
    };
  }

  @computed
  get availableBalanceWithCurrency() {
    const balance = this.totalBalanceFormatted?.amount;
    if (this.selectedCurrency) {
      const convertedCurrencyAmount = this.totalBalanceFormatted.amountFiatFormatted;
      if (convertedCurrencyAmount) {
        return `${formatCurrency(balance, this.selectedCurrency)} (${convertedCurrencyAmount})`;
      }
      return formatCurrency(balance, this.selectedCurrency);
    }
    return undefined;
  }

  @computed
  get formattedAmountWithoutCurrency() {
    if (this.currencyForAmount) {
      if (isWlCurrency(this.currencyForAmount)) {
        return formatWlCurrency(Number(this.amount ?? 0), this.currencyForAmount as currencies.WLCurrency);
      } else {
        return formatCurrency(Number(this.amount ?? 0), this.currencyForAmount as protocol.Currency);
      }
    }
  }

  @computed
  get formIsValid() {
    if (!this.address) {
      return false;
    }
    if (!this.amountIsValid) {
      return false;
    }

    return true;
  }

  @computed
  get amountIsValid() {
    return this.amount && Number(this.amount) <= Number(this.availableBalance);
  }

  @action
  init = (currency: string) => {
    this.setCurrency(currency, true);
  };

  @action
  setCurrency = (currency: string, onInit?: boolean) => {
    const cur = getCurrencyByCode(currency);
    if (cur) {
      this.selectedCurrency = cur;
      this.currencyForAmount = cur;
      this.loadFee();
    }
    this.fetchBalances();
    this.loadAddressesTags();
    if (this.isAddressesSelectEnabled) {
      this.fetchAddresses();
    }
    this.amount = '';
    this.address = '';
    this.feeRate = undefined;
    this.selectedAddresses = [];
    this.resetError();
    this.selectedFeeRateIndex = undefined;
    if (!onInit) {
      this.addressesToWithdrawFrom = undefined;
    }
  };

  @action
  fetchBalances = () => {
    this.balancesAbortController?.abort();
    this.balancesAbortController = new AbortController();
    this.balancesLoading = true;
    this.appState.api.balancesTotalsRequest(
      {
        currency: this.selectedCurrency,
        tags: this.selectedAddessesTags,
        spendable: !this.addressesToWithdrawFrom?.length,
        addresses: this.addressesToWithdrawFrom?.map(_ => _.address),
      },
      this.onBalancesResponse,
      this.balancesAbortController,
    );
  };

  @action
  onBalancesResponse = (msg: protocol.IServerResponse) => {
    this.totalBalance = undefined;
    this.balancesLoading = false;
    if (msg.clientTotalBalancesResponse?.balances?.length) {
      const currentCurrencyBalance = msg.clientTotalBalancesResponse.balances.find(
        _ => _.currency === this.selectedCurrency,
      );
      if (currentCurrencyBalance) {
        this.totalBalance = {
          amount: Number(currentCurrencyBalance.balance),
          balanceFiat: convertMapWithCurrencyNamesAsKeysToWlCurrency(currentCurrencyBalance.balanceFiat),
        };
      }
    }
  };

  @action
  loadAddressesTags = () => {
    this.appState.api.clientAddressesTagsListRequest({}, (msg: protocol.IServerResponse) => {
      this.addressesTags = msg?.clientAddressesTagsListResponse?.tags ?? [];
    });
  };

  @action
  changeSelectedAddressesTags = (values: (string | number)[]) => {
    this.selectedAddessesTags = values as string[];
    this.balancesLoading = true;
    this.resetError();
    this.fetchBalancesAfterSelectTags();
  };

  @action
  changeSelectedAddresses = (values: (string | number)[]) => {
    this.resetError();
    this.selectedAddresses = values as string[];
  };

  @action
  toggleActivateNewTronAddress = () => {
    this.activateNewTronAddress = !this.activateNewTronAddress;
  };

  @action
  fetchBalancesAfterSelectTags = debounce(() => {
    this.fetchBalances();
  }, 2000);

  @action
  loadFee = () => {
    if (this.selectedCurrency && this.selectedCurrency === protocol.Currency.BTC_CUR) {
      this.feeRatesIsLoading = true;
      const chain = currencyToChain(this.selectedCurrency);
      this.appState.api.feeRatesRequest({ chain: chain }, (msg: protocol.IServerResponse) => {
        this.feeRatesIsLoading = false;
        if (msg?.feeRatesResponse?.rates?.length) {
          this.feeRatesList = msg.feeRatesResponse.rates.map(rate => ({
            targetBlocks: rate.targetBlocks,
            rate: rate.rate ? String(round(Number(rate.rate) / 1000)) : '0',
          }));
        }
      });
    }
  };

  @action
  selectFeeRate = (index: number) => {
    this.selectedFeeRateIndex = index;
    if (this.feeRatesList?.length && this.feeRatesList[index]) {
      this.feeRate = this.feeRatesList[index].rate || undefined;
      this.fetchTransactionFee();
    }
  };

  @action
  onChangeFeeRate = (rate: string) => {
    this.feeRate = rate;
    this.selectedFeeRateIndex = undefined;
    this.fetchTransactionFee();
  };

  @action
  onChangeAddress = (address: string) => {
    this.address = address.replace(/\s/g, '');
    this.fetchTransactionFee();
  };

  @action
  onChangeAmount = (amount: string) => {
    this.amount = amount;
    this.fetchTransactionFee();
  };

  @action
  setAllBalanceAmount = () => {
    if (this.availableBalance) {
      this.amount = this.availableBalance;
      this.fetchTransactionFee();
    }
  };

  @action
  changeAddressesToWithdrawFrom = (addresses: AddressToWithdrawFrom[]) => {
    this.addressesToWithdrawFrom = addresses;
  };

  @action
  onChangeTags = (tags: string[]) => {
    this.tags = tags;
  };

  @action
  fetchTransactionFee = debounce(() => {
    if (this.selectedCurrency && this.selectedCurrency === protocol.Currency.BTC_CUR) {
      this.feeRequestAbortController.abort();
      this.transactionFeeRequest();
    }
  }, 700);

  @action
  transactionFeeRequest = () => {
    if (!this.amountIsValid && this.amount) {
      this.feeRequestError = 'Not enough balance';
      this.feeIsLoading = false;
    }
    if (
      this.currencyForAmount &&
      this.selectedCurrency &&
      this.amountIsValid &&
      this.selectedCurrency === protocol.Currency.BTC_CUR
    ) {
      this.feeIsLoading = true;
      this.fee = undefined;
      this.feeRequestError = undefined;

      if (isWlCurrency(this.currencyForAmount)) {
        this.convertCurrenciesAbortController.abort();
        this.convertCurrenciesAbortController = new AbortController();
        this.appState.api.convertCurrencies(
          [
            {
              fromCurrencyCode: this.currencyForAmount,
              amount:
                this.amount && this.currencyForAmount ? convertAmountToApi(this.amount, this.currencyForAmount) : '0',
              toCurrencyCode: getCurrencyCodeForConvert(this.selectedCurrency),
            },
          ],
          (msg: protocol.IServerResponse) => {
            if (msg?.convertCryptoToFiatResponse?.entries?.length) {
              const entry = msg.convertCryptoToFiatResponse.entries[0];
              if (
                entry.fromCurrencyCode === this.currencyForAmount &&
                entry.toCurrencyCode === getCurrencyCodeForConvert(this.selectedCurrency!) &&
                entry.amount
              ) {
                this.feeRequestAbortController = new AbortController();
                this.appState.api.transactionFeeRequest(
                  {
                    currency: this.selectedCurrency,
                    address: this.address,
                    amount: this.amount && this.selectedCurrency ? entry.amount : '0',
                    feeRate: this.feeRateForApi,
                  },
                  this.onTransactionFeeResponse,
                  this.feeRequestAbortController,
                );
              }
            }
          },
          this.convertCurrenciesAbortController,
        );
      } else {
        this.feeRequestAbortController = new AbortController();
        this.appState.api.transactionFeeRequest(
          {
            currency: this.selectedCurrency,
            address: this.address ?? undefined,
            amount: this.amount && this.selectedCurrency ? convertAmountToApi(this.amount, this.selectedCurrency) : '0',
            feeRate: this.feeRateForApi,
          },
          this.onTransactionFeeResponse,
          this.feeRequestAbortController,
        );
      }
    }
  };

  @action
  onTransactionFeeResponse = (msg: protocol.IServerResponse) => {
    if (msg?.transactionFeeResponse?.fee && !msg?.error) {
      this.fee = {
        feeCrypto: this.selectedCurrency
          ? formatCurrency(Number(msg.transactionFeeResponse.fee), this.selectedCurrency)
          : undefined,
        feeRate: msg.transactionFeeResponse.feeRate
          ? round(Number(msg.transactionFeeResponse.feeRate) / 1000) + ' sat/b'
          : undefined,
        feeFiat: undefined,
      };
      const currencyForAmount = getCurrencyCodeForConvert(Number(this.selectedCurrency));
      this.appState.api.convertCurrencies(
        [
          {
            fromCurrencyCode: currencyForAmount,
            amount: this.amount && this.selectedCurrency ? msg.transactionFeeResponse.fee : '0',
            toCurrencyCode: this.appState.selectedCurrencyForConvert,
          },
        ],
        (msg: protocol.IServerResponse) => {
          this.feeIsLoading = false;
          if (msg?.convertCryptoToFiatResponse?.entries?.length) {
            const entry = msg.convertCryptoToFiatResponse.entries[0];
            if (
              entry.fromCurrencyCode === currencyForAmount &&
              entry.toCurrencyCode === this.appState.selectedCurrencyForConvert &&
              entry.amount
            ) {
              this.fee!.feeFiat = formatWlCurrency(Number(entry.amount), this.appState.selectedCurrencyForConvert);
            }
          }
        },
      );
    } else if (msg?.error) {
      if (msg.error === protocol.ServerError.AmountTooSmall) {
        this.feeRequestError = 'Not enought money for comission';
      } else {
        this.feeRequestError = formatServerErrors(msg.error);
        if (msg.error === protocol.ServerError.NotEnoughBalance) {
          this.fetchBalances();
        }
      }
      this.feeIsLoading = false;
    } else {
      this.feeIsLoading = false;
    }
  };

  @computed
  get feeRateForApi() {
    return this.feeRate ? String(Number(this.feeRate) * 1000) : undefined;
  }

  @action
  goToConfirm = async () => {
    if (
      this.selectedCurrency === protocol.Currency.ERC20_USDT_CUR ||
      this.selectedCurrency === protocol.Currency.TRC20_USDT_CUR
    ) {
      const amount = await this.getAmountCurrencyForRequest();
      if (amount) {
        this.checkTokenPayoutSystemBalances(amount, true);
      }
    } else {
      this.withdrawalPageTab = 'confirm';
    }
  };

  @action
  goToForm = () => {
    this.withdrawalPageTab = 'form';
    this.resetError();
    this.resetTokenPayoutRequestInfo();
  };

  @action
  getAmountCurrencyForRequest = async (): Promise<string | null> => {
    return await new Promise(resolve => {
      if (!this.currencyForAmount) {
        resolve(null);
        return;
      }
      if (isWlCurrency(this.currencyForAmount)) {
        const toCurrencyCode = getCurrencyCodeForConvert(Number(this.selectedCurrency));
        this.appState.api.convertCurrencies(
          [
            {
              fromCurrencyCode: this.currencyForAmount,
              amount:
                this.amount && this.selectedCurrency
                  ? convertAmountToApi(Number(this.amount), this.currencyForAmount)
                  : '0',
              toCurrencyCode: toCurrencyCode,
            },
          ],
          (msg: protocol.IServerResponse) => {
            if (msg?.convertCryptoToFiatResponse?.entries?.length) {
              const entry = msg.convertCryptoToFiatResponse.entries[0];
              if (
                entry.fromCurrencyCode === this.currencyForAmount &&
                entry.toCurrencyCode === toCurrencyCode &&
                entry.amount
              ) {
                resolve(entry.amount);
              }
            }
            resolve(null);
          },
        );
      } else {
        const amount =
          this.amount && this.selectedCurrency ? convertAmountToApi(this.amount, this.selectedCurrency) : '0';
        resolve(amount);
      }
    });
  };

  @action
  onWithdrawalClick = async () => {
    if (this.formIsValid) {
      if (this.currencyForAmount) {
        this.resetError();

        this.isWithdrawRequestLoading = true;
        const amount = await this.getAmountCurrencyForRequest();
        if (amount) {
          if (
            (this.selectedCurrency === protocol.Currency.ERC20_USDT_CUR ||
              this.selectedCurrency === protocol.Currency.TRC20_USDT_CUR) &&
            !this.checkTokenPayoutRequestError
          ) {
            this.checkTokenPayoutSystemBalances(amount);
          } else {
            this.withdrawRequest(amount);
          }
        }
      }
    }
  };

  @action
  checkTokenPayoutSystemBalances = (amount?: string | null, preview?: boolean) => {
    this.checkTokenPayoutRequestLoading = true;

    this.appState.api.checkTokenPayoutSystemBalanceRequest(
      {
        currency: this.selectedCurrency,
        to: this.address,
        amount: amount ?? '0',
        tagsToWithdrawFrom: this.selectedAddessesTags?.length ? this.selectedAddessesTags : undefined,
        networkFeeAddresses: this.selectedAddresses?.length ? this.selectedAddresses : undefined,
        addressesToWithdrawFrom: this.addressesToWithdrawFrom?.map(_ => _.address),
      },
      msg => this.checkTokenPayoutSystemResponse(msg, preview, amount),
    );
  };

  @computed
  get networkFeeAddressesWarning() {
    if (
      this.checkTokenPayoutRequestError &&
      this.selectedNetworkFeeAddresses?.length &&
      this.selectedNetworkFeeAddresses.some(_ => Number(_.feeAmount ?? 0) !== 0)
    ) {
      return 'Since withdrawal is not possible at the moment, the list of these addresses may change';
    }
    return null;
  }

  @action
  checkTokenPayoutSystemResponse = (msg: protocol.IServerResponse, preview?: boolean, amount?: string | null) => {
    const nativeCurrency =
      this.selectedCurrency === protocol.Currency.ERC20_USDT_CUR
        ? protocol.Currency.ETH_CUR
        : protocol.Currency.TRX_CUR;
    this.checkTokenPayoutRequestLoading = false;
    this.resetTokenPayoutRequestInfo();
    if (msg.error && this.selectedCurrency) {
      this.isWithdrawRequestLoading = false;
      if (msg.error === protocol.ServerError.NotEnoughBalance) {
        if (msg.checkTokenPayoutSystemBalanceResponse?.refillSystemAddresses?.length) {
          this.checkTokenPayoutSystemBalanceInfo = {
            refillSystemAddresses: msg.checkTokenPayoutSystemBalanceResponse.refillSystemAddresses.map(address => ({
              address: address.address,
              tag: address.tag,
              balance: formatCurrency(Number(address.balance ?? 0), nativeCurrency!),
              availableBalance: formatCurrency(Number(address.availableBalance ?? 0), nativeCurrency!),
            })),
            requiredAmount: formatCurrency(
              Number(msg.checkTokenPayoutSystemBalanceResponse.requiredAmount ?? 0),
              nativeCurrency!,
            ),
          };
          this.checkTokenPayoutRequestError = this.selectedAddresses?.length
            ? 'Not enough funds on specified fee address'
            : 'Not enough funds on system addresses';
        } else {
          this.checkTokenPayoutRequestError = this.selectedAddresses?.length
            ? 'Not enough funds on specified fee address'
            : 'Not enougth USDT for such a withdrawal';
        }
      } else {
        this.error = formatServerErrors(msg.error);
        return;
      }
    } else if (!preview) {
      this.withdrawRequest(amount);
    }
    if (msg.checkTokenPayoutSystemBalanceResponse?.selectedTokenAddresses?.length && this.selectedCurrency) {
      this.selectedTokenAddresses = msg.checkTokenPayoutSystemBalanceResponse?.selectedTokenAddresses?.map(_ => ({
        ..._,
        formattedAmount: formatCurrency(Number(_.amount ?? 0), this.selectedCurrency!),
      }));
    }
    if (msg.checkTokenPayoutSystemBalanceResponse?.selectedNetworkFeeAddresses?.length && this.selectedCurrency) {
      this.selectedNetworkFeeAddresses = msg.checkTokenPayoutSystemBalanceResponse?.selectedNetworkFeeAddresses?.map(
        _ => ({
          ..._,
          formattedFeeAmount: formatCurrency(Number(_.feeAmount ?? 0), nativeCurrency!),
        }),
      );
    }

    this.withdrawalPageTab = 'confirm';
    console.log(this.selectedTokenAddresses);
  };

  @action
  withdrawRequest = (amount?: string | null) => {
    this.appState.api.withdrawRequest(
      {
        currency: this.selectedCurrency,
        to: this.address,
        tags: this.tags?.length ? this.tags : undefined,
        amount: amount ?? '0',
        feeRate: this.feeRateForApi,
        tagsToWithdrawFrom: this.selectedAddessesTags?.length ? this.selectedAddessesTags : undefined,
        networkFeeAddresses: this.selectedAddresses?.length ? this.selectedAddresses : undefined,
        activateNewTronAddress: this.sendTrxForAutoActivationIsVisible ? this.activateNewTronAddress : undefined,
        addressesToWithdrawFrom: this.addressesToWithdrawFrom?.map(_ => _.address),
      },
      this.onWithdrawalResponse,
    );
  };

  @action
  onWithdrawalResponse = (msg: protocol.IServerResponse) => {
    this.isWithdrawRequestLoading = false;
    this.resetTokenPayoutRequestInfo();

    if (msg.enqueuePayoutResponse?.payoutRequest) {
      this.withdrawalResult = new WithdrawalResult(msg.enqueuePayoutResponse.payoutRequest);
      this.appState.navigate!(
        `/dashboard/user/${this.appState.clientId}/withdrawal/${getFullCurrencyCode(
          Number(this.selectedCurrency),
        )}/result`,
      );
      if (msg.enqueuePayoutResponse.payoutRequest.payouts?.length) {
        const txIds: number[] = msg.enqueuePayoutResponse.payoutRequest.payouts
          .filter(_ => !!_.txId)
          .map(_ => _.txId) as number[];
        if (txIds?.length) {
          this.appState.api.modifyTagsRequest(
            {
              txIds,
              tagName: 'adm:withdrawal',
            },
            () => {},
          );
        }
      }
    } else if (msg.error) {
      if (msg.error === protocol.ServerError.EmptyAddressesList) {
        this.error = `There are no ${getFullCurrencyCode(
          Number(this.selectedCurrency),
        )} addresses with a balance, for such a withdrawal`;
      } else {
        this.error = formatServerErrors(msg.error);
      }
      this.withdrawalPageTab = 'form';
    } else {
      this.error = 'Unknown error';
      this.withdrawalPageTab = 'form';
    }
  };

  @action
  fetchAddresses = () => {
    this.addressesAbortController?.abort();
    this.addressesAbortController = new AbortController();
    this.addressesLoading = true;
    const currency =
      this.selectedCurrency === protocol.Currency.ERC20_USDT_CUR
        ? protocol.Currency.ETH_CUR
        : protocol.Currency.TRX_CUR;
    this.appState.api.clientAddressesListRequest(
      {
        currency,
        includeSystemAddresses: true,
      },
      this.onAddressesResponse,
      this.addressesAbortController,
    );
  };

  @action
  onAddressesResponse = (msg: protocol.IServerResponse) => {
    this.addressesLoading = false;
    this.addressesList =
      msg.clientAddressesListResponse?.addresses?.map(_ => {
        const currency =
          _.currency === protocol.Currency.ERC20_USDT_CUR || _.currency === protocol.Currency.ETH_CUR ? 'ETH' : 'TRX';

        const balance = _.balances && _.currency ? _.balances[currency]?.balance : undefined;
        return {
          ..._,
          formattedAmount: _.currency
            ? formatCurrency(
                Number(balance ?? 0),
                _.currency === protocol.Currency.ERC20_USDT_CUR || _.currency === protocol.Currency.ETH_CUR
                  ? protocol.Currency.ETH_CUR
                  : protocol.Currency.TRX_CUR,
              )
            : '',
        };
      }) ?? [];
  };

  @action
  resetWithdrawal = () => {
    this.amount = '';
    this.address = '';
    this.resetError();
    this.fee = undefined;
    this.feeRate = undefined;
    this.feeRatesList = undefined;
    this.selectedFeeRateIndex = undefined;
    this.feeIsLoading = false;
    this.isWithdrawRequestLoading = false;
    this.feeRequestError = undefined;
    this.feeRequestAbortController?.abort();
    this.convertCurrenciesAbortController?.abort();
    this.balancesAbortController?.abort();
    this.addressesAbortController?.abort();
    this.addressesTags = [];
    this.selectedAddessesTags = [];
    this.tags = [];
    this.checkTokenPayoutSystemBalanceInfo = undefined;
    this.balancesLoading = false;
    this.addressesLoading = false;
    this.withdrawalPageTab = 'form';
    this.activateNewTronAddress = false;
    this.checkTokenPayoutRequestLoading = false;
    this.resetTokenPayoutRequestInfo();
    this.addressesToWithdrawFrom = undefined;
  };

  @action
  resetWithdrawalResult = () => {
    this.withdrawalResult = undefined;
  };

  @action
  setCurrencyForAmount = (currency: protocol.Currency | currencies.WLCurrency) => {
    this.currencyForAmount = currency;
    this.fee = undefined;
    this.amount = '';
    this.feeIsLoading = false;
    this.feeRequestError = undefined;
    this.feeRequestAbortController?.abort();
    this.convertCurrenciesAbortController?.abort();
  };

  @action
  resetError = () => {
    this.error = undefined;
  };

  @action
  resetTokenPayoutRequestInfo = () => {
    this.checkTokenPayoutSystemBalanceInfo = undefined;
    this.selectedTokenAddresses = undefined;
    this.selectedNetworkFeeAddresses = undefined;
    this.checkTokenPayoutRequestError = undefined;
  };
}
