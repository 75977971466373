import { computed, makeObservable, observable } from 'mobx';
import { protocol } from '../api/proto';
import { AppState } from './AppState';
import { formatCurrency, getCurrencyCode, getCurrencyIcon, getCurrencyTitle } from './utils/Money';
import { IAddressWithTags, Operation } from './Operation';

export interface IPayoutRequestRecord extends protocol.PayoutRequestRecord {}

export class PayoutRequestRecord extends protocol.PayoutRequestRecord implements IPayoutRequestRecord {
  constructor(private appState: AppState, props: protocol.IPayoutRequestRecord, childs?: PayoutRequestRecord[]) {
    super(props);
    Object.assign(this, props);
    makeObservable(this);
    if (childs) {
      this.childs = childs;
    }
  }

  @observable childs?: PayoutRequestRecord[];
  @observable childsIsOpen: boolean = false;

  @computed
  get network(): string {
    return protocol.Chain[this.chain ?? protocol.Chain.UNK_CHAIN].replace('_CHAIN', '');
  }

  @computed
  get formattedAmount(): string {
    return formatCurrency(Number(this.requestedAmount), this.currency, true);
  }

  @computed
  get formattedAmountWithCurrency(): string {
    return formatCurrency(Number(this.requestedAmount), this.currency);
  }

  @computed
  get formattedAmountForSort(): number {
    return Number(formatCurrency(Number(this.requestedAmount), this.currency, true));
  }

  @computed
  get formattedCurrency(): string {
    return getCurrencyTitle(this.currency);
  }

  @computed
  get currencyCode(): string {
    return getCurrencyCode(this.currency);
  }

  @computed
  get currencyIconName(): string {
    return getCurrencyIcon(this.currency);
  }

  @computed
  get formattedDate(): string {
    return new Date(this.updatedAt?.multiply(1000).toNumber() ?? '').toLocaleString();
  }

  @computed
  get updatedAtNumber(): number {
    return this.updatedAt?.multiply(1000).toNumber() ?? 0;
  }

  @computed
  get formattedDateOnly(): string {
    return new Date(this.updatedAt?.multiply(1000).toNumber() ?? '').toLocaleDateString();
  }

  @computed
  get formattedTimeOnly(): string {
    return new Date(this.updatedAt?.multiply(1000).toNumber() ?? '').toLocaleTimeString();
  }

  @computed
  get formattedStatus(): string {
    if (this.manualActionStatusChild) {
      return this.getStatus(
        this.manualActionStatusChild.status,
        this.manualActionStatusChild,
        this.manualActionStatusChild.formattedAmountWithCurrency,
      );
    }
    return this.getStatus(this.status, this, this.formattedAmountWithCurrency);
  }

  @computed
  get manualActionStatusChild(): PayoutRequestRecord | undefined | null {
    if (this.childs?.length) {
      return this.childs.find(
        _ =>
          _.status === protocol.PayoutRequestStatus.PENDING_PAYOUT_REQUEST_STATUS &&
          !_.hasEnoughFunds &&
          _.refillSystemAddresses?.length,
      );
    }
    return undefined;
  }

  @computed
  get refillSystemAddressesComputed(): protocol.IPRSystemAddress[] {
    if (this.manualActionStatusChild) {
      return this.manualActionStatusChild.refillSystemAddresses;
    }
    return this.refillSystemAddresses;
  }

  getStatus = (status?: protocol.PayoutRequestStatus, record?: PayoutRequestRecord, amount?: string) => {
    switch (this.status) {
      case protocol.PayoutRequestStatus.UNK_PAYOUT_REQUEST_STATUS:
        return 'Unknown';
      case protocol.PayoutRequestStatus.CANCELED_PAYOUT_REQUEST_STATUS:
        return 'Canceled';
      case protocol.PayoutRequestStatus.PENDING_PAYOUT_REQUEST_STATUS:
        const customTextPending = this.getManualActionTextIfNeeded(record, amount);
        return customTextPending ?? 'Pending';
      case protocol.PayoutRequestStatus.PROCESSING_PAYOUT_REQUEST_STATUS:
        const customTextProcessing = this.getManualActionTextIfNeeded(record, amount);
        return customTextProcessing ?? 'Processing';
      case protocol.PayoutRequestStatus.COMPLETED_PAYOUT_REQUEST_STATUS:
        return 'Completed';
      case protocol.PayoutRequestStatus.FAILED_PAYOUT_REQUEST_STATUS:
        return 'Failed';
    }
  };

  getManualActionTextIfNeeded = (record?: PayoutRequestRecord, amount?: string) => {
    if (!record?.hasEnoughFunds && record?.refillSystemAddresses?.length) {
      if (amount) {
        return record?.refillSystemAddresses?.length > 1
          ? `Manual Action: Refill any system address from the list below (${amount})`
          : `Manual Action: Refill system address (${amount})`;
      }
      return 'Manual Action: Refill system address';
    }
    return null;
  };

  @computed
  get fromAddressesWithTags(): IAddressWithTags[] | undefined {
    return this.payouts
      ?.filter(_ => !!_.from)
      .map(payout => ({
        address: payout.from!,
        tags: payout.addressTags ?? undefined,
      }));
  }

  @computed
  get toAddresses(): IAddressWithTags[] | undefined {
    return [{ address: this.to }];
  }
}
