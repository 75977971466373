import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useState } from '../../state/AppState';
import { currenciesOptionsForConvert } from '../../state/utils/Money';
import { Select } from '../controls/Select/Select';
import cn from 'classnames';
import './Header.scss';
import { Icon } from '../Icon/Icon';
import useComponentVisible from '../../hooks/useComponentVisible';
import React, { useEffect } from 'react';
import moment from 'moment';
import { formatSecondsToTime } from '../../state/utils/DateTime';
import { ProtectedRoutes } from '../../state/Routes';
import { Loader } from '../Loader/Loader';

export const Header = observer(() => {
  const state = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <header
      className={
        state.currentProtectedRoute === ProtectedRoutes.MAIN_PAGE && state.mainPageInfo.updateBlockVisible
          ? 'with-updates-block'
          : ''
      }
    >
      <div className="Header-top">
        <div onClick={() => setIsComponentVisible(true)} className="Header__menu-icon">
          <Icon iconName="menu" />
        </div>
        <div className="Header__left-block">
          <div className="Header__logo" />
          <div className="Header__client-id">Client ID: {state.clientId || ''}</div>
        </div>
        <div className="Header__right-block">
          <div className="currency">
            <div className="title">Currency for convert</div>
            <Select
              options={currenciesOptionsForConvert}
              onChange={state.changeCurrencyForConvert}
              value={state.selectedCurrencyForConvert}
            />
          </div>
          {state.currentProtectedRoute === ProtectedRoutes.MAIN_PAGE && state.mainPageInfo.updateBlockVisible ? (
            <HeaderUpdatesBlock
              isLoading={state.mainPageInfo.isLoading}
              onUpdate={state.mainPageInfo.onUpdateClick}
              time={state.mainPageInfo.lastCheckTimeFormatted}
            />
          ) : null}
          {state.isAuthUser ? (
            <div className="Header__logout-btn" onClick={state.logOut}>
              Log out
            </div>
          ) : null}
        </div>
      </div>
      {state.availableRoutesMenuItems?.length && state.clientId ? (
        <div className={cn('Header-menu__container', { isOpened: isComponentVisible })}>
          <div className="Header-menu" ref={ref}>
            <div className="Header-menu__closeBtn" onClick={() => setIsComponentVisible(false)}>
              <Icon iconName="close-modal" widthInPx={20} heightInPx={20} />
            </div>
            <div className="Header-menu__list">
              {state.availableRoutesMenuItems.map(item => (
                <Link
                  onClick={() => setIsComponentVisible(false)}
                  key={item.route}
                  to={item.route.replace(':clientId', state.clientId!)}
                >
                  <div
                    className={cn('MenuItem', {
                      active: state.currentProtectedRoute
                        ? state.currentProtectedRoute === item.route ||
                          item.additionalRoutes?.includes(state.currentProtectedRoute)
                        : false,
                    })}
                  >
                    {item.title}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </header>
  );
});

const HeaderUpdatesBlock = ({
  time,
  onUpdate,
  isLoading,
}: {
  time?: string;
  onUpdate: () => void;
  isLoading?: boolean;
}) => {
  return (
    <div className="Header__update-block">
      <Icon iconName="warn" widthInPx={16} heightInPx={16} />
      <div className="Header__update-block__content">
        <div className="Header__update-block__top">Up-to-date data is available</div>
        <div className="Header__update-block__bottom">The last update was {time} ago</div>
      </div>
      <div className="Header__update-block__btn" onClick={onUpdate}>
        {isLoading ? <Loader /> : <Icon iconName="update" widthInPx={16} heightInPx={16} />}
        Update
      </div>
    </div>
  );
};
