import { observer } from 'mobx-react';
import { useState } from '../../../state/AppState';
import {
  getRiskLevelForChartByApiRiskLevel,
  getSourcesMainStatByRiskLevelForAddress,
} from '../../../state/utils/amlUtils';
import { AmlSignalsStatistics } from '../AmlSignalsStatistics/AmlSignalsStatistics';
import { RiskScoreChart } from '../RiskScoreChart/RiskScoreChart';
import './AmlReportAddressStatistics.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { protocol } from '../../../api/proto';

interface IAmlReportAddressStatisticsProps {
  isLoading?: boolean;
  riskScore?: number | null;
  riskLevel?: protocol.AMLRiskLevel | null;
  sources?: { [k: string]: protocol.IAMLSource } | null;
}

export const AmlReportAddressStatistics = observer(
  ({ isLoading, riskScore, riskLevel, sources }: IAmlReportAddressStatisticsProps) => {
    const state = useState();
    const signals = getSourcesMainStatByRiskLevelForAddress(sources || {});
    return (
      <div className="AmlReportAddressStatistics">
        <div className="AmlReportAddressStatistics__title">Sources statistics</div>
        <div className="AmlReportAddressStatistics__content">
          {isLoading ? (
            <Skeleton width={220} borderRadius={16} duration={0.8} highlightColor={'#FFFFFF'} height={220} count={1} />
          ) : (
            <RiskScoreChart
              riskLevelLabel
              riskLevel={getRiskLevelForChartByApiRiskLevel(riskLevel || undefined)}
              percent={riskScore ? Math.round(riskScore * 100) : 0}
            />
          )}
          <AmlSignalsStatistics isLoading={isLoading} signalsStatistics={signals} />
        </div>
      </div>
    );
  },
);
