import { action, computed, makeObservable, observable } from 'mobx';
import { currencies, protocol } from '../api/proto';
import { ISelectOption } from '../components/controls/Select/Select';
import { AppState } from './AppState';
import { NotificationType } from './NotificationsState';
import { formatServerErrors } from './utils/format';
import { convertAmountToApi } from './utils/Money';
import { emailIsValid } from './utils/validations';

type CreateInvoiceStep = 'creating-form' | 'success-created-peer';
type CreateInvoiceType = 'new-peer' | 'existing-peer';

export class InvoiceCreatingStore {
  private appState: AppState;

  @observable creatingInvoiceStep: CreateInvoiceStep = 'creating-form';
  @observable creatingInvoiceType: CreateInvoiceType = 'new-peer';

  @observable peersList?: protocol.IClientPeer[];

  @observable peerId?: number;
  @observable peerName: string = '';
  @observable amount: string = '';
  @observable email: string = '';
  @observable newPeerCurrency: protocol.Currency = protocol.Currency.UNK_CUR;
  @observable additionalCurrencies: protocol.FiatCurrency[] = [];

  @observable createPeerError?: string;
  @observable peerFieldError?: boolean;
  @observable peer?: protocol.IClientPeer;
  @observable peerAddresses?: protocol.IClientPeerAddress[] | null;

  @observable peerAddressesIsLoading: boolean = false;
  @observable regenerateAddress?: string;
  @observable currencyForNewAddress?: number;

  @observable creatingNewAddressLoading: boolean = false;
  @observable createInvoiceLoading: boolean = false;
  @observable createAddressError?: string;

  constructor(appState: AppState) {
    makeObservable(this);
    this.appState = appState;
  }

  @action
  init() {
    this.loadPeers();
  }

  @action
  loadPeers() {
    this.appState.api.clientPeersShortListRequest({}, (msg: protocol.IServerResponse) => {
      if (msg?.clientPeersShortListResponse?.peers?.length) {
        this.peersList = msg.clientPeersShortListResponse.peers;
      }
    });
  }

  @computed
  get peersListOptions(): ISelectOption[] {
    return (
      this.peersList
        ?.filter(peer => peer && peer.name && peer.id !== undefined && peer.id !== null)
        .map(peer => ({
          label: peer.name!,
          value: peer.id!,
        })) ?? []
    );
  }

  @action
  onChangeCreatingType = (type: CreateInvoiceType) => {
    this.creatingInvoiceType = type;
  };

  @action
  onChangeCreatingStep = (step: CreateInvoiceStep) => {
    this.creatingInvoiceStep = step;
  };

  @action
  onChangeAmount = (amount: string) => {
    this.amount = amount;
  };

  @action
  onChangePeerId = (peerId?: number | string) => {
    this.peerId = peerId ? Number(peerId) : undefined;
    const peer = this.peersList?.find(_ => _.id === this.peerId);
    this.peer = peer ?? undefined;
    this.peerAddresses = peer?.addresses;
  };

  @action
  onChangePeerCurrency = (currency?: number | string) => {
    this.newPeerCurrency = currency as number;
  };

  @action
  onChangePeerName = (peerName: string) => {
    this.peerName = peerName;
    this.peerFieldError = false;
  };

  @action
  onChangeEmail = (email: string) => {
    this.email = email;
  };

  @action
  onChangeAdditionalCurrencies = (values: (number | string)[]) => {
    this.additionalCurrencies = values as number[];
  };

  @action
  onChangeCurrencyForNewAddress = (value?: string | number) => {
    this.currencyForNewAddress = value as protocol.Currency;
  };

  @computed
  get isSubmitButtonEnabled() {
    if (this.creatingInvoiceType === 'new-peer') {
      return this.peerName && this.emailIsValid;
    } else {
      return this.currencyForNewAddress && this.peer?.id && this.emailIsValid;
    }
  }

  @computed
  get emailIsValid() {
    if (!this.email) return true;
    return emailIsValid(this.email);
  }

  @action
  onSubmitFormClick = () => {
    if (this.creatingInvoiceType === 'new-peer') {
      this.appState.api.createClientPeerRequest(
        {
          name: this.peerName,
          currency: this.newPeerCurrency ? this.newPeerCurrency : undefined,
          amountUsd: this.amount ? convertAmountToApi(this.amount, currencies.WLCurrency.WLC_USD) : undefined,
          email: this.email,
          additionalCurrencies: this.additionalCurrencies,
        },
        this.onCreatePeerResponse,
      );
    } else {
      this.onSubmitNewAddress();
    }
  };

  @action
  onCreatePeerResponse = (msg: protocol.IServerResponse) => {
    if (msg?.createClientPeerResponse?.peer) {
      this.peer = msg?.createClientPeerResponse?.peer;
      this.peerAddresses = msg?.createClientPeerResponse?.peer?.addresses;
      this.creatingInvoiceStep = 'success-created-peer';
      this.initCreateInvoiceStep();
    } else {
      if (msg.error && msg.error === protocol.ServerError.DuplicateRecord) {
        this.peerFieldError = true;
      }
      this.createPeerError = formatServerErrors(msg.error);
    }
  };

  @action
  onSubmitCreateInvoiceClick = () => {
    if (this.appState.navigate) {
      this.appState.navigate(`/dashboard/user/${this.appState.clientId}/invoices/peer/${this.peer?.id}`);
    }
    /*if (this.peer?.id && this.amount) {
      this.createInvoiceLoading = true;
      this.appState.api.createClientPeerInvoiceRequest(
        {
          peerId: this.peer?.id,
          amountUsd: convertAmountToApi(this.amount, currencies.WLCurrency.WLC_USD),
          email: this.email,
        },
        this.onCreateInvoiceResponse,
      );
    }*/
  };

  /* @action
  onCreateInvoiceResponse = (msg: protocol.IServerResponse) => {
    this.createInvoiceLoading = false;
    if (msg?.createClientPeerInvoiceResponse?.invoice) {
      this.appState.notifications.addNotification('Invoice successfully created', NotificationType.SUCCESS);
      if (this.appState.navigate)
        this.appState.navigate(`/dashboard/user/${this.appState.clientId}/invoices/peer/${this.peer?.id}`);
    } else {
      this.createInvoiceError = formatServerErrors(msg.error);
    }
  };*/

  @action
  onSubmitNewAddress = () => {
    if (this.currencyForNewAddress) {
      this.creatingNewAddressLoading = true;
      this.appState.api.generateNewClientPeerAddressRequest(
        {
          peerId: this.peer?.id,
          currency: this.currencyForNewAddress,
          amountUsd: this.amount ? convertAmountToApi(this.amount, currencies.WLCurrency.WLC_USD) : undefined,
          email: this.email,
        },
        (msg: protocol.IServerResponse) => {
          this.creatingNewAddressLoading = false;
          if (msg?.generateNewClientPeerAddressResponse?.address && this.appState.navigate) {
            this.appState.navigate(`/dashboard/user/${this.appState.clientId}/invoices/peer/${this.peer?.id}`);
            this.appState.notifications.addNotification('Address successfully created', NotificationType.SUCCESS);
          } else {
            this.createAddressError = formatServerErrors(msg.error);
          }
        },
      );
    }
  };

  @action
  initCreateInvoiceStep = () => {
    if (this.creatingInvoiceType === 'new-peer') {
      if (!this.peer?.addresses?.length) {
        this.fetchingPeerDetailsBeforeGettingAddresses();
      }
    }
  };

  @action
  fetchingPeerDetailsBeforeGettingAddresses = () => {
    if (this.peer?.id) {
      let timer = 30;
      const interval = window.setInterval(() => {
        timer--;
        if (timer <= 0) {
          window.clearInterval(interval);
        }
      }, 1000);
      this.peerAddressesIsLoading = true;
      const fetchFunc = () => {
        this.appState.api.clientPeerDetailsRequest({ id: this.peer?.id }, (msg: protocol.IServerResponse) => {
          if (msg?.clientPeerDetailsResponse?.addresses?.length) {
            this.peerAddresses = msg.clientPeerDetailsResponse.addresses;
            this.peerAddressesIsLoading = false;
          } else if (timer > 0) {
            fetchFunc();
          } else {
            this.peerAddressesIsLoading = false;
          }
        });
      };
      fetchFunc();
    }
  };

  @action
  generateNewAddress = (oldAddress: protocol.IClientPeerAddress) => {
    if (this.peer?.addresses && oldAddress.address) {
      this.regenerateAddress = oldAddress.address;
      this.appState.api.regenerateClientPeerAddressRequest(
        {
          peerId: this.peer?.id,
          oldAddress: oldAddress.address,
          email: this.email,
          amountUsd: this.amount ? convertAmountToApi(this.amount, currencies.WLCurrency.WLC_USD) : undefined,
        },
        (msg: protocol.IServerResponse) => {
          this.regenerateAddress = undefined;
          if (msg.error) {
            this.appState.notifications.addNotification(formatServerErrors(msg.error), NotificationType.ERROR);
          }
          if (msg?.regenerateClientPeerAddressResponse?.address && this.peer) {
            this.peerAddresses =
              this.peerAddresses?.map(address => {
                if (address.address === oldAddress.address) {
                  return msg!.regenerateClientPeerAddressResponse!.address!;
                }
                return address;
              }) || [];
          }
        },
      );
    }
  };

  @action
  createNewAddress = () => {
    if (this.currencyForNewAddress) {
      this.creatingNewAddressLoading = true;
      this.appState.api.generateNewClientPeerAddressRequest(
        {
          peerId: this.peer?.id,
          currency: this.currencyForNewAddress,
          email: this.email,
          amountUsd: this.amount ? convertAmountToApi(this.amount, currencies.WLCurrency.WLC_USD) : undefined,
        },
        (msg: protocol.IServerResponse) => {
          this.creatingNewAddressLoading = false;
          if (msg?.generateNewClientPeerAddressResponse?.address) {
            const newAddress = msg.generateNewClientPeerAddressResponse.address;
            this.peerAddresses = [...(this.peerAddresses || []), newAddress];
            this.currencyForNewAddress = undefined;
          }
        },
      );
    }
  };

  @action
  resetAll = () => {
    this.creatingInvoiceStep = 'creating-form';
    this.creatingInvoiceType = 'new-peer';
    this.peersList = undefined;
    this.peerId = undefined;
    this.newPeerCurrency = protocol.Currency.UNK_CUR;
    this.currencyForNewAddress = undefined;
    this.peerName = '';
    this.amount = '';
    this.email = '';
    this.createPeerError = undefined;
    this.peer = undefined;
    this.peerAddresses = undefined;
    this.peerAddressesIsLoading = false;
    this.regenerateAddress = undefined;
    this.createInvoiceLoading = false;
    this.createAddressError = undefined;
    this.creatingNewAddressLoading = false;
    this.peerFieldError = undefined;
    this.additionalCurrencies = [];
  };
}
