import useComponentVisible from '../../../hooks/useComponentVisible';
import { Icon } from '../../Icon/Icon';
import { ISelectOption } from '../Select/Select';
import './FilterSelect.scss';

interface IFilterSelect {
  options: ISelectOption[];
  value?: string | number;
  onChange: (value: string | number) => void;
  placeholder: string;
}

export const FilterSelect = (props: IFilterSelect) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggleOptionsVisible = () => {
    setIsComponentVisible(!isComponentVisible);
  };
  const label = !!props.value ? props.options.find(_ => _.value === props.value)?.label : props.placeholder;
  return (
    <div ref={ref} className="FilterSelect">
      <div onClick={toggleOptionsVisible} className="FilterSelect__input">
        <div className="FilterSelect__input__left">
          <Icon iconName="filter-select" />
          <span>{label}</span>
        </div>
        <Icon iconName="sort-arrows-down" />
      </div>
      {isComponentVisible ? (
        <div className="FilterSelect__options">
          {props.options.map(option => {
            return (
              <div
                className="FilterSelect__option"
                onClick={() => {
                  props.onChange(option.value);
                  toggleOptionsVisible();
                }}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
