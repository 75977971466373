import React from 'react';
import { observer } from 'mobx-react-lite';
//import { Confirmations } from "../Confirmations/Confirmations";
import { TotalBalances } from '../TotalBalances/TotalBalances';
import './MainPageInfo.scss';
import { Icon } from '../Icon/Icon';
import { TotalsBlock } from '../TotalsBlock/TotalsBlock';

export const MainPageInfo = observer(() => {
  const [minimizeView, setMinimizeView] = React.useState(window.innerWidth < 768 ? true : false);

  return (
    <div className={`MainPageInfo ${minimizeView ? 'minimize-view' : ''}`}>
      {/* <Confirmations /> */}
      <TotalsBlock minimizeView={minimizeView} />
      <div className="MainPageInfo__row">
        {/* <MainAccounts /> */}
        <TotalBalances minimizeView={minimizeView} />
      </div>
      <div className="size-switcher">
        <div className="size-switcher__left" />
        <div onClick={() => setMinimizeView(!minimizeView)} className="size-switcher__center">
          {minimizeView ? 'Maximize' : 'Minimize'}
          <Icon iconName={minimizeView ? 'arrow-down' : 'arrow-up'} widthInPx={13} />
        </div>
        <div className="size-switcher__right" />
      </div>
    </div>
  );
});
