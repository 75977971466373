import copy from 'copy-to-clipboard';
import { observer } from 'mobx-react';
import { useState } from '../../../state/AppState';
import { IconButton } from '../IconButton/IconButton';
import { MouseEvent } from 'react';

interface CopyButtonProps {
  contentForCopy?: string;
  sizeInPx?: number;
  notificationText?: string;
  label?: string;
}

export const CopyButton = observer((props: CopyButtonProps) => {
  const state = useState();
  const { notifications } = state;
  const onCopyClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.contentForCopy) {
      copy(props.contentForCopy);
      notifications.addNotification(props.notificationText || 'Copied');
    }
  };
  return (
    <IconButton
      onClick={onCopyClick}
      label={props.label}
      iconName="copy"
      heightInPx={props.sizeInPx}
      widthInPx={props.sizeInPx}
    />
  );
});
