import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { matchRoutes, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useMountEffect } from '../hooks/useMountEffect';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import { useState } from '../state/AppState';
import { ProtectedRoutes } from '../state/Routes';
import { FullPageLoader } from './Loader/Loader';

export const AuthWrapper = observer((props: { children?: ReactNode | undefined }) => {
  const params = useParams();
  const location = useLocation();
  const state = useState();
  useMountEffect(() => {
    if (params.clientId) {
      state.setClientId(params.clientId);
      state.checkSession();
    }
  });
  if (!state.isInitialized) {
    return null;
  }
  const isAvailableRouteMatch = matchRoutes(state.availableRoutes, location.pathname);
  if (!isAvailableRouteMatch?.length) {
    return <ErrorPage errorText="404 NOT FOUND" />;
  }
  if (isAvailableRouteMatch[0].route?.path) {
    state.setCurrentProtectedRoute(isAvailableRouteMatch[0].route.path as ProtectedRoutes);
  }
  return props.children ? <>{props.children}</> : <></>;
});
