import cn from 'classnames';
import { Icon } from '../../Icon/Icon';
import './Input.scss';
import { ReactNode } from 'react';

export interface InputProps {
  label: string;
  value?: string | number;
  onChange: (value: string) => void;
  actionButtonLabel?: string;
  actionButtonOnClick?: () => void;
  actionButtonDisabled?: boolean;
  type?: string;
  bottomText?: string | ReactNode;
  onEnter?: () => void;
  leftIconName?: string;
  actionButtonIcon?: string;
  className?: string;
  errorBool?: boolean;
}

export const Input = (props: InputProps) => {
  return (
    <div
      className={cn('input-container', props.className, {
        'with-action-btn': !!props.actionButtonLabel && !!props.actionButtonOnClick,
        'with-left-icon': !!props.leftIconName,
        'error-input': props.errorBool,
      })}
    >
      {props.leftIconName ? (
        <div className="input-left-icon">
          <Icon iconName={props.leftIconName} widthInPx={15} heightInPx={15} />
        </div>
      ) : null}
      <input
        type={props.type && props.type !== 'number' ? props.type : 'text'}
        value={props.value}
        placeholder={props.label}
        onChange={e => {
          if (props.type && props.type === 'number') {
            if (/^[\.0-9]*$/.test(e.target.value)) {
              props.onChange(e.target.value);
            }
          } else {
            props.onChange(e.target.value);
          }
        }}
        onKeyUp={e => {
          if (props.onEnter && (e?.key === 'Enter' || e?.keyCode === 13)) {
            props.onEnter();
          }
        }}
      />
      <span className="InputLabel">{props.label}</span>
      {props.actionButtonLabel || props.actionButtonIcon ? (
        <div
          className={cn('action-button', { disabled: props.actionButtonDisabled, 'with-icon': props.actionButtonIcon })}
          onClick={() => {
            if (!props.actionButtonDisabled && props.actionButtonOnClick) {
              props.actionButtonOnClick();
            }
          }}
        >
          {props.actionButtonLabel ? (
            props.actionButtonLabel
          ) : (
            <Icon iconName={props.actionButtonIcon!} widthInPx={10} heightInPx={10} />
          )}
        </div>
      ) : null}
      {props.bottomText ? <div className="bottom-text">{props.bottomText}</div> : null}
    </div>
  );
};
