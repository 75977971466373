import { ReactNode } from 'react';

interface TableTotalsProps {
  totals?: (string | number | ReactNode)[];
}

export const TableTotals = ({ totals }: TableTotalsProps) => {
  return (
    <div className="TableTotals">
      <tr>
        {totals?.map(_ => {
          return <td>{_}</td>;
        })}
      </tr>
    </div>
  );
};
