import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { currencies, protocol } from '../../api/proto';
import { CopyButton } from '../../components/controls/CopyButton/CopyButton';
import { Icon } from '../../components/Icon/Icon';
import { MainPageInfo } from '../../components/MainPageInfo/MainPageInfo';
import { CurrencyCell } from '../../components/Table/customCells/CurrencyCustomCell/CurrencyCustomCell';
import Table, { IColumn, SORT_DIRECTION } from '../../components/Table/Table';
import { useMountEffect } from '../../hooks/useMountEffect';
import { useState } from '../../state/AppState';
import { OPERATION_TABS, OperationTab } from '../../state/MainPageInfoStore';
import './MainPage.scss';
import { FromToInfoCell } from '../../components/mainPage/FromToInfoCell/FromToInfoCell';
import { Button, ButtonSize, ButtonType } from '../../components/controls/Button/Button';
import cn from 'classnames';
import { currenciesOptions, getShortAddress } from '../../state/utils/Money';
import { FilterSelect } from '../../components/controls/FilterSelect/FilterSelect';
import { AddressTag } from '../../components/mainPage/AddressTag/AddressTag';
import { TableTagsList } from '../../components/mainPage/TableTagsList/TableTagsList';

const DateTimeCell = ({ row }: { row: any }) => {
  return (
    <td>
      <div>{row.formattedDateOnly}</div>
      <div>{row.formattedTimeOnly}</div>
    </td>
  );
};

const IdCell = ({ row, operationType }: { row: any; operationType: OperationTab }) => {
  return (
    <td className="id-cell">
      <div className="container">
        {row.isNew ? <div className="new-transaction"></div> : null}
        {operationType === 'Pending' && row.direction ? (
          <Icon
            widthInPx={16}
            heightInPx={16}
            iconName={row.direction === protocol.TxDirection.IN_TX_DIR ? 'refill' : 'withdraw'}
          />
        ) : null}
        {row.id ? row.id : ''}
      </div>
    </td>
  );
};

const ProcessingActionButtonCell = ({ id, status }: { id: number; status?: protocol.PayoutRequestStatus }) => {
  const state = useState();
  const { mainPageInfo } = state;
  if (!status || status !== protocol.PayoutRequestStatus.PENDING_PAYOUT_REQUEST_STATUS) {
    return <td></td>;
  }
  return (
    <td>
      <Button
        label="Cancel"
        type={ButtonType.CANCEL}
        size={ButtonSize.SMALL}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          mainPageInfo.openDeleteProcessingTransactionModal(id);
        }}
      />
    </td>
  );
};
const currenciesWithAll = [{ label: 'All', value: 0 }, ...currenciesOptions];

const CurrencyFilter = observer(() => {
  const state = useState();
  const { mainPageInfo } = state;
  return (
    <FilterSelect
      options={currenciesWithAll}
      value={mainPageInfo.filterCurrency}
      onChange={mainPageInfo.onChangeFilterCurrency}
      placeholder="Select currency"
    />
  );
});

export function getTxTableColumns(
  operationType: OperationTab,
  currencyForConvert: currencies.WLCurrency,
  withTotalsFromApi?: boolean,
  withFilters?: boolean,
  fullDetails?: boolean,
) {
  let tableColumns: IColumn[] = [
    {
      title: 'ID',
      key: 'id',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => <IdCell row={row} operationType={operationType} />,
    },
    {
      title: 'Trx info',
      key: 'info',
      sortKey: 'info',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => <FromToInfoCell fullDetails={fullDetails} row={row} />,
    },
    {
      title: 'Currency',
      key: 'formattedCurrency',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => <CurrencyCell row={row} />,
      renderFilterComponent: withFilters ? () => <CurrencyFilter /> : undefined,
    },
    {
      title: 'Amount',
      key: 'formattedAmount',
      withTotal: true,
      additionalFields: operationType !== 'Pending' ? ['formattedConvertedAmounts'] : undefined,
      sortEnabled: true,
      sortKey: 'convertedAmount',
      isTotalsFromApi: true,
      totalKey: !withTotalsFromApi ? 'amountSum' : undefined,
    },
    {
      title: 'Fee',
      key: 'formattedFee',
      additionalFields: operationType !== 'Pending' ? ['formattedConvertedFee'] : undefined,
      sortEnabled: false,
      withTotal: true,
      isTotalsFromApi: true,
      totalKey: !withTotalsFromApi ? 'feeSum' : undefined,
    },
    {
      title: 'Date and time',
      key: 'formattedDate',
      sortKey: 'updatedAtNumber',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => <DateTimeCell row={row} />,
    },
    {
      title: 'Tx tags',
      key: 'tags',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => <TableTagsList tags={row.tags} />,
    },
  ];
  if (operationType === 'Withdrawal') {
    tableColumns = tableColumns.concat([
      {
        title: 'Specified tags for withdrawal',
        key: 'payoutTags',
        sortEnabled: false,
        renderCustomCellComponent: (row: any) => <TableTagsList tags={row.payoutTags} />,
      },
      {
        title: 'Payment description',
        key: 'payoutRequestTags',
        sortEnabled: false,
        renderCustomCellComponent: (row: any) => <TableTagsList tags={row.payoutRequestTags} />,
      },
    ]);
  }
  return tableColumns;
}

export function getPayoutRequestRecordsColumns(operationType: OperationTab, fullDetails?: boolean) {
  return [
    {
      title: 'ID',
      key: 'id',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => <IdCell row={row} operationType={operationType} />,
    },
    {
      title: 'Trx info',
      key: 'info',
      sortKey: 'info',
      sortEnabled: false,
      renderCustomCellComponent: (row: any) => <FromToInfoCell fullDetails={fullDetails} withoutTrxId row={row} />,
    },
    {
      title: 'Currency',
      key: 'formattedCurrency',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => <CurrencyCell row={row} />,
    },
    {
      title: 'Amount',
      key: 'formattedAmount',
      additionalFields: ['formattedConvertedAmounts'],
      sortEnabled: true,
    },
    {
      title: 'Status',
      key: 'formattedStatus',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => {
        return (
          <td>
            <div className="ProcessingTable__statusCell">
              <div className={cn('ProcessingTable__status', { bold: !!row.refillSystemAddressesComputed?.length })}>
                {row.formattedStatus}
              </div>
              {row.refillSystemAddressesComputed?.length ? (
                <div>
                  {row.refillSystemAddressesComputed.map((address: protocol.IPRSystemAddress) => (
                    <div className="ProcessingTable__address">
                      {getShortAddress(address.address ?? '')}
                      <CopyButton
                        notificationText="Address has been copied"
                        sizeInPx={14}
                        contentForCopy={address.address ?? ''}
                      />
                      {address.tag ? <AddressTag tag={address.tag} /> : null}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </td>
        );
      },
    },
    {
      title: 'Date and time',
      key: 'formattedDate',
      sortKey: 'updatedAtNumber',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => <DateTimeCell row={row} />,
    },
    {
      title: 'Specified tags for withdrawal',
      key: 'formattedPayoutTags',
      sortEnabled: true,
    },
    {
      title: 'Payment description',
      key: 'tags',
      sortEnabled: false,
      renderCustomCellComponent: (row: any) => <TableTagsList tags={row.tags} />,
    },
    {
      title: 'Operation id',
      key: 'operationId',
      sortEnabled: true,
      renderCustomCellComponent: (row: any) => {
        return (
          <td>
            {row.operationId ? (
              <div className="operationId">
                {getShortAddress(row.operationId)}
                <CopyButton
                  notificationText="Operation ID has been copied"
                  sizeInPx={14}
                  contentForCopy={row.operationId ?? ''}
                />
              </div>
            ) : null}
          </td>
        );
      },
    },
    {
      title: '',
      key: '',
      sortEnabled: false,
      renderCustomCellComponent: (row: any) =>
        row.id ? <ProcessingActionButtonCell status={row.status} id={row.id} /> : null,
    },
  ];
}

const MainPage = observer(() => {
  const state = useState();
  const params = useParams();
  const { mainPageInfo } = state;
  const { resetPage } = mainPageInfo;
  useMountEffect(() => {
    if (params.clientId) {
      state.setClientId(params.clientId);
      state.mainPageInfo.init();
    }
  });
  useEffect(() => {
    return () => {
      resetPage();
    };
  }, [resetPage]);

  let tableColumns: IColumn[] = [];
  if (mainPageInfo.operationType === 'Payout requests') {
    tableColumns = getPayoutRequestRecordsColumns(mainPageInfo.operationType);
  } else {
    tableColumns = getTxTableColumns(mainPageInfo.operationType, state.selectedCurrencyForConvert, true, true);
  }

  return (
    <div className="MainPage">
      <MainPageInfo />
      <div className="MainPage__operations">
        {mainPageInfo.operationType === 'Payout requests' ? (
          <Table
            columns={tableColumns}
            hasChildRows
            defaultSortedColumn={{
              name: 'updatedAtNumber',
              direction: SORT_DIRECTION.DESC,
            }}
            periodsFilterColumn={'updatedAtNumber'}
            data={mainPageInfo.filteredProcessingOperations}
            contentIsLoading={mainPageInfo.processingOperationsLoading}
            openChildsInAnotherPage
            onRowClick={state.mainPageInfo.onTxTableRowClick}
            onSetBlockRef={state.mainPageInfo.onSetTableRef}
            tableBlockRef={state.mainPageInfo.tableRef}
            filtersLeftBlock={
              <div className="OperationsFilter__tabs">
                {OPERATION_TABS.map(_ => (
                  <div
                    key={_}
                    className={`OperationsFilter__tab ${
                      _ === state.mainPageInfo.operationType ? 'OperationsFilter__tab--active' : ''
                    }`}
                    onClick={() => {
                      state.mainPageInfo.updateOperationTab(_);
                    }}
                  >
                    {_}
                  </div>
                ))}
              </div>
            }
          />
        ) : (
          <Table
            columns={tableColumns}
            hasChildRows
            defaultSortedColumn={{
              name: 'updatedAtNumber',
              direction: SORT_DIRECTION.DESC,
            }}
            periodsFilterColumn={'updatedAtNumber'}
            data={mainPageInfo.operations}
            filtersLeftBlock={
              <div className="OperationsFilter__tabs">
                {OPERATION_TABS.map(_ => (
                  <div
                    key={_}
                    className={`OperationsFilter__tab ${
                      _ === state.mainPageInfo.operationType ? 'OperationsFilter__tab--active' : ''
                    }`}
                    onClick={() => {
                      state.mainPageInfo.updateOperationTab(_);
                    }}
                  >
                    {_}
                  </div>
                ))}
              </div>
            }
            onPageChange={state.mainPageInfo.changePage}
            pageCount={state.mainPageInfo.totalPages}
            page={state.mainPageInfo.page}
            serverSortingAndFilters={true}
            sortedColumn={state.mainPageInfo.sortedColumn}
            onSortColumnClick={state.mainPageInfo.onSortColumnClick}
            period={state.mainPageInfo.periodTransaction}
            customDateRange={state.mainPageInfo.customDateRangeTransactions}
            onChangePeriod={state.mainPageInfo.onChangePeriod}
            setCustomDateRange={state.mainPageInfo.setCustomDateRangeTransactions}
            contentIsLoading={state.mainPageInfo.isTransactionsLoading}
            totalsFromApi={state.mainPageInfo.tableTotals}
            onRowClick={state.mainPageInfo.onTxTableRowClick}
            openChildsInAnotherPage
            onSetBlockRef={state.mainPageInfo.onSetTableRef}
            tableBlockRef={state.mainPageInfo.tableRef}
          />
        )}
      </div>
    </div>
  );
});

export default MainPage;
